import {
	USER_REQUEST,
	USER_ERROR,
	USER_SUCCESS,
	USER_UPDATE_DATA,
	USER_SET_DEFAULT_SHIP_DATA,
	USER_CHECK_AGE,
	ACCEPT_COOKIES,

	POPUP_REQUEST,
	POPUP_SUCCESS,

} from '../actions/user'
import {
	DECRYPT,
	ENCRYPT
} from '../actions/auth'
import Vue from 'vue'
import {
	AUTH_LOGOUT,
	AUTH_OK
} from '../actions/auth'
import axios from 'axios'
import authData from '../modules/auth'



const state = {
	status: '',
	profile: {},
	defaultShipData: {},
	checkAge: localStorage.getItem('checkAge') || false,
	acceptCookies: localStorage.getItem('acceptCookies') || false,
	popup: {
		arr: ['test1', 'test2', 'test3'],
		status: false
	},
}

const getters = {
	getProfile: state => state.profile,
	getDefaultShipData: state => state.defaultShipData,
	isComplateAccount: state => !!state.profile.support_id,
	isProfileLoaded: state => !!state.profile.username_player,
	checkAge: state => !!state.checkAge,
	acceptCookies: state => !!state.acceptCookies,
	getPopup: state => state.popup,
}

const actions = {
	[POPUP_REQUEST]: ({
		commit,
		dispatch
	}) => {
		commit(POPUP_REQUEST)
		let data = {
			player_token_player: localStorage.getItem(process.env.VUE_APP_WORMY + process.env.VUE_APP_ICODE),
			HBGRF: process.env.VUE_APP_HBGRF,
			vector_HBGRF: process.env.VUE_APP_ICODE
		}
		commit(ENCRYPT, data)
		data = authData.state.tempEncrypt
		axios({
				url: process.env.VUE_APP_ROOT_API + 'api/createPopUp',
				data: {
					data
				},
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + process.env.VUE_APP_KITTY,
					'Content-type': 'application/json'
				}
			})
			.then(resp => {
				commit(DECRYPT, resp.data)
				resp.data = authData.state.tempCrypt

				commit(POPUP_SUCCESS, resp)
				// dispatch(AUTH_OK)
			})
			.catch(resp => {
				commit(DECRYPT, resp.response.data)
				resp.response.data = authData.state.tempCrypt

				// commit(USER_ERROR, resp.response.data.message)
				// dispatch(AUTH_LOGOUT, resp.response.data.message)
			})
	},
	[USER_REQUEST]: ({
		commit,
		dispatch
	}) => {
		commit(USER_REQUEST)
		var data = {
			player_token_player: localStorage.getItem(process.env.VUE_APP_WORMY + process.env.VUE_APP_ICODE),
			jwt: localStorage.getItem(process.env.VUE_APP_WORMY + process.env.VUE_APP_ICODE + '#$%'),
			HBGRF: process.env.VUE_APP_HBGRF,
			vector_HBGRF: process.env.VUE_APP_ICODE
		}
		commit(ENCRYPT, data)
		data = authData.state.tempEncrypt
		axios({
				url: process.env.VUE_APP_ROOT_API + 'api/getUser',
				data: {
					data
				},
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + process.env.VUE_APP_KITTY,
					'Content-type': 'application/json'
				}
			})
			.then(resp => {
				commit(DECRYPT, resp.data)
				resp.data = authData.state.tempCrypt

				commit(USER_SUCCESS, resp.data)
				dispatch(AUTH_OK)
			})
			.catch(resp => {
				commit(DECRYPT, resp.response.data)
				resp.response.data = authData.state.tempCrypt
				commit(USER_ERROR, resp.response.data.message)
				dispatch(AUTH_LOGOUT, resp.response.data.message)
			})
	},
	[USER_UPDATE_DATA]: ({
		commit,
		dispatch
	}) => {
		commit(USER_UPDATE_DATA)

	},
	[USER_SET_DEFAULT_SHIP_DATA]: ({
		commit,
		dispatch
	}, shipData) => {
		commit(USER_SET_DEFAULT_SHIP_DATA, shipData)
	},
	[USER_CHECK_AGE]: ({
		commit,
		dispatch
	}) => {
		localStorage.setItem('checkAge', true) // store the token in localstorage  
		commit(USER_CHECK_AGE)
	},
	[ACCEPT_COOKIES]: ({
		commit,
		dispatch
	}) => {
		localStorage.setItem('acceptCookies', true) // store the token in localstorage  
		commit(ACCEPT_COOKIES)
	},



}

const mutations = {


	[POPUP_SUCCESS]: (state, resp) => {
		state.popup.status = 'success'
		state.popup.arr = resp.data.message
	},

	[USER_REQUEST]: (state) => {
		state.status = 'loading'
	},
	[USER_SUCCESS]: (state, resp) => {
		state.status = 'success'
		Vue.set(state, 'profile', resp.message)
	},
	[USER_ERROR]: (state, resp) => {
		state.status = resp
	},
	[USER_UPDATE_DATA]: (state) => {
		state.status = 'success'
		Vue.set(state.profile, 'support_id', true)
	},
	[AUTH_LOGOUT]: (state) => {
		state.profile = {}
	},
	[USER_SET_DEFAULT_SHIP_DATA]: (state, shipData) => {
		Vue.set(state, 'defaultShipData', shipData)
	},
	[USER_CHECK_AGE]: (state) => {
		state.checkAge = true
	},
	[ACCEPT_COOKIES]: (state) => {
		state.acceptCookies = true
	},


}

export default {
	state,
	getters,
	actions,
	mutations,
}