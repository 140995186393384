<template>
	<div class="container-fluid fixed cookieBanner" v-if="!acceptCookies && !cookieBot">
		<div class="">
			<span class="Medium color-3">
				Utilizziamo i cookie al fine di personalizzare e migliorare la tua esperienza sul nostro
				sito.
				<br />Visita la nostra
				<a target="_blank" :href="privacyPolicy">informativa sulla privacy</a>
				per acquisire maggiori informazioni. <br />Utilizzando il nostro sito accetti l'utilizzo dei
				cookie da parte nostra.
			</span>
		</div>

		<button class="btn btn-primary pr-3 pl-3" v-on:click="doAcceptCookies"> OK </button>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	import { ACCEPT_COOKIES } from 'actions/user'
	const moment = require('moment')

	import { sharedEnvMixin } from '../../mixins/sharedEnvMixin'

	export default {
		name: 'CookieBanner',
		mixins: [sharedEnvMixin],

		data() {
			return {
				currentPage: '',
				visible: true,
				opacity: { opacity: 1 },
				moment: moment
			}
		},
		methods: {
			doAcceptCookies: function () {
				this.$store.dispatch(ACCEPT_COOKIES).then((resp) => {})
			}
		},
		watch: {},
		'$route.path': function (route) {
			if (route != '/') {
				this.visible = false
				this.opacity = {
					opacity: 0,
					'-ms-filter': 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)',
					filter: 'alpha(opacity=0)'
				}
			} else {
				this.opacity = {
					opacity: 1,
					'-ms-filter': 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)',
					filter: 'alpha(opacity=100)'
				}
				this.visible = true
			}
		},
		acceptCookies: function () {},
		beforeUpdate: function () {},
		updated() {},
		mounted: function () {
			this.currentPage = this.$route.name

			var _vue = this
			if (this.cookieBot) {
				let cookieBotScript = document.createElement('script')
				cookieBotScript.setAttribute('src', 'https://consent.cookiebot.com/uc.js')
				cookieBotScript.setAttribute('data-cbid', cookieBotKey)
				cookieBotScript.setAttribute('data-blockingmode', 'auto')
				cookieBotScript.async = true
				cookieBotScript.setAttribute('type', 'text/javascript')
				document.head.appendChild(cookieBotScript)
			}
			setTimeout(function () {
				if (_vue.$route.path != '/') {
					_vue.visible = false
					_vue.opacity = {
						opacity: 0,
						'-ms-filter': 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)',
						filter: 'alpha(opacity=0)'
					}
				}
			}, 300)
			window.onresize = () => {}
		},
		computed: {
			...mapGetters([
				'acceptCookies',
				'isAuthenticated',
				'authStatus',
				'getPromotions',
				'getOldPromotions'
			])
		}
	}
</script>
