<template>
	<div class="widthFull relative customBg pt-3 min-height-calc" id="registration">
		<div v-if="authStatus != 'success'" class="container-fluid">
			<div class="row justify-content-center mt-4">
				<div class="col-8 text-center">
					<h3 class="col-12 center Bold pb-2 color-1 uppercase"> Registrati </h3>
				</div>
			</div>
		</div>

		<div class="container-fluid pb-5">
			<loading v-if="loadingState" />

			<form
				v-if="authStatus != 'success'"
				class="pt-0 pb-3 pt-3"
				id="registration-form"
				@submit.prevent="registration"
			>
				<div v-show="adult2" class="form-row justify-content-center">
					<div class="col-md-6 col-lg-4 mb-4 mb-md-3">
						<div class="group">
							<input
								type="text"
								class="form-control"
								v-model="first_name_player"
								id="first_name_player"
								name="first_name_player"
								data-vv-as="Nome"
								v-validate="{ required: true, max: 128, min: 2 }"
								:class="{
									'is-invalid': errors.has('first_name_player'),
									valid: !!first_name_player
								}"
							/>
							<span class="highlight"></span>

							<label class="niceInputLabel" for="first_name_player">Nome </label>
							<div v-if="errors.has('first_name_player')" class="invalid-feedback">
								{{ errors.first('first_name_player') }}
							</div>
						</div>
					</div>

					<div class="col-md-6 col-lg-4 mb-4 mb-md-3">
						<div class="group">
							<input
								type="text"
								class="form-control"
								v-model="last_name_player"
								id="last_name_player"
								name="last_name_player"
								data-vv-as="Cognome"
								v-validate="{ required: true, max: 128, min: 2 }"
								:class="{
									'is-invalid': errors.has('last_name_player'),
									valid: !!last_name_player
								}"
							/>
							<span class="highlight"></span>

							<label class="niceInputLabel" for="last_name_player">Cognome</label>

							<div v-if="errors.has('last_name_player')" class="invalid-feedback">
								{{ errors.first('last_name_player') }}
							</div>
						</div>
					</div>
				</div>

				<div v-show="adult2" class="form-row justify-content-center">
					<div class="col-md-6 col-lg-4 mb-4 mb-md-3">
						<div class="group">
							<input
								type="text"
								v-model="username_player"
								class="form-control"
								id="username_player"
								name="username_player"
								@blur="checkIfExistEmail"
								ref="username_player"
								data-vv-as="username_player"
								v-validate="{
									required: true,
									email: true,
									temporaryEmail: true,
									plusIssueEmail: true,
									max: 56
								}"
								:class="{
									'is-invalid': errors.has('username_player') || existEmail,
									valid: !!username_player
								}"
							/>
							<span class="highlight"></span>

							<label class="niceInputLabel" for="username_player">Indirizzo E-Mail</label>

							<div v-if="existEmail" class="invalid-feedback" style="display: block">
								<span v-if="existEmailSocial"
									>Email digitato già presente. Sei registrato con:
									<strong class="text-capitalize">{{ existEmailSocial }}</strong></span
								>
								<span v-else>Email digitato già presente</span>
							</div>
							<div v-if="errors.has('username_player')" class="invalid-feedback">
								{{ errors.first('username_player') }}
							</div>
						</div>
					</div>

					<div class="col-md-6 col-lg-4 mb-4 mb-md-3">
						<div class="group">
							<input
								autocomplete="on"
								type="text"
								class="form-control"
								v-model="confirm_username"
								id="confirm_username"
								name="confirm_username"
								data-vv-as="Conferma Password"
								v-validate="{
									required: true,
									email: true,
									temporaryEmail: true,
									plusIssueEmail: true,
									max: 56,
									confirmed: username_player
								}"
								:class="{
									'is-invalid': errors.has('confirm_username'),
									valid: !!confirm_username
								}"
							/>
							<span class="highlight"></span>

							<label class="niceInputLabel" for="confirm_username">Ripeti Indirizzo E-Mail</label>
							<div v-if="errors.has('confirm_username')" class="invalid-feedback">
								{{ errors.first('confirm_username') }}
							</div>
						</div>
					</div>
				</div>

				<div v-show="adult2" class="form-row justify-content-center">
					<div class="col-md-6 col-lg-4 mb-4 mb-md-3">
						<div class="group">
							<input
								autocomplete="on"
								type="password"
								class="form-control"
								v-model="password_player"
								id="password_player"
								name="password_player"
								data-vv-as="password_player"
								v-validate="{
									required: true,
									passwordCheck: true,
									min: 8,
									max: 56
								}"
								:class="{
									'is-invalid': errors.has('password_player'),
									valid: !!password_player
								}"
							/>
							<span class="highlight"></span>

							<label class="niceInputLabel" for="password_player">Password</label>
							<div v-if="errors.has('password_player')" class="invalid-feedback">
								{{ errors.first('password_player') }}
							</div>
						</div>
					</div>
					<div class="col-md-6 col-lg-4 mb-4 mb-md-3">
						<div class="group">
							<input
								autocomplete="on"
								type="password"
								class="form-control"
								v-model="confirm_password"
								id="confirm_password"
								name="confirm_password"
								data-vv-as="Conferma Password"
								v-validate="{
									required: true,
									passwordCheck: true,
									min: 8,
									max: 56,
									confirmed: password_player
								}"
								:class="{
									'is-invalid': errors.has('confirm_password'),
									valid: !!confirm_password
								}"
							/>
							<span class="highlight"></span>

							<label class="niceInputLabel" for="confirm_password">Ripeti Password</label>
							<div v-if="errors.has('confirm_password')" class="invalid-feedback">
								{{ errors.first('confirm_password') }}
							</div>
						</div>
					</div>
				</div>

				<div class="form-row justify-content-center">
					<div class="col-md-6 col-lg-4 mb-4 mb-md-3">
						<div class="group">
							<datepicker
								:language="it"
								v-model="birth_date_player"
								:full-month-name="true"
								:open-date="openDate"
								:initialView="'year'"
								:format="'dd MMMM yyyy'"
								:monday-first="true"
								:disabledDates="disabledBirthdays"
								name="birth_date_player"
								input-class="form-control "
							></datepicker>

							<input
								type="text"
								v-show="false"
								v-model="birth_date_player"
								class="form-control datepicker"
								id="birth_date_player"
								name="birth_date_player"
								data-vv-as="Data di nascita"
								v-validate="{ required: true }"
								:class="{
									'is-invalid': errors.has('birth_date_player'),
									valid: !!birth_date_player
								}"
							/>
							<label class="niceInputLabel" for="address">Data di nascita*</label>
							<div v-if="errors.has('birth_date_player')" class="invalid-feedback">
								{{ errors.first('birth_date_player') }}
							</div>
						</div>
					</div>
					<div class="col-md-6 col-lg-4 mb-4 mb-md-3">
						<div class="group">
							<input
								type="text"
								autocomplete="randomr"
								class="form-control"
								v-model="address"
								id="address"
								name="address"
								data-vv-as="Indirizzo"
								v-validate="{ required: true, max: 128, min: 5 }"
								:class="{
									'is-invalid': errors.has('address'),
									valid: !!address
								}"
							/>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label class="niceInputLabel" for="address">Indirizzo *</label>
							<div v-if="errors.has('address')" class="invalid-feedback">
								{{ errors.first('address') }}
							</div>
						</div>
					</div>
				</div>

				<div class="form-row justify-content-center">
					<div class="col-md-6 col-lg-4 mb-4 mb-md-3">
						<div class="group">
							<input
								type="text"
								autocomplete="randomt"
								class="form-control"
								v-model="civic"
								id="civic"
								name="civic"
								data-vv-as="Civico"
								v-validate="{ required: true, max: 15 }"
								:class="{
									'is-invalid': errors.has('civic'),
									valid: !!civic
								}"
							/>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label class="niceInputLabel" for="civic">Civico *</label>
							<div v-if="errors.has('civic')" class="invalid-feedback">
								{{ errors.first('civic') }}
							</div>
						</div>
					</div>
					<div class="col-md-6 col-lg-4 mb-4 mb-md-3">
						<div class="group">
							<select
								v-model="province"
								autocomplete="randomy"
								id="province"
								name="province"
								class="form-control"
								data-vv-as="Provincia"
								v-validate="{ required: true }"
								:class="{
									'is-invalid': errors.has('province'),
									valid: !!province
								}"
							>
								<option v-if="!shipData.province" disabled value="" selected="selected"></option>
								<option v-else :value="shipData.province" selected="selected">
									{{ shipData.province }}
								</option>
								<option
									v-for="province in provinces"
									:key="province.sigla"
									v-bind:value="province.sigla"
									:selected="province.sigla == shipData.province"
								>
									{{ province.sigla }} - {{ province.provincia }}
								</option>
							</select>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label class="niceInputLabel" for="province">Provincia *</label>
							<div v-if="provincesState">
								<font-awesome-icon
									class="loadSpinner"
									id="loadProvince"
									icon="spinner"
									size="1x"
									pulse
								/>
							</div>
							<div v-if="errors.has('province')" class="invalid-feedback">
								{{ errors.first('province') }}
							</div>
						</div>
					</div>
					<div class="col-12"></div>
					<div class="col-md-6 col-lg-4 mb-4 mb-md-3">
						<div class="group">
							<select
								class="form-control"
								autocomplete="randomu"
								v-model="city"
								v-on:click="checkProvince"
								id="city"
								name="city"
								data-vv-as="Località"
								v-validate="{ required: true }"
								:disabled="citiesState"
								:class="{
									'is-invalid': errors.has('city'),
									valid: !!city
								}"
							>
								<option v-if="!shipData.city" disabled value="" selected="selected"></option>
								<option else :value="{ city: shipData.city }" selected="selected">
									{{ shipData.city }}
								</option>
								<option
									v-for="city in cities"
									:key="city.comune"
									v-bind:value="{
										cityistat: city.istat,
										city: city.comune
									}"
									:selected="city.comune == shipData.city"
								>
									{{ city.comune }}
								</option>
							</select>

							<span class="highlight"></span>
							<span class="bar"></span>
							<label class="niceInputLabel" for="city"
								>Località *<span v-if="cityError" class="formError"
									>Prima scegli Provincia</span
								></label
							>
							<div v-if="citiesState">
								<font-awesome-icon
									class="loadSpinner"
									id="loadCity"
									icon="spinner"
									size="1x"
									pulse
								/>
							</div>
							<div v-if="errors.has('city')" class="invalid-feedback">
								{{ errors.first('city') }}
							</div>
						</div>
					</div>
					<div class="col-md-6 col-lg-4 mb-4 mb-md-3">
						<div class="group">
							<select
								class="form-control"
								autocomplete="randomi"
								v-model="zip"
								v-on:click="checkZip"
								id="zip"
								name="zip"
								data-vv-as="Cap"
								v-validate="{ required: true }"
								:disabled="zipsState"
								:class="{
									'is-invalid': errors.has('zip'),
									valid: !!zip
								}"
							>
								<option v-if="!shipData.zip" value="" disabled :selected="true"></option>
								<option
									v-for="zip in zips"
									:key="zip.cap"
									v-bind:value="zip.cap"
									:selected="zip.cap == shipData.zip"
								>
									{{ zip.cap }}
								</option>
							</select>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label class="niceInputLabel" for="zip"
								>Cap *<span v-if="zipError" class="formError">Prima scegli Località</span>
							</label>
							<div v-if="zipsState">
								<font-awesome-icon
									class="loadSpinner"
									id="loadZips"
									icon="spinner"
									size="1x"
									pulse
								/>
							</div>
							<div v-if="errors.has('zip')" class="invalid-feedback">
								{{ errors.first('zip') }}
							</div>
						</div>
					</div>
				</div>
				<div class="form-row justify-content-center">
					<div class="col-md-6 col-lg-4 mb-4 mb-md-3">
						<div class="group">
							<input
								type="text"
								autocomplete="random"
								class="form-control"
								v-model="near"
								id="near"
								name="near"
								data-vv-as="Presso"
								v-validate="{ max: 128, min: 2 }"
								:class="{
									'is-invalid': errors.has('near'),
									valid: !!near
								}"
							/>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label class="niceInputLabel" for="lastname">Presso / Nome sul citofono </label>

							<div v-if="errors.has('near')" class="invalid-feedback">
								{{ errors.first('near') }}
							</div>
						</div>
					</div>
					<div class="col-md-6 col-lg-4 mb-4 mb-md-3">
						<div class="group">
							<select
								v-model="gender_player"
								id="gender_player"
								name="gender_player"
								class="form-control"
								data-vv-as="Sesso"
								v-validate="{ required: false }"
								:class="{
									'is-invalid': errors.has('gender_player'),
									valid: !!gender_player
								}"
							>
								<option v-for="(item, index) in genders" :key="index" :value="item">
									<p>{{ item }}</p>
								</option>
							</select>
							<label class="niceInputLabel" for="lastname">Sesso </label>
							<div v-if="errors.has('gender_player')" class="invalid-feedback">
								{{ errors.first('gender_player') }}
							</div>
						</div>
					</div>
				</div>

				<div class="row justify-content-center py-4">
					<div class="col-md-8 center badge badge-danger" v-if="authStatus == 'error'">
						Errore conessione
					</div>

					<div class="col-md-8">
						<h5 class="Regular">
							Dichiaro di essere maggiorenne, di aver preso visione del
							<a class="underline" :href="regolamentoPolicy" target="_blank"
								>regolamento concorso</a
							>
							e
							<a class="underline" :href="privacyPolicy" target="_blank"
								>l'Informativa per il trattamento dei dati personali</a
							>
							ne accetto il contenuto per:
						</h5>
					</div>
					<div class="col-md-8">
						<h5> La partecipazione alla presente iniziativa* </h5>
						<div class="form-check my-3 my-md-0">
							<div class="d-flex align-items-center">
								<div class="mr-5">
									<input
										v-model="agreement1_player"
										class="mt-3 mr-3"
										type="radio"
										name="agreement1_player"
										id="pr3"
										value="1"
										v-validate="'required|included:1'"
										:class="{
											'is-invalid': errors.has('agreement1_player')
										}"
									/>

									<label for="pr3" class="custom-pos-label">
										<div class="">
											<span class="uppercase">Sì</span>
										</div>
									</label>
								</div>

								<div class="ml-3">
									<input
										v-model="agreement1_player"
										class="mt-3 mr-3"
										type="radio"
										name="agreement1_player"
										id="pr4"
										value="0"
										v-validate="'required|included:1'"
									/>

									<label for="pr4" class="custom-pos-label">
										<div class="">
											<span class="uppercase">No</span>
										</div>
									</label>
								</div>
							</div>
							<label class="red" v-show="errors.has('agreement1_player')">
								Consenso di presa visione va accettato
							</label>
						</div>
					</div>

					<div class="col-md-8">
						<h5
							>Attività di marketing quali invio di materiale promozionale e pubblicitario anche a
							mezzo e-mail, sms o equivalente
						</h5>
						<div class="form-check my-3 my-md-0">
							<div class="d-flex align-items-center">
								<div class="mr-5">
									<input
										v-model="agreement2_player"
										class="mt-3 mr-3"
										type="radio"
										name="agreement2_player"
										id="pr5"
										value="1"
										v-validate="'required|included:0,1'"
										:class="{
											'is-invalid': errors.has('agreement2_player')
										}"
									/>

									<label for="pr5" class="custom-pos-label">
										<div class="">
											<span class="uppercase">Sì</span>
										</div>
									</label>
								</div>

								<div class="ml-3">
									<input
										v-model="agreement2_player"
										class="mt-3 mr-3"
										type="radio"
										name="agreement2_player"
										id="pr6"
										value="0"
										v-validate="'required|included:0,1'"
									/>

									<label for="pr6" class="custom-pos-label">
										<div class="">
											<span class="uppercase">No</span>
										</div>
									</label>
								</div>
							</div>
							<label class="red" v-show="errors.has('agreement2_player')">
								Consenso per finalità di markenting va accettato oppure rifiutato
							</label>
						</div>
					</div>

					<div v-show="adult2" class="col-md-12 center mt-3">
						<button class="btn btn-primary" type="submit">REGISTRATI</button>
					</div>
				</div></form
			>
			<div v-if="authStatus == 'success'" class="container">
				<div class="row align-items-center py-5">
					<div class="col-12 col-md-8 mx-auto">
						<div class="center">
							<h3 class="Bold py-2 color-1 pt-5">Conferma la tua email</h3>

							<h4 class="mt-4">
								Ti abbiamo inviato una mail all’indirizzo che ci hai indicato.
								<br />
								<b class="color-1"> Controlla la tua casella </b>di posta, anche nella cartella
								<b class="color-1">spam</b>.
							</h4>
							<h4 class="mt-4">
								<b class="color-1"> Clicca sul </b>link che troverai all’interno del messaggio per
								confermare la tua registrazione e torna sul sito per giocare.
							</h4>

							<div class="mt-5">
								<router-link tag="button" class="btn btn-primary uppercase" to="/login">
									accedi
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { AUTH_REGISTRATION } from 'actions/auth'
	import { mapGetters, mapState } from 'vuex'
	import axios from 'axios'
	import Datepicker from 'vuejs-datepicker'
	import { it } from 'vuejs-datepicker/dist/locale'
	const moment = require('moment')

	import { locationMixin } from '../../mixins/locationMixin'
	import { checkUniqueMixin } from '../../mixins/checkUniqueMixin'
	import { sharedEnvMixin } from '../../mixins/sharedEnvMixin'
	import { toDateMixin } from '../../mixins/toDateMixin'
	import { innerWidthMixin } from '../../mixins/innerWidthMixin'
	import { playFunctionMixin } from '../../mixins/playFunctionMixin'
	import { popupConfigurationMixin } from '../../mixins/popupConfigurationMixin'
	import { gtagMixin } from '../../mixins/gtagMixin'

	export default {
		components: {
			Datepicker
		},
		name: 'registration',
		mixins: [
			checkUniqueMixin,
			locationMixin,
			toDateMixin,
			sharedEnvMixin,
			gtagMixin,
			popupConfigurationMixin,
			playFunctionMixin,
			innerWidthMixin
		],
		data() {
			return {
				moment: moment,
				genders: ['M', 'F'],
				birth_date_player: '',
				username_player: '',
				confirm_username: '',
				password_player: '',
				confirm_password: '',
				first_name_player: '',
				last_name_player: '',
				address_player: '',
				civic_number_player: '',
				province_player: '',
				zip_player: '',
				phone_player: '',
				near: '',
				agreement1_player: '',
				agreement2_player: '',
				agreement3_player: '',
				agreement4_player: '',
				gender_player: '',
				txt1_player: '',

				adult2: true,
				registerPage: false,
				colorType: '',
				colorType2: '',

				disabledDates: {
					to: '',
					from: ''
				},
				disabledBirthdays: {
					to: '',
					from: ''
				},
				openDate: new Date(),
				disabledDatesStart: {},
				productValidated: false,
				productToValidate: [],
				wizardTitle: "Carica la tua prova d'acquisto",
				videoLoaded: false,
				videoOpacity: 1,
				showDateInfoVar: false,
				it: it,
				timeVal: '',
				stepPopUp: true,
				priceFocused: false,
				colorType: '',
				product: '',
				advisor: false,
				productIndex: 1,
				showProdError: false,
				quantity: '',
				disabledDates: {
					to: '',
					from: ''
				},
				pdvs: [],
				pdvsState: false,
				pdvState: false,
				pdvsError: false,
				pdv_prov: '',
				prodSel1: false,
				prodSel2: false,
				pdv: '',
				pdv_city: '',
				pdv_cities: [],
				items: {},
				provincesState: true,
				cities: [],
				citiesState: false,
				zips: [],
				zipsState: false,
				allInvoiceInfo: false,
				question: '',
				canScroll: true,
				scrollFinished: false,
				stepSize: 'md',
				rebate: '',
				totPrice: '',
				priceError: false,
				timeC: false,
				countdown: 0,
				datenow: '',
				showInfo: false,
				token: '',
				id_image: '',
				zip: '',
				city: '',
				province: '',
				endedVideo: true,
				taglia: '',
				taglie: []
			}
		},

		methods: {
			showPopUp() {
				this.popupErrorText(
					'',
					'',
					"<h4 class='Regular p-4'>Dal numero di cellulare registrato potrai partecipare anche tramite <b>WhatsApp®</b> inviando la tua prova d'acquisto al numero <b>3917054053</b></h4>",
					'',
					''
				)
			},

			showPdv() {
				var pdv = this.pdv
				pdv.sort((a, b) => a[1].localeCompare(b[1]))
				var text = ''
				for (var x in pdv) {
					text +=
						'<p class="text-left"> <b class="medium">' +
						pdv[x][1] +
						'</b> - ' +
						pdv[x][0] +
						' </p> '
				}

				this.$swal({
					type: '',
					title: '<strong class="modalT danger">Punti vendita.</strong>',
					html: '<div class="pdvContainer">' + text + '</div>',
					confirmButtonText: 'CHIUDI',
					confirmButton: 'closeProductsModal',

					background: 'rgb(255, 255, 255)'
				})
			},
			registerPagefun() {
				var _vue = this
				_vue.registerPage = true

				this.$router.push({ query: { type: this.colorType, viewform: 1 } })
			},
			registration: function (e) {
				//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
				//PER CHIAMARE PASSARE I NOME DEI CAMPI SUL DB, PER PASSARE DEI PARAMETRI NEL DB CHE NON SERVONO AI FINI DELL'INSERIMENTO/////
				//MA COME CONTROLLO NEL BACK E' SUFFICIENTE ANDARE AD INSERIRE NELLA VARIABILE "NOPASS" DEL CONFIG I CAMPI DA NON CONSIDERARE/
				/////////////	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
				let data = this.$encryptByAES({
					player: {
						username_player: this.username_player || '',
						password_player: this.password_player,
						first_name_player: this.first_name_player || '',
						last_name_player: this.last_name_player || '',
						birth_date_player: this.birth_date_player
							? moment(this.birth_date_player).format('YYYY-MM-DD')
							: '',
						address_player: this.address || '',
						civic_number_player: this.civic || '',
						province_player: this.province || '',
						zip_player: this.zip || '',
						city_player: this.city.city || '',
						phone_player: '39' + this.phone_player || '',
						email_player: this.username_player || '',
						agreement1_player: this.agreement1_player || 0,
						agreement2_player: this.agreement2_player || 0,
						agreement3_player: this.agreement3_player || 0,
						agreement4_player: this.agreement4_player || 0,
						agreement5_player: this.agreement5_player || 0,
						txt1_player: this.near || '',
						confirmed_player: 0,
						gender_player: this.gender_player || ''
					}
				})

				this.$callLog('registration', data)

				var itGood = false
				this.$validator.validate().then((valid) => {
					if (valid && !this.existEmail && !this.existPhone) {
						this.$store.dispatch(AUTH_REGISTRATION, data).then(() => {
							this.scrollToTop()

							this.gtagCheck('registration', this.username_player, 'success', 'success')
						})
					} else {
						this.$scrollTo('#' + this.errors.items[0].field, 700, {
							offset: -170
						})
					}
				})
			},

			calcNewStartDate: function () {
				this.openDate = new Date(
					moment().format('YYYY') - 18,
					moment().format('MM') - 1,
					moment().format('DD')
				)
				var newStartDate = new Date(1900, 0, 1)
				var now = new Date(
					moment().format('YYYY') - 18,
					moment().format('MM') - 1,
					moment().format('DD')
				)
				this.disabledDatesStart = {
					to: newStartDate, // Disable all dates up to specific date
					from: now // Disable all dates after specific date
				}
			},
			scrollToTop: function () {
				var _vue = this
				setTimeout(function () {
					_vue.$scrollTo('.main', 700, { offset: -2000 })
				}, 100)
			}
		},
		watch: {
			agreement1_player(val) {},

			xuserx: function (val) {
				if (val) {
					this.xuserx = val.replace(/\d+/g, '')
					this.xuserx = this.xuserx.replace(/[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi, '')
					this.xuserx = this.xuserx.charAt(0).toUpperCase() + this.xuserx.slice(1)
				}
			},
			lastnamex: function (val) {
				if (val) {
					this.lastnamex = val.replace(/\d+/g, '')
					this.lastnamex = this.lastnamex.replace(/[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi, '')
					this.lastnamex = this.lastnamex.charAt(0).toUpperCase() + this.lastnamex.slice(1)
				}
			},
			pdv_city: function (val) {
				if (val) {
					this.pdv_city = val.replace(/\d+/g, '')
					this.pdv_city = this.pdv_city.replace(/[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi, '')
					this.pdv_city = this.pdv_city.charAt(0).toUpperCase() + this.pdv_city.slice(1)
				}
			},
			number: function (val) {
				if (val) {
					this.number = val.replace(/\D+/g, '')
				}
				if (val < 0) {
					this.number = val.replace(val, 0)
				}
			},
			phone: function (val) {
				if (val) {
					this.phone = val.replace(/\D+/g, '')
				}
			},

			first_name_player: function (val) {
				if (val) {
					this.first_name_player = val.replace(/\d+/g, '')
					this.first_name_player = this.first_name_player.replace(
						/[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi,
						''
					)
					this.first_name_player =
						this.first_name_player.charAt(0).toUpperCase() + this.first_name_player.slice(1)
				}
			},
			txt1_player: function (val) {
				if (val < 0) {
					this.txt1_player = val.replace(val, 0)
				}
			},
			cf: function (val) {
				if (val) {
					this.cf = this.cf.replace(/[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi, '')
					this.cf = this.cf.toUpperCase()
				}
			},
			last_name_player: function (val) {
				if (val) {
					this.last_name_player = val.replace(/\d+/g, '')
					this.last_name_player = this.last_name_player.replace(
						/[`~!@#$£%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi,
						''
					)
					this.last_name_player =
						this.last_name_player.charAt(0).toUpperCase() + this.last_name_player.slice(1)
				}
			}
		},
		created: function () {
			this.$validator.extend('notZeros', {
				getMessage: (field) => ' Il campo Numero di telefono non ha un formato valido.',
				validate: (value) => {
					// value must be > zero
					if (
						value > 1000000 &&
						value.length > 1 &&
						value != 30000000 &&
						value != 300000000 &&
						value != 3000000000 &&
						value != 30000000000
					)
						return true
					return false
				}
			})

			this.colorType = this.$route.query.type

			if (this.$route.query.viewform) {
				this.registerPage = true
			}
		},
		mounted: function () {
			// this.scrollToTop();
			;(this.disabledDates = {
				to: this.calcToDate(), // Disable all dates up to specific date
				from: this.calcFromDate() // Disable all dates after specific date
			}),
				(this.disabledBirthdays = {
					from: this.calcToDate18() // Disable all dates after specific date
				})

			this.$track('disabledDates', this.disabledDates)

			this.calcNewStartDate()
			var _vue = this
			setTimeout(function () {
				_vue.populateProvinces(), (_vue.scrollFinished = true)
			}, 1500)
		},
		computed: {
			...mapGetters(['isAuthenticated', 'loadingState', 'authStatus']),
			...mapState({
				profile: (state) => state.user.profile,
				shipData: (state) => state.user.defaultShipData
			})
		}
	}
</script>
<style lang="css" scoped></style>

<style lang="scss">
	// @import './src/assets/scss/forms.scss';
</style>
