<template>
  <div class="widthFull">
    <nav class="navbar fixed-top back-3" style="z-index: 15">
      <div class="container widthFull">
        <div class="navbar-header text-center">
          <a href="https://www.avene.it/" target="_blank">
            <img src="../../assets/images/logo.png" alt="" ref="mainNavLogo"
          /></a>

          <!-- <router-link
						to="/"
						v-scroll-to="{ el: '.main', offset: -2000 }"
					>
					
					</router-link> -->
        </div>
      </div>
    </nav>

    <div
      class="container-fluid fixed back-1 p-4"
      v-if="!acceptCookies && !cookieBot"
    >
      <div class="row justify-content-center">
        <div class="col-12 col-md-5 offset-md-2">
          <span class="Medium">
            Utilizziamo i cookie al fine di personalizzare e migliorare la tua
            esperienza sul nostro sito.
            <br />Visita la nostra
            <a
              style="color: red !important; text-decoration: underline"
              target="_blank"
              :href="privacyPolicy"
              >informativa sulla privacy</a
            >
            per acquisire maggiori informazioni. <br />Utilizzando il nostro
            sito accetti l'utilizzo dei
            <a
              style="color: red !important; text-decoration: underline"
              target="_blank"
              :href="cookiePolicy"
              >cookie</a
            >
            da parte nostra.
          </span>
        </div>

        <div class="col-md-4 text-center">
          <button
            class="btn btn-primary-inv pr-3 pl-3"
            v-on:click="doAcceptCookies"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import { mapGetters, mapState } from "vuex";
import { AUTH_LOGOUT, SET_PROMOTION, GET_PROMOTIONS } from "actions/auth";
import { ACCEPT_COOKIES } from "actions/user";

import { innerWidthMixin } from "../../mixins/innerWidthMixin";
import { sharedEnvMixin } from "../../mixins/sharedEnvMixin";
import { checkSoftwareVersion } from "../../mixins/checkSoftwareVersion";
import { popupConfigurationMixin } from "../../mixins/popupConfigurationMixin";

const moment = require("moment");
export default {
  name: "navigation",
  mixins: [
    innerWidthMixin,
    sharedEnvMixin,
    checkSoftwareVersion,
    popupConfigurationMixin,
  ],
  data() {
    return {
      currentPage: "",
      visible: true,
      opacity: { opacity: 1 },

      moment: moment,
    };
  },
  methods: {
    visibilityChanged(isVisible, entry) {},
    logout: function () {
      this.$store
        .dispatch(AUTH_LOGOUT)
        .then(() => (this.$route.path != "/" ? this.$router.push("/") : ""));
    },
    scrollToHowParticipate: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo("#how-participate", 700, { offset: -75 });
      }, 100);
    },
    scrollToAward: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo("#award", 700, { offset: -75 });
      }, 100);
    },
    scrollToTop: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo(".main", 700, { offset: -125 });
      }, 100);
    },
    doAcceptCookies: function () {
      this.$store.dispatch(ACCEPT_COOKIES).then((resp) => {
        //this.$router.push('/')
      });
    },
  },
  watch: {
    $route(to, from) {
      this.checkSoftwareVersion();
    },

    "$route.path": function (route) {
      if (route != "/") {
        this.visible = false;
        this.opacity = {
          opacity: 0,
          "-ms-filter": "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)",
          filter: "alpha(opacity=0)",
        };
      } else {
        this.opacity = {
          opacity: 1,
          "-ms-filter": "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)",
          filter: "alpha(opacity=100)",
        };
        this.visible = true;
      }
    },
    acceptCookies: function () {},
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "acceptCookies",
      "promoOpen",
      "getPromotions",
    ]),
    ...mapState({
      authLoading: (state) => state.auth.status === "loading",
      first_name_player: (state) => `${state.user.profile.first_name_player}`,
    }),
  },

  updated() {
    this.currentPage = this.$route.name;
  },
  mounted: function () {
    this.currentPage = this.$route.name;
    this.$track("isAuthenticated", this.isAuthenticated);
    var _vue = this;
    if (this.cookieBot) {
      let cookieBotScript = document.createElement("script");
      cookieBotScript.setAttribute(
        "src",
        "https://consent.cookiebot.com/uc.js"
      );
      cookieBotScript.setAttribute("data-cbid", this.cookieBotKey);
      cookieBotScript.setAttribute("data-blockingmode", "auto");
      cookieBotScript.async = true;
      cookieBotScript.setAttribute("type", "text/javascript");
      document.head.appendChild(cookieBotScript);
    }
    setTimeout(function () {
      if (_vue.$route.path != "/") {
        _vue.visible = false;
        _vue.opacity = {
          opacity: 0,
          "-ms-filter": "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)",
          filter: "alpha(opacity=0)",
        };
      }
    }, 300);
  },
};
</script>

