<template>
	<div class="widthFull">
		<nav
			class="navbar navbar-light navbar-expand-lg fixed-top back-transparent"
			:class="{'back-transparent' : vw > 998 && scroll < 90 , 'back-semi-tranparent' : vw <= 998 || scroll >= 90 || $route.path != '/'}"
			style="z-index: 15"
		>

			<div class="container-fluid widthFull">

				<div
					class="navbar-header"
					v-if="vw < 998"
				>
					<router-link
						to="/"
						v-scroll-to="{ el: '.main', offset: -2000 }"
						class="navbar-brand router-link-exact-active router-link-active"
					>
						<img
							src="../../assets/images/logo.png"
							:alt="altImg"
							class="img-fluid logo"
							ref="mainNavLogo"
						/>
					</router-link>

				</div>

				<button
					v-if="!(this.moment(start) > timeStore)"
					class="navbar-toggler navbar-toggler-right ml-auto mt-0"
					type="button"
					data-toggle="collapse"
					data-target="#navbarCollapse"
				>
					<span class="navbar-toggler-icon"></span>
				</button v-else>

				<div
					class="collapse navbar-collapse flex-column ml-0"
					id="navbarCollapse"
					v-if="!(this.moment(start) > timeStore)"
				>

					<ul
						class="navbar-nav "
						:class="{'row' : vw > 992}"
					>

						<div :class="{'col-5 d-flex align-items-center' : vw > 992}">

							<li>
								<div
									class="icon text-center my-3 d-flex justify-content-center"
									v-if="vw > 1400 || vw <= 992"
								>

									<a
										href="https://www.facebook.com/birramoretti"
										target="blank"
									>
										<font-awesome-icon
											:icon="['fab', 'facebook-f']"
											class="ml-2 icon"
										/>
									</a>

									<a
										href="https://www.instagram.com/birramoretti.italia/"
										target="blank"
									>
										<font-awesome-icon
											:icon="['fab', 'instagram']"
											class="ml-2 icon "
											style=""
										/>
									</a>

									<a
										href="https://twitter.com/baffo_moretti"
										target="blank"
									>
										<font-awesome-icon
											:icon="['fab', 'twitter']"
											class="ml-2 icon "
											style=""
										/>
									</a>

									<a
										href="https://www.youtube.com/channel/UC8JN7GRw7l_CSps_TVrTvQg"
										target="blank"
									>
										<font-awesome-icon
											:icon="['fab', 'youtube']"
											class="ml-2 icon "
											style=""
										/>
									</a>

								</div>

								<div
									class="icon text-center my-3 "
									v-else
								>

									<div class="text-center">

										<a
											href="https://www.facebook.com/birramoretti"
											target="blank"
										>
											<font-awesome-icon
												:icon="['fab', 'facebook-f']"
												class="ml-2 icon"
											/>
										</a>

										<a
											href="https://www.instagram.com/birramoretti.italia/"
											target="blank"
										>
											<font-awesome-icon
												:icon="['fab', 'instagram']"
												class="ml-2 icon "
												style=""
											/>
										</a>

									</div>

									<div>

										<a
											href="https://twitter.com/baffo_moretti"
											target="blank"
										>
											<font-awesome-icon
												:icon="['fab', 'twitter']"
												class="ml-2 icon "
												style=""
											/>
										</a>

										<a
											href="https://www.youtube.com/channel/UC8JN7GRw7l_CSps_TVrTvQg"
											target="blank"
										>
											<font-awesome-icon
												:icon="['fab', 'youtube']"
												class="ml-2 icon "
												style=""
											/>
										</a>
									</div>

								</div>
							</li>

							<li class="nav-item">

								<router-link
									class="nav-link"
									:to="{ path: '/' }"
									v-scroll-to="{ el: '.main', offset: -75 }"
									data-toggle="collapse"
									data-target=".navbar-collapse.show"
								>
									<span class="menuItem">Home</span>
								</router-link>

							</li>

							<li class="nav-item dropdown">
								<a
									class="nav-link dropdown-toggle menuItem"
									href="#"
									id="navbarDropdown"
									role="button"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									Partecipa
								</a>

								<div
									class="dropdown-menu"
									aria-labelledby="navbarDropdown"
								>

									<router-link
										class="dropdown-item menuItem"
										to="regolamento"
										data-toggle="collapse"
										data-target=".navbar-collapse.show"
									><span class="menuItem ipadMenu">Regolamento</span></router-link>

									<router-link
										class="dropdown-item menuItem"
										to="/"
										v-scroll-to="{ el: '#section01', offset: this.vw > 768 ? -this.navHeight : -this.navHeightSmall}"
										data-toggle="collapse"
										data-target=".navbar-collapse.show"
									><span class="menuItem ipadMenu">Come partecipare</span></router-link>

									<router-link
										class="dropdown-item menuItem"
										to="/"
										v-scroll-to="{ el: '#section02', offset: this.vw > 768 ? -this.navHeight : -this.navHeightSmall}"
										data-toggle="collapse"
										data-target=".navbar-collapse.show"
									><span class="menuItem ipadMenu">Il premio</span></router-link>

									<a
										v-if="isProfileLoaded"
										href="https://www.birramoretti.it/"
										class="dropdown-item menuItem"
										target="_blank"
									><span class="menuItem ipadMenu">Scopri birra moretti filtrata a freddo</span></a>

							<li
								v-if="!isAuthenticated && hasLogin"
								class="dropdown-item menuItem"
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
							>
								<router-link
									class="text-decoration-none"
									v-if="$route.path != '/login'"
									to="/login"
								>
									<span class="menuItem ipadMenu">Accedi</span>
								</router-link>
								<router-link
									v-else
									class="text-decoration-none"
									to="/login"
									v-scroll-to="{ el: '.main', offset: -2000 }"
								>
									<span class="menuItem ipadMenu">Accedi</span>
								</router-link>

							</li>
							<li
								v-if="!isAuthenticated && hasLogin && moment(finish) > timeStore"
								class="dropdown-item menuItem"
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
							>
								<router-link
									class="text-decoration-none"
									v-if="$route.path != '/registration'"
									to="/registration"
								>
									<span class="menuItem">Registrati</span>
								</router-link>
								<router-link
									class="text-decoration-none"
									to=""
									v-else
									v-scroll-to="{ el: '.main', offset: -1000 }"
								>
									<span class="menuItem">Registrati</span>
								</router-link>
							</li>

						</div>

						</li>

				</div>

				<div
					class="navbar-header"
					:class="{'col-2' : vw > 992}"
					v-if="vw > 998"
				>
					<router-link
						to="/"
						v-scroll-to="{ el: '.main', offset: -2000 }"
						class="navbar-brand router-link-exact-active router-link-active"
					>
						<img
							src="../../assets/images/logo.png"
							alt=""
							class="img-fluid logo"
							ref="mainNavLogo"
						/>
					</router-link>

				</div>

				<div :class="{'col-5 d-flex align-items-center justify-content-center' : vw > 992}">

					<li
						class="nav-item"
						v-if="!isProfileLoaded"
					>

						<a
							class="
						nav-link"
							href="https://www.birramoretti.it/"
							target="_blank"
						>
							<span class="menuItem">Scopri birra moretti filtrata a freddo</span>
						</a>

					</li>

					<li class="nav-item">

						<router-link
							class="nav-link"
							:to="{ path: '/faq' }"
							v-scroll-to="{ el: '.main', offset: -150 }"
							data-toggle="collapse"
							data-target=".navbar-collapse.show"
						>
							<span class="menuItem">Faq</span>
						</router-link>

					</li>

					<li
						v-if="isProfileLoaded && hasLogin"
						class="nav-item dropdown mr-0 pd-0"
					>
						<a
							class="nav-link dropdown-toggle menuItem mr-0 pd-0"
							href="#"
							id="navbardrop"
							data-toggle="dropdown"
						>
							<font-awesome-icon
								class="pointer mr-0 pd-0"
								icon="user"
								size="1x"
							/>
							Ciao {{ first_name_player }}
						</a>
						<div
							class="dropdown-menu bgDrop"
							data-toggle="collapse "
							data-target=".navbar-collapse.show"
						>
							<router-link
								class="dropdown-item menuItem"
								to="/account"
								v-scroll-to="'.main'"
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
							><span class="menuItem ipadMenu">Il Mio Profilo</span></router-link>
							<router-link
								class="dropdown-item menuItem"
								to="/receipts"
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
							><span class="menuItem ipadMenu">Le mie giocate</span></router-link>
							<a
								class="dropdown-item pointer menuItem black"
								@click="logout"
							><span class="menuItem ipadMenu">Esci</span></a>
						</div>
					</li>

				</div>

				</ul>
			</div>

	</div>
	</nav>

	<div
		class="container-fluid fixed back-1 p-1 p-md-4 "
		v-if="!acceptCookies && !cookieBot"
	>

		<div class="row justify-content-center">

			<div class="col-12 relative">

				<font-awesome-icon
					:icon="['fa', 'times']"
					class="ml-2 icon icon--absolute"
					@click="doAcceptCookies"
				/>

				<p class="Medium px-1">Questo sito www.birramoretti/vinciunacena utilizza cookie tecnici sia di prima parte (Heineken Italia S.p.A.) che di terze parti e potrà, con il consenso dell’utente, utilizzare anche cookie di profilazione o altri strumenti di tracciamento, anche di terze parti, al fine di: inviare messaggi pubblicitari in linea con le preferenze manifestate dall’utente stesso nell’ambito dell’utilizzo delle funzionalità e della navigazione in rete; effettuare analisi e monitoraggio dei comportamenti dell’utente; consentire all’utente di effettuare comunicazioni e interazioni attraverso i social. Cliccando sul tasto <b>“ACCETTA TUTTO”</b>, l’utente acconsente all’uso di tutti i cookie non tecnici, inclusi quindi quelli di profilazione, analitici e social. Il consenso è facoltativo e può essere revocato in qualsiasi momento. Se l’utente desidera gestire le proprie preferenze può cliccare sul tasto <b>“PERSONALIZZA COOKIE”</b> (accessibile in ogni momento dal sito). Per sapere di più sui cookie che usiamo può accedere alla COOKIE POLICY di Heineken Italia S.p.A. da dove è possibile esprimere le preferenze sui singoli cookie. Chiudendo questo banner - cliccando sulla X in alto a destra - l’utente non presta il consenso all’uso dei cookie che richiedono il consenso, mantenendo le impostazioni di default (solo cookie tecnici attivi).</p>

				<div class="d-flex justify-content-center">

					<router-link
						class="btn btn-primary text-decoration-none px-3 mx-md-5"
						:to="{path: '/cookies', query: {cookies: 'accept'}}"
					>
						<span> Personalizza cookie</span>
					</router-link>

					<button
						class="btn btn-primary  pr-3 pl-3 mx-md-5"
						v-on:click="doAcceptCookies"
					>
						Accetta tutto
					</button>
				</div>

				<!-- <span class="Medium">

					Utilizziamo2 i cookie al fine di personalizzare e migliorare la tua
					esperienza sul nostro sito.
					<br />Visita la nostra
					<a
						style="color: white !important; text-decoration: underline"
						target="_blank"
						:href="privacyPolicy"
					>informativa sulla privacy</a>
					per acquisire maggiori informazioni. <br />Utilizzando il nostro
					sito accetti l'utilizzo dei cookie da parte nostra.
				</span> -->
			</div>

			<div class="col-md-4 text-center  ">

			</div>
		</div>
	</div>
	</div>
</template>
 
<script>
import { mapGetters, mapState } from "vuex";
import { AUTH_LOGOUT, SET_PROMOTION, GET_PROMOTIONS } from "actions/auth";
import { ACCEPT_COOKIES } from "actions/user";

import { innerWidthMixin } from "../../mixins/innerWidthMixin";
import { sharedEnvMixin } from "../../mixins/sharedEnvMixin";
import { checkSoftwareVersion } from "../../mixins/checkSoftwareVersion";
import { popupConfigurationMixin } from "../../mixins/popupConfigurationMixin";

const moment = require("moment");
export default {
  name: "navigation",
  mixins: [
    innerWidthMixin,
    sharedEnvMixin,
    checkSoftwareVersion,
    popupConfigurationMixin,
  ],
  data() {
    return {
      currentPage: "",
      visible: true,
      opacity: { opacity: 1 },
      tagsState: false,
      canUpdate: false,
      moment: moment,
      reset: true,
    };
  },
  methods: {
    triggerRelay(path) {
      if (this.acceptRelay42) {
        if (this.reset) {
          _st("resetTags");
        }
        _st(
          "setPageStructure",
          "Heineken-IT|" + process.env.VUE_APP_RELAY_NAME + "|" + path
        );

        switch (path) {
          case "Home":
          case "HowToParticipate":
          case "Login":
          case "ThePrice":
          case "FAQ":
          case "Regulations":
          case "MyParticipations":
          case "PrivacyPolicy":
          case "CookiesPolicy":
            this.setRelayDataLayer();
            break;

          default:
            break;
        }
      }
    },

    setRelayDataLayer: function () {
      if (this.tagsState) {
        var data = {
          brand: process.env.VUE_APP_RELAY_NAME,
          timeStamp: +new Date(),
          url: location.href,
          "referrer-url": document.referrer,
          userAgent: window.navigator.userAgent,
        };

        this.$track("setRelayDataLayer", data);

        _st("addTagProperties", data);
        _st("loadTags");
      }
    },

    visibilityChanged(isVisible, entry) {},
    logout: function () {
      this.$store
        .dispatch(AUTH_LOGOUT)
        .then(() => (this.$route.path != "/" ? this.$router.push("/") : ""));
    },
    scrollToHowParticipate: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo("#how-participate", 700, { offset: -75 });
      }, 100);
    },
    scrollToAward: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo("#award", 700, { offset: -75 });
      }, 100);
    },
    scrollToTop: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo(".main", 700, { offset: -125 });
      }, 100);
    },
    doAcceptCookies: function () {
      this.$store.dispatch(ACCEPT_COOKIES).then((resp) => {
        //this.$router.push('/')
      });
    },
  },
  watch: {
    $route(to, from) {
      this.checkSoftwareVersion();
    },

    "$route.path": function (route) {
      if (route != "/") {
        this.visible = false;
        this.opacity = {
          opacity: 0,
          "-ms-filter": "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)",
          filter: "alpha(opacity=0)",
        };
      } else {
        this.opacity = {
          opacity: 1,
          "-ms-filter": "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)",
          filter: "alpha(opacity=100)",
        };
        this.visible = true;
      }
    },
    acceptCookies: function () {},
  },

  beforeUpdate: function () {
    if (this.oldHash != this.$route.hash || this.oldRoute != this.$route.path) {
      var _vue = this;
      var rt = _vue.$route.path;
      var hs = _vue.$route.hash;

      _vue.oldHash = hs;
      _vue.oldRoute = rt;

      setTimeout(function () {
        if (rt == "/") {
          if (hs == "#home" || hs == "") {
            _vue.triggerRelay("Home");
          } else if (hs == "#howplaySection") {
            _vue.triggerRelay("HowToParticipate");
          } else if (hs == "#awardSection") {
            _vue.triggerRelay("ThePrice");
          } else if (hs == "#login") {
            _vue.triggerRelay("Login");
          }
        }

        if (rt == "/faq") {
          _vue.triggerRelay("FAQ");
        }

        if (rt == "/login") {
          _vue.triggerRelay("Login");
        }
        if (rt == "/registration") {
          _vue.triggerRelay("Registration");
        }
        if (rt == "/regolamento") {
          _vue.triggerRelay("Regulations");
        }

        if (rt == "/cookies") {
          _vue.triggerRelay("CookiesPolicy");
        }

        if (rt == "/play/" || rt == "/play") {
          if (hs == "" || hs == "#howplaySection") {
            _vue.triggerRelay("UploadInvoice");
          } else if (hs == "#step1") {
            _vue.triggerRelay("UploadInvoice");
          } else if (hs == "#step2") {
            _vue.triggerRelay();
          } else if (hs == "#ConfirmInvoiceData") {
            _vue.triggerRelay("InsertShippingData");
          } else if (hs == "#step4") {
            _vue.triggerRelay("Participation");
          } else if (hs == "#partecipa") {
            _vue.triggerRelay("UploadInvoice");
          } else if (hs == "#login") {
            _vue.triggerRelay("Login");
          }
        }

        if (rt == "/privacy") {
          _vue.triggerRelay("PrivacyPolicy");
        }
        if (rt == "/account") {
          _vue.triggerRelay("PreferenceSetting");
        }
        if (rt == "/receipts") {
          _vue.triggerRelay("MyParticipations");
        }
      }, 700);

      this.canUpdate = false;
    }
  },

  computed: {
    timeStore() {
      return this.$timestore.now;
    },
    ...mapGetters([
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "acceptCookies",
      "promoOpen",
      "getPromotions",
      "acceptRelay42",
    ]),
    ...mapState({
      authLoading: (state) => state.auth.status === "loading",
      first_name_player: (state) => `${state.user.profile.first_name_player}`,
    }),
  },

  updated() {
    this.currentPage = this.$route.name;
  },
  mounted: function () {
    this.currentPage = this.$route.name;
    this.$track("isAuthenticated", this.isAuthenticated);
    var _vue = this;

    setTimeout(function () {
      if (_vue.$route.path != "/") {
        _vue.visible = false;
        _vue.opacity = {
          opacity: 0,
          "-ms-filter": "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)",
          filter: "alpha(opacity=0)",
        };
      }
    }, 300);

    (function (a, d, e, b, f, c, s) {
      a[b] =
        a[b] ||
        function () {
          a[b].q.push(arguments);
        };
      a[b].q = [];
      c = d.createElement(e);
      c.async = 1;
      c.src = "//tdn.r42tag.com/lib/" + f + ".js";
      s = d.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(c, s);
    })(window, document, "script", "_st", "1360-v1");

    var _vue = this;
    setTimeout(async function () {
      _vue.tagsState = true;

      if (
        _vue.$route.path != "/registration" &&
        _vue.$route.path != "/play" &&
        _vue.$route.path != "/account"
      ) {
        _vue.setRelayDataLayer();
      }
    }, 2000);
  },
};
</script>
