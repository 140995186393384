import axios from 'axios';
import {
	axiosMixin
} from "./axiosMixin.js";

export const checkSoftwareVersion = {
	methods: {

		async checkSoftwareVersion() {

			let version = localStorage.getItem('sw_version');
			if (!version) version = false;

			let data = {
				version: version,
				id_platform: process.env.ID_PLATFORM,
			}

			let r = await axiosMixin.methods.axiosCall('api/checkSoftwareVersion', data, 'POST');

			let message = r.data.message;

			try {
				if (r.status < parseInt(process.env.VUE_APP_CATCH)) { // then
					if (message) {
						if (version != message) {
							localStorage.setItem('sw_version', message);
							window.location.reload();
						}
					}
				}
			} catch (error) {}
		},
	},



}