var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widthFull relative customBg pt-3 min-height-calc",attrs:{"id":"registration"}},[(_vm.authStatus != 'success')?_c('div',{staticClass:"container-fluid"},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"container-fluid pb-5"},[(_vm.loadingState)?_c('loading'):_vm._e(),(_vm.authStatus != 'success')?_c('form',{staticClass:"pt-0 pb-3 pt-3",attrs:{"id":"registration-form"},on:{"submit":function($event){$event.preventDefault();return _vm.registration.apply(null, arguments)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.adult2),expression:"adult2"}],staticClass:"form-row justify-content-center"},[_c('div',{staticClass:"col-md-6 col-lg-4 mb-4 mb-md-3"},[_c('div',{staticClass:"group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.first_name_player),expression:"first_name_player"},{name:"validate",rawName:"v-validate",value:({ required: true, max: 128, min: 2 }),expression:"{ required: true, max: 128, min: 2 }"}],staticClass:"form-control",class:{
								'is-invalid': _vm.errors.has('first_name_player'),
								valid: !!_vm.first_name_player
							},attrs:{"type":"text","id":"first_name_player","name":"first_name_player","data-vv-as":"Nome"},domProps:{"value":(_vm.first_name_player)},on:{"input":function($event){if($event.target.composing){ return; }_vm.first_name_player=$event.target.value}}}),_c('span',{staticClass:"highlight"}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"first_name_player"}},[_vm._v("Nome ")]),(_vm.errors.has('first_name_player'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('first_name_player'))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-md-6 col-lg-4 mb-4 mb-md-3"},[_c('div',{staticClass:"group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.last_name_player),expression:"last_name_player"},{name:"validate",rawName:"v-validate",value:({ required: true, max: 128, min: 2 }),expression:"{ required: true, max: 128, min: 2 }"}],staticClass:"form-control",class:{
								'is-invalid': _vm.errors.has('last_name_player'),
								valid: !!_vm.last_name_player
							},attrs:{"type":"text","id":"last_name_player","name":"last_name_player","data-vv-as":"Cognome"},domProps:{"value":(_vm.last_name_player)},on:{"input":function($event){if($event.target.composing){ return; }_vm.last_name_player=$event.target.value}}}),_c('span',{staticClass:"highlight"}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"last_name_player"}},[_vm._v("Cognome")]),(_vm.errors.has('last_name_player'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('last_name_player'))+" ")]):_vm._e()])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.adult2),expression:"adult2"}],staticClass:"form-row justify-content-center"},[_c('div',{staticClass:"col-md-6 col-lg-4 mb-4 mb-md-3"},[_c('div',{staticClass:"group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username_player),expression:"username_player"},{name:"validate",rawName:"v-validate",value:({
								required: true,
								email: true,
								temporaryEmail: true,
								plusIssueEmail: true,
								max: 56
							}),expression:"{\n\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\temail: true,\n\t\t\t\t\t\t\t\ttemporaryEmail: true,\n\t\t\t\t\t\t\t\tplusIssueEmail: true,\n\t\t\t\t\t\t\t\tmax: 56\n\t\t\t\t\t\t\t}"}],ref:"username_player",staticClass:"form-control",class:{
								'is-invalid': _vm.errors.has('username_player') || _vm.existEmail,
								valid: !!_vm.username_player
							},attrs:{"type":"text","id":"username_player","name":"username_player","data-vv-as":"username_player"},domProps:{"value":(_vm.username_player)},on:{"blur":_vm.checkIfExistEmail,"input":function($event){if($event.target.composing){ return; }_vm.username_player=$event.target.value}}}),_c('span',{staticClass:"highlight"}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"username_player"}},[_vm._v("Indirizzo E-Mail")]),(_vm.existEmail)?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[(_vm.existEmailSocial)?_c('span',[_vm._v("Email digitato già presente. Sei registrato con: "),_c('strong',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.existEmailSocial))])]):_c('span',[_vm._v("Email digitato già presente")])]):_vm._e(),(_vm.errors.has('username_player'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('username_player'))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-md-6 col-lg-4 mb-4 mb-md-3"},[_c('div',{staticClass:"group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirm_username),expression:"confirm_username"},{name:"validate",rawName:"v-validate",value:({
								required: true,
								email: true,
								temporaryEmail: true,
								plusIssueEmail: true,
								max: 56,
								confirmed: _vm.username_player
							}),expression:"{\n\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\temail: true,\n\t\t\t\t\t\t\t\ttemporaryEmail: true,\n\t\t\t\t\t\t\t\tplusIssueEmail: true,\n\t\t\t\t\t\t\t\tmax: 56,\n\t\t\t\t\t\t\t\tconfirmed: username_player\n\t\t\t\t\t\t\t}"}],staticClass:"form-control",class:{
								'is-invalid': _vm.errors.has('confirm_username'),
								valid: !!_vm.confirm_username
							},attrs:{"autocomplete":"on","type":"text","id":"confirm_username","name":"confirm_username","data-vv-as":"Conferma Password"},domProps:{"value":(_vm.confirm_username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirm_username=$event.target.value}}}),_c('span',{staticClass:"highlight"}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"confirm_username"}},[_vm._v("Ripeti Indirizzo E-Mail")]),(_vm.errors.has('confirm_username'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('confirm_username'))+" ")]):_vm._e()])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.adult2),expression:"adult2"}],staticClass:"form-row justify-content-center"},[_c('div',{staticClass:"col-md-6 col-lg-4 mb-4 mb-md-3"},[_c('div',{staticClass:"group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_player),expression:"password_player"},{name:"validate",rawName:"v-validate",value:({
								required: true,
								passwordCheck: true,
								min: 8,
								max: 56
							}),expression:"{\n\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\tpasswordCheck: true,\n\t\t\t\t\t\t\t\tmin: 8,\n\t\t\t\t\t\t\t\tmax: 56\n\t\t\t\t\t\t\t}"}],staticClass:"form-control",class:{
								'is-invalid': _vm.errors.has('password_player'),
								valid: !!_vm.password_player
							},attrs:{"autocomplete":"on","type":"password","id":"password_player","name":"password_player","data-vv-as":"password_player"},domProps:{"value":(_vm.password_player)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_player=$event.target.value}}}),_c('span',{staticClass:"highlight"}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"password_player"}},[_vm._v("Password")]),(_vm.errors.has('password_player'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('password_player'))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-md-6 col-lg-4 mb-4 mb-md-3"},[_c('div',{staticClass:"group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirm_password),expression:"confirm_password"},{name:"validate",rawName:"v-validate",value:({
								required: true,
								passwordCheck: true,
								min: 8,
								max: 56,
								confirmed: _vm.password_player
							}),expression:"{\n\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\tpasswordCheck: true,\n\t\t\t\t\t\t\t\tmin: 8,\n\t\t\t\t\t\t\t\tmax: 56,\n\t\t\t\t\t\t\t\tconfirmed: password_player\n\t\t\t\t\t\t\t}"}],staticClass:"form-control",class:{
								'is-invalid': _vm.errors.has('confirm_password'),
								valid: !!_vm.confirm_password
							},attrs:{"autocomplete":"on","type":"password","id":"confirm_password","name":"confirm_password","data-vv-as":"Conferma Password"},domProps:{"value":(_vm.confirm_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirm_password=$event.target.value}}}),_c('span',{staticClass:"highlight"}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"confirm_password"}},[_vm._v("Ripeti Password")]),(_vm.errors.has('confirm_password'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('confirm_password'))+" ")]):_vm._e()])])]),_c('div',{staticClass:"form-row justify-content-center"},[_c('div',{staticClass:"col-md-6 col-lg-4 mb-4 mb-md-3"},[_c('div',{staticClass:"group"},[_c('datepicker',{attrs:{"language":_vm.it,"full-month-name":true,"open-date":_vm.openDate,"initialView":'year',"format":'dd MMMM yyyy',"monday-first":true,"disabledDates":_vm.disabledBirthdays,"name":"birth_date_player","input-class":"form-control "},model:{value:(_vm.birth_date_player),callback:function ($$v) {_vm.birth_date_player=$$v},expression:"birth_date_player"}}),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"},{name:"model",rawName:"v-model",value:(_vm.birth_date_player),expression:"birth_date_player"},{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"form-control datepicker",class:{
								'is-invalid': _vm.errors.has('birth_date_player'),
								valid: !!_vm.birth_date_player
							},attrs:{"type":"text","id":"birth_date_player","name":"birth_date_player","data-vv-as":"Data di nascita"},domProps:{"value":(_vm.birth_date_player)},on:{"input":function($event){if($event.target.composing){ return; }_vm.birth_date_player=$event.target.value}}}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"address"}},[_vm._v("Data di nascita*")]),(_vm.errors.has('birth_date_player'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('birth_date_player'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"col-md-6 col-lg-4 mb-4 mb-md-3"},[_c('div',{staticClass:"group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"},{name:"validate",rawName:"v-validate",value:({ required: true, max: 128, min: 5 }),expression:"{ required: true, max: 128, min: 5 }"}],staticClass:"form-control",class:{
								'is-invalid': _vm.errors.has('address'),
								valid: !!_vm.address
							},attrs:{"type":"text","autocomplete":"randomr","id":"address","name":"address","data-vv-as":"Indirizzo"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.address=$event.target.value}}}),_c('span',{staticClass:"highlight"}),_c('span',{staticClass:"bar"}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"address"}},[_vm._v("Indirizzo *")]),(_vm.errors.has('address'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('address'))+" ")]):_vm._e()])])]),_c('div',{staticClass:"form-row justify-content-center"},[_c('div',{staticClass:"col-md-6 col-lg-4 mb-4 mb-md-3"},[_c('div',{staticClass:"group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.civic),expression:"civic"},{name:"validate",rawName:"v-validate",value:({ required: true, max: 15 }),expression:"{ required: true, max: 15 }"}],staticClass:"form-control",class:{
								'is-invalid': _vm.errors.has('civic'),
								valid: !!_vm.civic
							},attrs:{"type":"text","autocomplete":"randomt","id":"civic","name":"civic","data-vv-as":"Civico"},domProps:{"value":(_vm.civic)},on:{"input":function($event){if($event.target.composing){ return; }_vm.civic=$event.target.value}}}),_c('span',{staticClass:"highlight"}),_c('span',{staticClass:"bar"}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"civic"}},[_vm._v("Civico *")]),(_vm.errors.has('civic'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('civic'))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-md-6 col-lg-4 mb-4 mb-md-3"},[_c('div',{staticClass:"group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.province),expression:"province"},{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"form-control",class:{
								'is-invalid': _vm.errors.has('province'),
								valid: !!_vm.province
							},attrs:{"autocomplete":"randomy","id":"province","name":"province","data-vv-as":"Provincia"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.province=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[(!_vm.shipData.province)?_c('option',{attrs:{"disabled":"","value":"","selected":"selected"}}):_c('option',{attrs:{"selected":"selected"},domProps:{"value":_vm.shipData.province}},[_vm._v(" "+_vm._s(_vm.shipData.province)+" ")]),_vm._l((_vm.provinces),function(province){return _c('option',{key:province.sigla,domProps:{"value":province.sigla,"selected":province.sigla == _vm.shipData.province}},[_vm._v(" "+_vm._s(province.sigla)+" - "+_vm._s(province.provincia)+" ")])})],2),_c('span',{staticClass:"highlight"}),_c('span',{staticClass:"bar"}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"province"}},[_vm._v("Provincia *")]),(_vm.provincesState)?_c('div',[_c('font-awesome-icon',{staticClass:"loadSpinner",attrs:{"id":"loadProvince","icon":"spinner","size":"1x","pulse":""}})],1):_vm._e(),(_vm.errors.has('province'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('province'))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-12"}),_c('div',{staticClass:"col-md-6 col-lg-4 mb-4 mb-md-3"},[_c('div',{staticClass:"group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.city),expression:"city"},{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"form-control",class:{
								'is-invalid': _vm.errors.has('city'),
								valid: !!_vm.city
							},attrs:{"autocomplete":"randomu","id":"city","name":"city","data-vv-as":"Località","disabled":_vm.citiesState},on:{"click":_vm.checkProvince,"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.city=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[(!_vm.shipData.city)?_c('option',{attrs:{"disabled":"","value":"","selected":"selected"}}):_vm._e(),_c('option',{attrs:{"else":"","selected":"selected"},domProps:{"value":{ city: _vm.shipData.city }}},[_vm._v(" "+_vm._s(_vm.shipData.city)+" ")]),_vm._l((_vm.cities),function(city){return _c('option',{key:city.comune,domProps:{"value":{
									cityistat: city.istat,
									city: city.comune
								},"selected":city.comune == _vm.shipData.city}},[_vm._v(" "+_vm._s(city.comune)+" ")])})],2),_c('span',{staticClass:"highlight"}),_c('span',{staticClass:"bar"}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"city"}},[_vm._v("Località *"),(_vm.cityError)?_c('span',{staticClass:"formError"},[_vm._v("Prima scegli Provincia")]):_vm._e()]),(_vm.citiesState)?_c('div',[_c('font-awesome-icon',{staticClass:"loadSpinner",attrs:{"id":"loadCity","icon":"spinner","size":"1x","pulse":""}})],1):_vm._e(),(_vm.errors.has('city'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('city'))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-md-6 col-lg-4 mb-4 mb-md-3"},[_c('div',{staticClass:"group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.zip),expression:"zip"},{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"form-control",class:{
								'is-invalid': _vm.errors.has('zip'),
								valid: !!_vm.zip
							},attrs:{"autocomplete":"randomi","id":"zip","name":"zip","data-vv-as":"Cap","disabled":_vm.zipsState},on:{"click":_vm.checkZip,"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.zip=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[(!_vm.shipData.zip)?_c('option',{attrs:{"value":"","disabled":""},domProps:{"selected":true}}):_vm._e(),_vm._l((_vm.zips),function(zip){return _c('option',{key:zip.cap,domProps:{"value":zip.cap,"selected":zip.cap == _vm.shipData.zip}},[_vm._v(" "+_vm._s(zip.cap)+" ")])})],2),_c('span',{staticClass:"highlight"}),_c('span',{staticClass:"bar"}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"zip"}},[_vm._v("Cap *"),(_vm.zipError)?_c('span',{staticClass:"formError"},[_vm._v("Prima scegli Località")]):_vm._e()]),(_vm.zipsState)?_c('div',[_c('font-awesome-icon',{staticClass:"loadSpinner",attrs:{"id":"loadZips","icon":"spinner","size":"1x","pulse":""}})],1):_vm._e(),(_vm.errors.has('zip'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('zip'))+" ")]):_vm._e()])])]),_c('div',{staticClass:"form-row justify-content-center"},[_c('div',{staticClass:"col-md-6 col-lg-4 mb-4 mb-md-3"},[_c('div',{staticClass:"group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.near),expression:"near"},{name:"validate",rawName:"v-validate",value:({ max: 128, min: 2 }),expression:"{ max: 128, min: 2 }"}],staticClass:"form-control",class:{
								'is-invalid': _vm.errors.has('near'),
								valid: !!_vm.near
							},attrs:{"type":"text","autocomplete":"random","id":"near","name":"near","data-vv-as":"Presso"},domProps:{"value":(_vm.near)},on:{"input":function($event){if($event.target.composing){ return; }_vm.near=$event.target.value}}}),_c('span',{staticClass:"highlight"}),_c('span',{staticClass:"bar"}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"lastname"}},[_vm._v("Presso / Nome sul citofono ")]),(_vm.errors.has('near'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('near'))+" ")]):_vm._e()])]),_c('div',{staticClass:"col-md-6 col-lg-4 mb-4 mb-md-3"},[_c('div',{staticClass:"group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.gender_player),expression:"gender_player"},{name:"validate",rawName:"v-validate",value:({ required: false }),expression:"{ required: false }"}],staticClass:"form-control",class:{
								'is-invalid': _vm.errors.has('gender_player'),
								valid: !!_vm.gender_player
							},attrs:{"id":"gender_player","name":"gender_player","data-vv-as":"Sesso"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.gender_player=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.genders),function(item,index){return _c('option',{key:index,domProps:{"value":item}},[_c('p',[_vm._v(_vm._s(item))])])}),0),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"lastname"}},[_vm._v("Sesso ")]),(_vm.errors.has('gender_player'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('gender_player'))+" ")]):_vm._e()])])]),_c('div',{staticClass:"row justify-content-center py-4"},[(_vm.authStatus == 'error')?_c('div',{staticClass:"col-md-8 center badge badge-danger"},[_vm._v(" Errore conessione ")]):_vm._e(),_c('div',{staticClass:"col-md-8"},[_c('h5',{staticClass:"Regular"},[_vm._v(" Dichiaro di essere maggiorenne, di aver preso visione del "),_c('a',{staticClass:"underline",attrs:{"href":_vm.regolamentoPolicy,"target":"_blank"}},[_vm._v("regolamento concorso")]),_vm._v(" e "),_c('a',{staticClass:"underline",attrs:{"href":_vm.privacyPolicy,"target":"_blank"}},[_vm._v("l'Informativa per il trattamento dei dati personali")]),_vm._v(" ne accetto il contenuto per: ")])]),_c('div',{staticClass:"col-md-8"},[_c('h5',[_vm._v(" La partecipazione alla presente iniziativa* ")]),_c('div',{staticClass:"form-check my-3 my-md-0"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-5"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.agreement1_player),expression:"agreement1_player"},{name:"validate",rawName:"v-validate",value:('required|included:1'),expression:"'required|included:1'"}],staticClass:"mt-3 mr-3",class:{
										'is-invalid': _vm.errors.has('agreement1_player')
									},attrs:{"type":"radio","name":"agreement1_player","id":"pr3","value":"1"},domProps:{"checked":_vm._q(_vm.agreement1_player,"1")},on:{"change":function($event){_vm.agreement1_player="1"}}}),_vm._m(1)]),_c('div',{staticClass:"ml-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.agreement1_player),expression:"agreement1_player"},{name:"validate",rawName:"v-validate",value:('required|included:1'),expression:"'required|included:1'"}],staticClass:"mt-3 mr-3",attrs:{"type":"radio","name":"agreement1_player","id":"pr4","value":"0"},domProps:{"checked":_vm._q(_vm.agreement1_player,"0")},on:{"change":function($event){_vm.agreement1_player="0"}}}),_vm._m(2)])]),_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('agreement1_player')),expression:"errors.has('agreement1_player')"}],staticClass:"red"},[_vm._v(" Consenso di presa visione va accettato ")])])]),_c('div',{staticClass:"col-md-8"},[_c('h5',[_vm._v("Attività di marketing quali invio di materiale promozionale e pubblicitario anche a mezzo e-mail, sms o equivalente ")]),_c('div',{staticClass:"form-check my-3 my-md-0"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-5"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.agreement2_player),expression:"agreement2_player"},{name:"validate",rawName:"v-validate",value:('required|included:0,1'),expression:"'required|included:0,1'"}],staticClass:"mt-3 mr-3",class:{
										'is-invalid': _vm.errors.has('agreement2_player')
									},attrs:{"type":"radio","name":"agreement2_player","id":"pr5","value":"1"},domProps:{"checked":_vm._q(_vm.agreement2_player,"1")},on:{"change":function($event){_vm.agreement2_player="1"}}}),_vm._m(3)]),_c('div',{staticClass:"ml-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.agreement2_player),expression:"agreement2_player"},{name:"validate",rawName:"v-validate",value:('required|included:0,1'),expression:"'required|included:0,1'"}],staticClass:"mt-3 mr-3",attrs:{"type":"radio","name":"agreement2_player","id":"pr6","value":"0"},domProps:{"checked":_vm._q(_vm.agreement2_player,"0")},on:{"change":function($event){_vm.agreement2_player="0"}}}),_vm._m(4)])]),_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('agreement2_player')),expression:"errors.has('agreement2_player')"}],staticClass:"red"},[_vm._v(" Consenso per finalità di markenting va accettato oppure rifiutato ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.adult2),expression:"adult2"}],staticClass:"col-md-12 center mt-3"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("REGISTRATI")])])])]):_vm._e(),(_vm.authStatus == 'success')?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center py-5"},[_c('div',{staticClass:"col-12 col-md-8 mx-auto"},[_c('div',{staticClass:"center"},[_c('h3',{staticClass:"Bold py-2 color-1 pt-5"},[_vm._v("Conferma la tua email")]),_vm._m(5),_vm._m(6),_c('div',{staticClass:"mt-5"},[_c('router-link',{staticClass:"btn btn-primary uppercase",attrs:{"tag":"button","to":"/login"}},[_vm._v(" accedi ")])],1)])])])]):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center mt-4"},[_c('div',{staticClass:"col-8 text-center"},[_c('h3',{staticClass:"col-12 center Bold pb-2 color-1 uppercase"},[_vm._v(" Registrati ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"custom-pos-label",attrs:{"for":"pr3"}},[_c('div',{},[_c('span',{staticClass:"uppercase"},[_vm._v("Sì")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"custom-pos-label",attrs:{"for":"pr4"}},[_c('div',{},[_c('span',{staticClass:"uppercase"},[_vm._v("No")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"custom-pos-label",attrs:{"for":"pr5"}},[_c('div',{},[_c('span',{staticClass:"uppercase"},[_vm._v("Sì")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"custom-pos-label",attrs:{"for":"pr6"}},[_c('div',{},[_c('span',{staticClass:"uppercase"},[_vm._v("No")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"mt-4"},[_vm._v(" Ti abbiamo inviato una mail all’indirizzo che ci hai indicato. "),_c('br'),_c('b',{staticClass:"color-1"},[_vm._v(" Controlla la tua casella ")]),_vm._v("di posta, anche nella cartella "),_c('b',{staticClass:"color-1"},[_vm._v("spam")]),_vm._v(". ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"mt-4"},[_c('b',{staticClass:"color-1"},[_vm._v(" Clicca sul ")]),_vm._v("link che troverai all’interno del messaggio per confermare la tua registrazione e torna sul sito per giocare. ")])}]

export { render, staticRenderFns }