
<template>
  <div v-if="active" class="bg_load">
    <div>
      <div class="d-flex align-items-center">
        <h4 class="Medium loadingTextColor">{{ text }}</h4>
        <div v-if="text" class="dot-container">
          <div class="snippet" data-title=".dot-pulse">
            <div class="stage">
              <div class="dot-pulse"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loading_ocr",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      text: "Attendere prego",
      interval: "",

      loadingText: [
        "Caricamento immagine",
        "Lettura dello scontrino",
        "Estrapolazione dei dati",
        "Salvataggio dei risultati",
        "Completamento del processo",
      ],
    };
  },

  methods: {
    returnText() {
      let i = 0;

      this.interval = setInterval(() => {
        this.text = this.loadingText[i % this.loadingText.length];
        i++;
      }, 3500);
    },
  },
  watch: {
    active: function (val) {
      if (val) {
        this.returnText();
      } else {
        this.interval ? clearInterval(this.interval) : "";
      }
    },
  },
  mounted() {},
};
</script>


<style scoped>
/*  LOADSCREEN  */
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400&display=swap");

.bg_load {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #000000be;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgLoad {
  width: 7vw;
  background: transparent;
}

.loadingTextColor {
  color: white;
  text-shadow: 1px 1px rgb(47, 133, 65);
}
/* ****************** */

.dot-container {
  position: relative;
  /* top: 1.6vw; */
  left: 30px;
}

.dot-carousel {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  animation: dotCarousel 1.5s infinite linear;
}

@keyframes dotCarousel {
  0% {
    box-shadow: 9984px 0 0 -1px white, 9999px 0 0 1px white,
      10014px 0 0 -1px white;
  }
  50% {
    box-shadow: 10014px 0 0 -1px white, 9984px 0 0 -1px white,
      9999px 0 0 1px white;
  }
  100% {
    box-shadow: 9999px 0 0 1px white, 10014px 0 0 -1px white,
      9984px 0 0 -1px white;
  }
}

/* ============================================== */
/* * Dot Pulse */
/* ==============================================  */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 7px;
  height: 7px;
  border-radius: 0px;
  background-color: white;
  color: white;
  box-shadow: 9999px 0 0 -5px white;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 7px;
  height: 7px;
  border-radius: 0px;
  background-color: white;
  color: white;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px white;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px white;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px white;
  }
  30% {
    box-shadow: 9984px 0 0 2px white;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px white;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px white;
  }
  30% {
    box-shadow: 9999px 0 0 2px white;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px white;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px white;
  }
  30% {
    box-shadow: 10014px 0 0 2px white;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px white;
  }
}
</style>
