	<template>
  <div class="widthFull pt-5 mt-1"></div>
</template>


	<script>
import { mapGetters } from "vuex";

export default {
  name: "header_",
  data() {
    return {
      vw: window.innerWidth,

      finish: process.env.VUE_APP_FINISH,
      colorToView: "",
    };
  },
  mounted() {
    window.onresize = () => {
      this.vw = window.innerWidth;
    };
  },
  methods: {
    playNow: function (val) {
      var _vue = this;
      if (this.vw > 767) {
        /**Pc */
        if (val == 1) {
          setTimeout(function () {
            _vue.$scrollTo("#intro-concorso", 700, { offset: -150 });
          }, 100);
        } else if (val == 2) {
          setTimeout(function () {
            _vue.$scrollTo("#how-participate", 700, { offset: 520 });
          }, 100);
        } else {
          setTimeout(function () {
            _vue.$scrollTo("#how-participate", 700, { offset: -60 });
          }, 100);
        }
      } else if (this.vw < 330) {
        /*Iphone 5*/
        if (val == 1) {
          setTimeout(function () {
            _vue.$scrollTo("#intro-concorso", 700, { offset: 5 });
          }, 100);
        } else if (val == 2) {
          setTimeout(function () {
            _vue.$scrollTo("#how-participate", 700, { offset: 1320 });
          }, 100);
        } else {
          setTimeout(function () {
            _vue.$scrollTo("#how-participate", 700, { offset: -60 });
          }, 100);
        }
      } else {
        /**Mobile */
        if (val == 1) {
          setTimeout(function () {
            _vue.$scrollTo("#intro-concorso", 700, { offset: -120 });
          }, 100);
        } else if (val == 2) {
          setTimeout(function () {
            _vue.$scrollTo("#how-participate", 700, { offset: 1280 });
          }, 100);
        } else {
          setTimeout(function () {
            _vue.$scrollTo("#how-participate", 700, { offset: -60 });
          }, 100);
        }
      }
    },

    scrollToTop: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo(".main", 700, { offset: -125 });
      }, 100);
    },
  },
  mounted: function () {
    if (this.$route.query.type == 1) {
      this.colorToView = 1;
    } else if (this.$route.query.type == 2) {
      this.colorToView = 2;
    } else if (this.$route.query.type == 3) {
      this.colorToView = 3;
    } else if (this.$route.query.type == 4) {
      this.colorToView = 4;
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated", "authStatus", "getPromotions"]),
  },
};
</script>

