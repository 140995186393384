import Vue from 'vue'
import Router from 'vue-router'
import Home from 'components/home'
import Faq from 'components/faq'
import Regolamento from 'components/regulation'

import Login from 'components/login'
import Confirm from 'components/confirm'
import Registration from 'components/registration'
import Receipts from 'components/receipts'
import Play from 'components/play'
import ManageData from 'components/manage-data'
import Privacy from 'components/privacy'

import Cookies from 'components/cookies'

import CheckAge from 'components/check-age'
import Preregistration from 'components/pre-registration'
import CantPlay from 'components/cant-play'
import Forgotpassword from 'components/forgot-password'
import Recoverypassword from 'components/recovery-password'
import Wheretobuy from 'components/where-to-buy'
import PageNotFound from 'components/03-page-not-found'
import NotActive from 'components/02-not-active'
import Account from 'components/account'

import store from '../store'

Vue.use(Router)

const path = process.env.VUE_APP_PATH

const ifNotAuthenticated = (to, from, next) => {
	if (!store.getters.isAuthenticated) {
		next()
		return
	}
	next('/')
}

const ifAuthenticated = (to, from, next) => {
	if (store.getters.isAuthenticated) {
		next()
		return
	}
	next('/login')
}

const scrollTo = (to, from, next) => {
	window.scrollTo({
		top: 0,
		left: 0,
		behavior: 'smooth'
	})
}

const ifComplateAccount = (to, from, next) => {
	if (!store.getters.isProfileLoaded && store.getters.isAuthenticated) {
		store.watch(
			(state, getters) => getters.isProfileLoaded,
			(value) => {
				if (value === true) {
					proceed()
				}
			}
		)
	} else {
		proceed()
	}

	function proceed() {
		if (store.getters.isAuthenticated) {
			if (store.getters.isComplateAccount) {
				next()
				return
			} else {
				//next('/completa-registrazione')
				next()
			}
		} else {
			next()
			return
		}
	}
}
const hasLogin = (to, from, next) => {
	if (process.env.VUE_APP_HAS_LOGIN == 'OFF') {
		next('/')
	}
}

const moment = require('moment')

const ifIsStart = (to, from, next) => {
	// https://wsbot.eu/21008front/?age-gate=1
	// CHECK AGE SYSTEM
	if (to.query['age-gate']) {
		localStorage.setItem('checkAge', true)
	}

	if (localStorage.getItem('checkAge')) {
		if (moment() > moment(process.env.START)) {
			next()
			return
		}
	} else {
		window.location.href = 'https://www.birramoretti.it/agegate?from=sitopromo'
		next('/wait')
		return
	}

	// next('/not-active')
}

const ifStart = (to, from, next) => {
	if (moment(process.env.VUE_APP_START) > moment()) {
		next('/not-active')
		return
	}
	next()
}
const ifEnded = (to, from, next) => {
	if (moment(process.env.VUE_APP_FINISH) < moment()) {
		next('/')
		return
	}
	next()
}
const checkAge = (to, from, next) => {
	if (localStorage.getItem('checkAge')) {
		next()
		return
	}
	if (process.env.VUE_APP_HAS_CHECKAGE == 'ON') {
		next('/check-age')
	} else {
		next()
	}
}
const haveWinPage = (to, from, next) => {
	if (process.env.VUE_APP_HAS_PLAYPAGE == 'ON') {
		next()
		return
	}
	next('/')
}
const haveCheckAge = (to, from, next) => {
	if (process.env.VUE_APP_HAS_CHECKAGE == 'ON') {
		next()
		return
	}
	next('/')
}
const ifTime = (to, from, next) => {
	if (process.env.VUE_APP_HAS_TIME_FOR_PLAY == 'ON') {
		var hour = moment().format('H')
		var now = moment()
		var start = now.toDate()
		var end = now.toDate()
		start.setHours(0)
		start.setMinutes(0)
		start.setSeconds(0)
		start.setMilliseconds(0)

		end.setHours(parseFloat(process.env.VUE_APP_CLOSE))
		end.setMinutes(59)
		end.setSeconds(59)
		end.setMilliseconds(0)
		if (
			hour >= parseFloat(process.env.VUE_APP_OPEN) &&
			hour < parseFloat(process.env.VUE_APP_CLOSE) + 1
		) {
			next()
			return
		} else {
			next('/cant-play')
		}
	} else {
		next()
	}
}

export default new Router({
	mode: 'history',

	base: path,

	routes: [
		{
			path: '/',
			name: 'Home',
			component: Home,
			beforeEnter: guards([ifStart, checkAge, ifComplateAccount, scrollTo])
		},

		{
			path: '/not-active',
			name: 'NotActive',
			component: NotActive
		},
		{
			path: '/play',
			component: Play,
			beforeEnter: guards([
				ifStart,
				ifEnded,
				checkAge,
				ifTime,
				haveWinPage,
				hasLogin,
				ifComplateAccount,
				ifAuthenticated,
				scrollTo
			])
		},

		{
			path: '/account',
			name: 'Account',
			component: Account,
			beforeEnter: guards([
				ifStart,
				hasLogin,
				ifComplateAccount,
				checkAge,
				ifAuthenticated,
				scrollTo
			])
		},
		{
			path: '/receipts',
			name: 'Receipts',
			component: Receipts,
			beforeEnter: guards([
				ifStart,
				hasLogin,
				ifComplateAccount,
				checkAge,
				ifAuthenticated,
				scrollTo
			])
		},
		{
			path: '/cant-play',
			name: 'Cantplay',
			component: CantPlay
		},
		{
			path: '/login',
			name: 'Login',
			component: Login,
			beforeEnter: guards([ifStart, hasLogin, checkAge, ifNotAuthenticated, scrollTo])
		},
		{
			path: '/registration',
			name: 'Registration',
			component: Registration,
			beforeEnter: guards([ifStart, hasLogin, checkAge, ifEnded, ifNotAuthenticated, scrollTo])
		},
		{
			path: '/confirm',
			name: 'Confirm',
			component: Confirm,
			beforeEnter: guards([ifNotAuthenticated, hasLogin, scrollTo])
		},
		{
			path: '/forgot-password',
			name: 'Forgotpassword',
			component: Forgotpassword,
			beforeEnter: guards([ifNotAuthenticated, checkAge, hasLogin, scrollTo])
		},
		{
			path: '/recovery-password',
			name: 'Recoverypassword',
			component: Recoverypassword,
			beforeEnter: guards([ifStart, ifNotAuthenticated, hasLogin, scrollTo])
		},

		{
			path: '/where-to-buy',
			name: 'Wheretobuy',
			component: Wheretobuy,
			beforeEnter: guards([ifStart, scrollTo])
		},
		{
			path: '/cookies',
			name: 'Cookies',
			component: Cookies,
			beforeEnter: guards([ifStart, ifComplateAccount, checkAge])
		},
		{
			path: '*',
			name: 'PageNotFound',
			component: PageNotFound
		},

		{
			path: '/faq',
			name: 'Faq',
			component: Faq,
			beforeEnter: guards([ifStart, checkAge, ifComplateAccount])
		},
		{
			path: '/regolamento',
			name: 'Regolamento',
			component: Regolamento,
			beforeEnter: guards([ifStart, ifComplateAccount, checkAge])
		},
		{
			path: '/privacy',
			name: 'Privacy',
			component: Privacy,
			beforeEnter: guards([ifStart, ifComplateAccount, checkAge])
		},

		{
			path: '/check-age',
			name: 'CheckAge',
			component: CheckAge,
			beforeEnter: guards([ifStart, haveCheckAge])
		},
		// {
		//   path: '/cant-play',
		//   name: 'CantPlay',
		//   component: CantPlay,
		// },

		// {
		//   path: '/pre-registration',
		//   name: 'Preregistration',
		//   component: Preregistration,
		//   beforeEnter: guards([ifStart, ifEnded, checkAge, ifNotAuthenticated]),
		// },
		{
			path: '/manage-data/:token',
			name: 'ManageData',
			component: ManageData,
			beforeEnter: guards([ifStart, hasLogin])
		}
		// {
		//   path: '/social-login/:id',
		//   component: Login,
		//   beforeEnter: guards([ifNotAuthenticated, checkAge, hasLogin]),
		// },
	]
})

// allows using multiple guards for routes
function guards(guards) {
	return async (to, from, next) => {
		// a flag to discard remaining guards
		let changed = false

		// handle next for multiple guards
		const mNext = function (value) {
			// prevent calling next after it is already resolved with a value
			if (changed) return

			// if we have 'value' reslove next with the value and set changed flag
			if (typeof value != 'undefined') {
				changed = true
				next(value)
			}
		}

		// call guards
		for (let i = 0; i < guards.length; i++) {
			if (changed) break
			await guards[i](to, from, mNext)
		}

		// move on if none of guards resolved next with a value
		if (!changed) next()
	}
}
