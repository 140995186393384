<template>
	<div class="widthFull back-3">
		<div v-if="!popUpCheckAge" class="container h-100 pb-5 pb-md-0">
			<loading v-if="loadingState" />

			<div class="center">
				<div class="row justify-content-center center">
					<div class="col-12 center mt-5 pt-5">
						<img class="img-fluid" src="../../assets/images/logo.png" />
					</div>
					<div class="col-12 center">
						<h5 class="theme Bold mt-5" style="font-size: 20px">
							INSERISCI LA TUA DATA DI NASCITA:
						</h5>
					</div>
				</div>

				<div v-if="!block" class="mt-0 pb-2">
					<form @submit.prevent="submit">
						<div class="row justify-content-md-center pb-2">
							<div class="col-md-4 col-12 mt-5 mt-md-0 mb-4 mb-md-0 text-left">
								<small class="vertical uppercase theme Bold" v-if="vw > 600">giorno</small>
								<div class="group mt-2 ml-0 mr-0 pl-0 pr-0 center mt-0">
									<input
										type="text"
										v-model="day"
										class="grey transparentBackground"
										id="day"
										name="day"
										ref="day"
										@blur="handleBlur"
										placeholder="GG"
										data-vv-as="Giorno"
										v-validate="{
											required: true,
											integer: true,
											regex: /^([0-9]+)$/,
											min: 1,
											max: 2,
											min_value: 1,
											max_value: 31
										}"
										:class="{ 'is-invalid': errors.has('day'), valid: !!day }"
									/>
									<span class="highlight bbaRed"></span>
									<span class="bar bbaRed"></span>
									<label class="niceInputLabel grey" for="day"></label>

									<div v-if="errors.has('day')" class="text-left invalid-feedback">
										{{ errors.first('day') }}
									</div>
								</div>
							</div>
							<div class="col-md-7 text-left"></div>
							<div class="col-md-4 col-12 mb-4 mb-md-0 text-left">
								<small class="vertical uppercase theme Bold" v-if="vw > 600">mese</small>
								<div class="group mt-2 ml-0 mr-0 pl-0 pr-0 mt-0">
									<input
										type="text"
										v-model="month"
										class="grey transparentBackground borderBoth"
										id="month"
										name="month"
										placeholder="MM"
										data-vv-as="mese"
										ref="month"
										v-validate="{
											required: true,
											integer: true,
											regex: /^([0-9]+)$/,
											min: 1,
											max: 2,
											min_value: 1,
											max_value: 12
										}"
										:class="{
											'is-invalid': errors.has('month'),
											valid: !!month
										}"
									/>
									<span class="highlight bbaRed"></span>
									<span class="bar bbaRed"></span>
									<label class="niceInputLabel grey" for="month"></label>

									<div v-if="errors.has('month')" class="invalid-feedback">
										{{ errors.first('month') }}
									</div>
								</div>
							</div>
							<div class="col-md-7 text-left"></div>
							<div class="col-md-4 col-12 mb-4 mb-md-0 text-left">
								<small class="vertical uppercase theme Bold" v-if="vw > 600">anno</small>
								<div class="group mt-2 ml-0 mr-0 pl-0 pr-0 mt-0 pr-md-3">
									<input
										type="text"
										v-model="year"
										class="grey transparentBackground"
										id="year"
										name="year"
										ref="year"
										placeholder="AAAA"
										data-vv-as="Anno"
										v-validate="{
											required: true,
											integer: true,
											regex: /^([0-9]+)$/,
											min: 4,
											max: 4,
											min_value: 1900,
											max_value: 2019
										}"
										:class="{ 'is-invalid': errors.has('year'), valid: !!year }"
									/>
									<span class="highlight bbaRed"></span>
									<span class="bar bbaRed"></span>
									<label class="niceInputLabel grey" for="year"></label>

									<div v-if="errors.has('year')" class="invalid-feedback">
										{{ errors.first('year') }}
									</div>
								</div>
							</div>
							<div class="col-7 text-left"></div>

							<div
								v-if="
									!errors.has('year') &&
									!errors.has('month') &&
									!errors.has('day') &&
									year &&
									month &&
									day &&
									adult()
								"
								class="col-12 col-md-12 mb-3 mr-0 pr-0"
							></div>

							<div v-if="year && month && day && !adult()" class="col-12 col-md-12 mb-3">
								<h3 class="theme back-color-2 mt-3 Bold uppercase">
									Devi essere maggiorenne per partecipare!
								</h3>
							</div>
						</div>
					</form>
				</div>

				<div v-else class="maxSm">
					<h3 class="white center">
						<b> UNFORTUNATELY THIS WEBSITE CANNOT BE VIEWED FROM YOUR CURRENT LOCATION </b>
					</h3>
				</div>
			</div>
		</div>
		<div v-else>
			<Home />
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'
	import Home from '../home/index.vue'

	import { USER_REQUEST } from 'actions/user'
	import Datepicker from 'vuejs-datepicker'
	import { it } from 'vuejs-datepicker/dist/locale'
	import { USER_CHECK_AGE } from 'actions/user'
	const moment = require('moment')

	import { toDateMixin } from '../../mixins/toDateMixin'

	export default {
		name: 'checkage',
		components: {
			Datepicker,
			Home
		},
		mixins: [toDateMixin],
		data() {
			return {
				loadingState: '',
				userToken: '',
				date: '',
				day: '',
				month: '',
				year: '',
				vw: window.innerWidth,
				nations: [],
				nationR: '',
				nationsState: true,
				image: 'idle.png',
				popUpCheckAge: process.env.VUE_APP_HAS_CHECKAGE_POPUP == 'ON' ? true : false,
				displayPop: process.env.VUE_APP_HAS_CHECKAGE_POPUP == 'ON' ? this.checkPopoUp() : false,
				it: it,
				block: false,
				disabledDates: {
					to: '',
					from: ''
				}
			}
		},
		methods: {
			adult: function () {
				if (this.year.length == 4) {
					var date = moment(this.year + '-' + this.month + '-' + this.day)
					if (date < this.timeStore.subtract(18, 'years')) {
						var _vue = this
						setTimeout(function () {
							_vue.submit()
						}, 100)

						return true
					} else {
						return false
					}
				} else {
					return true
				}
			},
			checkPopoUp: function () {
				console.log('ooooooooooooooooooo')
				this.$swal({
					type: '',
					imageUrl: require('../../assets/images/logo.png'),
					title:
						'<h4 class="mt-5 color-3" style="color:white!important">PER ACCEDERE AL SITO DEVI <br/> CONFERMARE LA TUA ETÀ <br/><br/><br/><br/> SEI MAGGIORENNE?</h4>',
					confirmButtonText: 'SI',
					cancelButtonText: 'NO',
					confirmButtonColor: '#15267',
					showCancelButton: true,
					cancelButtonColor: 'black',
					customClass: 'swal-checkAge',
					allowOutsideClick: false,
					allowEscapeKey: false
				}).then((result) => {
					if (result.value) {
						this.$store.dispatch(USER_CHECK_AGE).then((resp) => {})
					} else {
						this.$swal({
							type: '',
							imageUrl: require('../../assets/images/logo.png'),
							title:
								'<h4 class="mt-5" style="color:white!important">PER ACCEDERE AL SITO DEVI <br/> ESSERE MAGGIORENNE! <br/><br/><br/><br/></h4>',
							showConfirmButton: false,
							showCancelButton: false,
							cancelButtonColor: 'black',
							customClass: 'swal-checkAge',
							allowOutsideClick: false,
							allowEscapeKey: false
						})
					}
				})
			},
			handleBlur(e) {},

			redirectTo: function () {
				window.location.replace('https://www.beviresponsabile.it/')
			},
			submit: function () {
				this.$validator.validate().then((valid) => {
					if (valid) {
						// RECONSTRUCT QUERY STRING
						var queryString = '?'
						var i = 0

						var queryArray = this.$router.currentRoute.query

						for (var x in queryArray) {
							if (i == 0) {
								queryString += x + '=' + queryArray[x]
								i++
							} else {
								queryString += '&' + x + '=' + queryArray[x]
								i++
							}
						}

						this.$store.dispatch(USER_CHECK_AGE).then((resp) => {
							this.$router.push('/' + queryString)
						})
					}
				})
			}
		},
		watch: {
			date: function (value) {},
			nationR: function (val) {},
			day: function (val) {
				if (val) {
					if (val.length >= 2) {
						this.$refs.month.focus()
					}

					this.day = val.replace(/\D+/g, '')
				}
			},
			month: function (val) {
				if (val) {
					if (val.length >= 2) {
						this.$refs.year.focus()
					}
					if (this.day.length < 2) {
						if (this.day < 10) {
							if (this.day.length < 2 && this.day != 0) {
								this.day = '0' + this.day
							}
						}
					}
					this.month = val.replace(/\D+/g, '')
				}
			},
			year: function (val) {
				if (val) {
					if (this.month.length < 2) {
						if (this.month < 10) {
							if (this.month.length < 2 && this.month != 0) {
								this.month = '0' + this.month
							}
						}
					}
					this.year = val.replace(/\D+/g, '')
				}
			},
			checkAge: function () {
				if (process.env.VUE_APP_HAS_CHECKAGE_POPUP == 'ON' && !this.checkAge) {
					//this.checkPopoUp()
				}
				if (this.checkAge) {
					this.$router.push('/')
				}
			}
		},
		mounted: function () {
			this.disabledDates = {
				//to: this.calcToDate(), // Disable all dates up to specific date
				from: this.calcFromDate() // Disable all dates after specific date
			}
		},
		computed: {
			...mapState({
				profile: (state) => state.user.profile,
				checkAge: (state) => state.user.checkAge
			}),
			timeStore() {
				return this.$timestore.now
			}
		}
	}
</script>

<style lang="scss" scoped>
	.fitAvailableHeight {
		min-height: -webkit-fill-available;
	}
	.relative {
		position: relative;
	}

	.widthAge {
		width: 52.4vw;
	}

	.widthAgeLogo {
		@media screen and (min-width: 1025px) {
			margin-top: -73px;
		}

		width: 20vw;
	}

	.borderRed {
		border-bottom: 1px solid #89082d !important;
	}

	.vertical {
		transform: rotate(-90deg);
		transform-origin: right top;
		text-transform: uppercase;
		font-weight: 900;
		font-size: 10px;
		left: -8.5rem;
		letter-spacing: 0.5rem;
		position: absolute;
		width: 8.5rem;
		top: 0;
		text-align: right;
		padding-right: 1.5rem;
		box-sizing: border-box;
	}
	.transparentBackground {
		background: transparent !important;
		color: #024992 !important;

		text-align: center !important;

		font-family: Bold !important;
		font-weight: 900 !important;
		@media screen and (min-width: 20px) {
			width: 200px;
			font-size: 30px !important;
			height: 60px;
			border: 0px solid #024992 !important;
		}
		@media screen and (min-width: 700px) {
			width: 400px;
			font-size: 6.5rem !important;
			height: 138px;
			border-bottom: 7px solid #024992 !important;
		}

		border: 0px !important;

		text-align: left !important;
		padding: 0;
		&:focus {
			border: 0px !important;
			border-bottom: 7px solid #0249928f !important;
		}
		&::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: #024992 !important;
			opacity: 0.3; /* Firefox */

			@media screen and (min-width: 1025px) {
				font-size: 120px;
			}
		}
	}

	.mrMR {
		margin-left: -50px !important;
	}

	.borderLeft::before {
		content: '';
		position: absolute;
		width: 3px;
		top: 30px;
		height: 80px;
		background: #024992 !important;
		left: 0;
	}
	.borderRight::after {
		content: '';
		position: absolute;
		width: 3px;
		top: 30px;
		height: 80px;
		background: #024992 !important;
		right: 0;
	}

	::-webkit-input-placeholder {
		/* Edge */
		color: #024992 !important;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #024992 !important;
	}

	::placeholder {
		color: #024992 !important;
	}

	.bigTitle {
		font-size: 27px;
	}

	.mediumTitle {
		font-size: 47px;
	}

	.verCentered {
		position: absolute;
		top: 43%;
		left: 50%;
		-webkit-transform: translateX(-50%) translateY(-50%);
		-ms-transform: translateX(-50%) translateY(-50%);
		-moz-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
	}

	.checkAgeBackground {
		/*background-image: url(../../assets/images/bg_agegate.jpg);*/
		z-index: 22222;
		background-size: cover;
		background-position: center;
		margin-top: -142px;
		min-height: -webkit-fill-available;
		height: 101vh;
		min-height: -webkit-min-content;
		min-height: -moz-min-content;
		min-height: min-content;
	}
	.bar:before,
	.bar:after {
		content: '';
		height: 1px;
		width: 0;
		bottom: 1px;
		position: absolute;
		background: transparent !important;
		transition: 0.2s ease all;
		-moz-transition: 0.2s ease all;
		-webkit-transition: 0.2s ease all;
	}
	.maxWidthImgCA {
		max-width: 222px;

		width: 25vh;
	}

	.shadowBoxN {
		box-shadow: inset 0px -8px 15px 0px #000000ee;
	}

	.indC {
		z-index: 2;
	}

	.dividerCheckAge {
		width: 100vw;
		height: 0px;
		border: none;
		border-bottom: 5px solid;
		border-style: none none dotted none;
		border-color: #5d3827;
	}

	.errorCard {
		background: #d9291d;
		position: absolute;
		width: 8%;
		height: 14%;
		top: 53%;
		left: 5%;
		-webkit-animation-name: scale-data-v-6717d66c;
		animation-name: scale-data-v-6717d66c;
		-webkit-animation-duration: 2s;
		animation-duration: 2s;
		transform: rotate(-20deg);
		z-index: -1;
	}

	@keyframes scale {
		from {
			transform: scale(7) rotate(-20deg) translateX(-20%) translateY(-20%);
			z-index: 5555;
		}
		to {
			transform: scale(1) rotate(-200deg) translateX(0%) translateY(0%);
			z-index: -1;
		}
	}

	@media (min-width: 20px) and (max-width: 767px) {
		.bigTitle {
			font-size: 20px;
		}

		.widthAgeLogo {
			width: 80vw;
		}

		.widthAge {
			width: 92vw;
		}

		.borderLeft::before {
			opacity: 0;
		}
		.borderRight::after {
			opacity: 0;
		}

		.mrMR {
			margin-left: 0px !important;
		}

		.group {
			margin-top: 5px !important;
		}

		.transparentBackground {
			background: transparent !important;
			line-height: 45px;
			color: #024992 !important;
			font-size: 38px !important;
			font-family: Bold !important;
			font-weight: 900 !important;
			padding: 0;
			height: 40px;
		}

		.mediumTitle {
			font-size: 30px;
		}

		.errorCard {
			left: 10%;
		}

		.maxWidthImgCA {
			max-width: 122px;
			width: 20vw;
		}

		.checkAgeBackground {
		}
	}
</style>
