export const innerWidthMixin = {

	data() {
		return {
			vw: window.innerWidth,
			vh: window.innerHeight,
			scroll: 0,

		};
	},


	methods: {

		updateScroll: function () {
			this.scroll = window.pageYOffset;
		},


		handleResize: function () {

			this.vw = window.innerWidth;
			var h = window.innerHeight;


			if (this.vw > 1200 && h > 800) {
				this.stepSize = 'md';
			} else {
				this.stepSize = 'sm';
			}
		},
	},

	mounted() {
		window.addEventListener('resize', this.handleResize);
		window.addEventListener('scroll', this.updateScroll);
		this.handleResize();
	},

}