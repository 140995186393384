<template>
	<div class="widthFull relative customBg padding-top-calc  min-height-calc">
		<loading v-if="loadingState" />

		<div class="container-fluid">
			<div class="row pt-5 pb-3 center">
				<h1 class="col-12 center Regular pb-2 color-1 uppercase">
					<b> COOKIE POLICY </b>
				</h1>

			</div>
		</div>

		<div class="container mt-4 mb-5 regulationContainer orange_bg pt-5 pb-5">
			<!--
			<h4 class="text-left">
				Introduzione
			</h4>
			Per far funzionare correttamente il sito web e per fornire i prodotti e i servizi più rilevanti ai visitatori e ai membri del nostro sito, collochiamo sul vostro dispositivo piccoli file di dati chiamati cookie. Questa informativa fornisce informazioni sui cookie presenti all’interno del sito web e su come gestirne l’interazione.

		  <h4 class="text-left">
				Che cos'è un Cookie?
		  </h4>
			<p>
				Un cookie è un piccolo file di testo che un sito web salva sul vostro computer o dispositivo mobile quando visitate il sito web. I cookie vengono quindi inviati di nuovo al sito web di origine ad ogni visita successiva, o ad un altro sito web che riconosce il cookie, per sviluppare una registrazione dell'attività online dell'utente. I cookie di questo sito possono essere definiti come cookie di prima parte (impostati dal nostro sito web) o di terze parti (impostato da un altro sito web) e inoltre possono anche essere contenuti nelle e-mail che ricevete da noi.
			</p>
		-->

			<div
				id="cookiedeclarationbox"
				class="widthFull"
			>

			</div>

			<!--
			<router-link class="link underline" to="/informativa_privacy">
				all’informativa privacy.
			</router-link>
		-->

		</div>
	</div>
</template>


<script>
	import { mapState } from 'vuex';
	import axios from 'axios';
	import { USER_REQUEST } from 'actions/user';
	import { mapGetters } from 'vuex';
	import { sharedEnvMixin } from '../../mixins/sharedEnvMixin';

	export default {
		name: 'cookiesPolicy',
		mixins: [sharedEnvMixin],
		data() {
			return {
				loadingState: '',
				userToken: '',
			};
		},
		methods: {},
		watch: {},
		mounted: function () {
		
			let cookieBotScript = document.createElement('script');
			cookieBotScript.setAttribute(
				'src',
				'https://consent.cookiebot.com/' + process.env.VUE_APP_COOKIEBOTKEY + '/cd.js'
			);
			cookieBotScript.setAttribute('id', 'CookieDeclaration');
			cookieBotScript.async = true;
			cookieBotScript.setAttribute('type', 'text/javascript');

			document
				.getElementById('cookiedeclarationbox')
				.appendChild(cookieBotScript);
		},
		computed: {
			...mapGetters([
				'getProfile',
				'isAuthenticated',
				'isProfileLoaded',
				'acceptCookies',
				'checkAge',
			]),
		},
	};
</script>
<style lang="scss">
	@import './src/assets/scss/forms.scss';
</style>