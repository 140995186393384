<template>
	<div class="widthFull section01 customBg" id="how-partecipate">
		<div class="container-fluid py-4 px-5">
			<div class="row justify-content-center mb-3">
				<div class="col-lg-6 col-md-6 col-10 back-image center Bold white h4 pt-2 pb-2">
					COME PARTECIPARE
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-lg-6 col-md-10 col-12 center">
					<h3 class="color-1">Acquista almeno 6€ di prodotti della linea Peroni Nastro Azzurro*</h3>
				</div>
				<div class="col-12">
					<img src="../../assets/images/pack-home.png" class="img img-fluid" /></div
			></div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	const moment = require('moment')

	import { sharedEnvMixin } from '../../mixins/sharedEnvMixin'
	import { innerWidthMixin } from '../../mixins/innerWidthMixin'
	import { popupConfigurationMixin } from '../../mixins/popupConfigurationMixin'

	export default {
		name: 'award',

		mixins: [sharedEnvMixin, innerWidthMixin, popupConfigurationMixin],
		data() {
			return {
				oldPresent: false,
				moment: moment
			}
		},
		methods: {
			showPopUp() {
				this.popupErrorText(
					'',
					'',
					"<h4 class='Regular p-4'>Con il numero di cellulare indicato in fase di registrazione potrai partecipare anche tramite <b>WhatsApp®</b> inviando la tua prova d'acquisto al numero <b>3917054053</b></h4>",
					'',
					''
				)
			}
		},

		mounted: function () {
			window.addEventListener('resize', this.handleResize)
		},
		computed: {
			...mapGetters(['isAuthenticated', 'authStatus', 'getPromotions', 'getOldPromotions']),
			timeStore() {
				return this.$timestore.now
			}
		}
	}
</script>
