<template>
  <div class="d-flex group">
    <select class="form-control" v-model="product">
      <option value="" selected disabled hidden>Prodotto</option>
      <option placeholder="Prodotto" v-for="item in itemdata" :value="item">
        {{ item.name_product }}
      </option>
    </select>
    <input
      maxlength="2"
      class="form-control"
      type="number"
      placeholder="Quantità"
      min="0"
      v-model="product.quantity_product"
    />
    <input
      maxlength="4"
      class="form-control"
      type="number"
      min="0"
      step="0.01"
      placeholder="Importo unitario"
      v-model="product.amount_product"
    />
    <button
      v-if="index != 0"
      @click.prevent="remove"
      class="btn-danger rounded"
    >
      X
    </button>
  </div>
</template>

<script>
export default {
  props: {
    itemdata: Array,
    y: Object,
    index: Number,
    selected: Object,
  },

  data() {
    return {
      result: {},
      product: this.y,
    };
  },
  methods: {
    remove() {
      this.$emit("remove", this.index);
    },
  },
  watch: {
    product() {
      this.result[this.index] = this.product;
      this.$emit("check", this.result[this.index], this.index);
    },
    "product.quantity_product"() {
      this.result[this.index] = this.product;
      this.$emit("check", this.result[this.index], this.index);
    },
    "product.amount_product"() {
      this.result[this.index] = this.product;
      this.$emit("check", this.result[this.index], this.index);
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style>
</style>