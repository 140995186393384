<template>
	<div class="receipts min-height-calc--footer customBg h-100 pt-5">
		<loading v-if="loadingState" />

		<div class="container-fluid pt-md-0">
			<div class="row justify-content-center">
				<div class="col-8 text-center mt-4">
					<h3 class="col-12 center Bold pb-2 color-1 uppercase"> Le tue partecipazioni </h3>
				</div>
			</div>
		</div>

		<div class="container pb-2">
			<div class="row">
				<div class="col-12">
					<template v-if="dataAcquired">
						<div class="row justify-content-center">
							<div class="col-11 col-md-7 center Medium pl-2 mb-2">
								<h4 v-if="dataArray.length" class="Medium">
									Clicca sulla riga di partecipazione per maggiori informazioni
								</h4>
							</div>
						</div>

						<div v-if="dataArray.length" class="justify-content-center">
							<h5
								class="center pointer miniReceipt pl-1 pr-1 mt-2 Medium col-12"
								v-for="(item, index) in dataArray"
								:key="index"
								v-scroll-to="{ el: '.itemReview', offset: -140 }"
								@click="selectedImage(item)"
							>
								<div class="row justify-content-center">
									<div
										class="col-11 col-md-7 text-left pl-md-5 Regular giocata text-center text-lg-left"
									>
										Pratica - {{ item.practice_code_play }} del
										{{ moment(item.datetime_play).format('DD-MM-YYYY') }}
										<br v-if="vw <= 1024" />
										( canale: {{ item.channel_play }} -

										<ReceiptStatus
											:practice="null"
											:validation="item.validation_manage_play"
											:result="item.result_play"
										/>
										)
									</div>
								</div>
							</h5>
						</div>

						<hr class="back-1 receipts__divider mb-3 w-75" />

						<div v-if="dataArray.length" class="row pb-5 itemReview justify-content-center">
							<div v-if="selectedPlay.amount" class="col-md-12 mt-5">
								<h5 class="Medium w-100 center">
									<span class="Bold uppercase">Pratica:</span>
									{{ selectedPlay.identification_code }} <br />
									<i v-if="selectedPlay.status == 'not_approved'" class="red"
										>Motivazione bocciatura : {{ selectedPlay.motivation }}</i
									>
								</h5>
							</div>

							<template v-if="selectedPlay.receipt_amount_play">
								<h5 class="Regular w-100 center">
									<ReceiptStatus
										:practice="selectedPlay.practice_code_play"
										:validation="selectedPlay.validation_manage_play"
										:result="selectedPlay.result_play"
									/>
								</h5>

								<div class="col-md-3">
									<h5 class="color-2 underline">Dati della prova d'acquisto</h5>
									<div>
										<h5 class="w-100 Medium">
											Data:
											<span class="Medium">{{
												moment(selectedPlay.receipt_date_play).format('DD-MM-YYYY')
											}}</span>
										</h5>
										<h5 v-if="selectedPlay.receipt_time_play != '00:00:00'" class="w-100 Medium">
											Ora:
											<span class="Medium">{{ selectedPlay.receipt_time_play }}</span>
										</h5>
										<h5 class="w-100 Medium">
											Numero:
											<span class="Medium">{{ selectedPlay.receipt_number_play }}</span>
										</h5>
										<h5 class="w-100 Medium">
											Importo:
											<span class="Medium">{{ selectedPlay.receipt_amount_play }} €</span>
										</h5>
									</div>

									<div v-if="selectedPlay.product_registered.length > 0">
										<h5 class="color-2 underline mt-4">Prodotti acquistati</h5>
										<div>
											<h5
												class="w-100 Medium"
												v-for="(item, index) in selectedPlay.product_registered"
												@key="index"
											>
												{{ item.name_product }} ({{ item.quantity_product }}pz.)
											</h5>
										</div>
									</div>
								</div>
								<div class="col-md-3 my-3 my-md-0" v-if="true">
									<button class="btn-primary modButton ml-3" @click="viewUrlJwt">
										visulizza scontrino
									</button>

									<viewer class="imagesViewer clearfix widthFull mt-3" :ref="'viewer'">
										<template v-for="(image, key) in arrayToView">
											<img
												v-if="
													image.substring(image.lastIndexOf('.') + 1, image.length).toLowerCase() !=
													'pdf'
												"
												:src="image"
												:key="key"
												class="img-fluid d-none"
											/>
											<a v-else :href="image" class="pdfViewer" target="_blank">
												<img
													src="../../assets/images/pdf.png"
													:key="key"
													class="img-fluid d-none"
												/>
											</a>
										</template>
									</viewer>
								</div>
							</template>

							<div v-if="datiSpedizione" class="col-md-6">
								<div
									v-if="
										selectedPlay.result_play == 401 && selectedPlay.shipping_address_manage_play
									"
									class="custFormColor"
								>
									<h5 v-if="selectedPlay.shipping_address_manage_play" class="Bold w-100 mb-4">
										<h5 class="color-2 underline"> Dati della prova d'acquisto </h5>

										<!--<button
											v-if="
												selectedPlay.shipping_address_manage_play &&
												selectedPlay.shipping_status_manage_play == 700 &&
												!modShipData
											"
											v-on:click="
												modShipData = true
												showReloadInvoice = false
											"
											class="center btn-primary modButton"
										>
											Modifica
										</button>-->
									</h5>

									<div
										v-if="!modShipData && selectedPlay.shipping_address_manage_play"
										class="pb-4"
									>
										<h5 class="w-100 Medium">
											Nome:
											<span class="Medium ml-3">{{
												selectedPlay.shipping_first_name_manage_play
											}}</span>
										</h5>
										<h5 class="w-100 Medium">
											Cognome:
											<span class="Medium ml-3">{{
												selectedPlay.shipping_last_name_manage_play
											}}</span>
										</h5>
										<h5 class="w-100 Medium">
											Indirizzo:
											<span class="Medium ml-3"
												>{{ selectedPlay.shipping_address_manage_play }}
												{{ selectedPlay.shipping_civic_number_manage_play }}</span
											>
										</h5>
										<h5 class="w-100 Medium">
											Località:
											<span class="Medium ml-3"
												>{{ selectedPlay.shipping_city_manage_play }} (
												{{ selectedPlay.shipping_province_manage_play }} )</span
											>
										</h5>
										<h5 class="w-100 Medium">
											Cap:
											<span class="Medium ml-3">{{ selectedPlay.shipping_zip_manage_play }}</span>
										</h5>
										<h5 class="w-100 Medium">
											Telefono:
											<span class="Medium ml-3">{{ selectedPlay.shipping_phone_manage_play }}</span>
										</h5>
										<h5 class="w-100 Medium">
											Presso:
											<span class="Medium ml-3">{{ selectedPlay.shipping_notes_manage_play }}</span>
										</h5>
									</div>

									<div v-if="modShipData" class="widthFull">
										<form @submit.prevent="validateShipData">
											<h5 class="Medium w-100 pt-3"> Inserisci i dati per la spedizione </h5>

											<div class="form-row">
												<div class="col-md-12 mb-3">
													<div class="group ml-0">
														<input
															type="text"
															autocomplete="randomq"
															class="form-control"
															v-model="xuserx"
															id="xuserx"
															name="xuserx"
															data-vv-as="Nome"
															v-validate="{ required: true, max: 128, min: 2 }"
															:class="{
																'is-invalid': errors.has('xuserx'),
																valid: !!xuserx
															}"
														/>
														<span class="highlight"></span>
														<span class="bar"></span>
														<label class="niceInputLabel" for="xuserx">Nome *</label>
														<div v-if="errors.has('xuserx')" class="invalid-feedback">
															{{ errors.first('xuserx') }}
														</div>
													</div>
												</div>
											</div>
											<div class="form-row">
												<div class="col-md-12 mb-3">
													<div class="group ml-0">
														<input
															type="text"
															autocomplete="offw"
															class="form-control"
															v-model="lastnamex"
															id="lastnamex"
															name="lastnamex"
															data-vv-as="Cognome"
															v-validate="{ required: true, max: 128, min: 2 }"
															:class="{
																'is-invalid': errors.has('lastnamex'),
																valid: !!lastnamex
															}"
														/>
														<span class="highlight"></span>
														<span class="bar"></span>
														<label class="niceInputLabel" for="lastnamex">Cognome *</label>

														<div v-if="errors.has('lastnamex')" class="invalid-feedback">
															{{ errors.first('lastnamex') }}
														</div>
													</div>
												</div>
											</div>

											<div class="form-row">
												<div class="col-md-12 mb-3">
													<div class="group ml-0">
														<input
															type="text"
															autocomplete="randome"
															class="form-control"
															v-model="phone"
															id="phone"
															name="phone"
															data-vv-as="Telefono"
															v-validate="{
																required: true,
																max: 11,
																min: 9,
																notZeros: true,
																regex: /^[0,3]+/
															}"
															:class="{
																'is-invalid': errors.has('phone'),
																valid: !!phone
															}"
														/>
														<span class="highlight"></span>
														<span class="bar"></span>
														<label class="niceInputLabel" for="phone">Telefono *</label>
														<div v-if="errors.has('phone')" class="invalid-feedback">
															{{ errors.first('phone') }}
														</div>
													</div>
												</div>
											</div>
											<div class="form-row">
												<div class="col-md-12 mb-3">
													<div class="group ml-0">
														<input
															type="text"
															autocomplete="randomr"
															class="form-control"
															v-model="address"
															id="address"
															name="address"
															data-vv-as="Indirizzo"
															v-validate="{ required: true, max: 128, min: 5 }"
															:class="{
																'is-invalid': errors.has('address'),
																valid: !!address
															}"
														/>
														<span class="highlight"></span>
														<span class="bar"></span>
														<label class="niceInputLabel" for="address">Indirizzo *</label>
														<div v-if="errors.has('address')" class="invalid-feedback">
															{{ errors.first('address') }}
														</div>
													</div>
												</div>
											</div>
											<div class="form-row">
												<div class="col-md-12 mb-3">
													<div class="group ml-0">
														<input
															type="text"
															autocomplete="randomt"
															class="form-control"
															v-model="civic"
															id="civic"
															name="civic"
															data-vv-as="Civico"
															v-validate="{ required: true, max: 15 }"
															:class="{
																'is-invalid': errors.has('civic'),
																valid: !!civic
															}"
														/>
														<span class="highlight"></span>
														<span class="bar"></span>
														<label class="niceInputLabel" for="civic">Civico *</label>
														<div v-if="errors.has('civic')" class="invalid-feedback">
															{{ errors.first('civic') }}
														</div>
													</div>
												</div>
											</div>

											<div class="form-row">
												<div class="col-md-12 mb-3">
													<div class="group ml-0">
														<select
															v-model="province"
															autocomplete="randomy"
															id="province"
															name="province"
															class="form-control"
															data-vv-as="Provincia"
															v-validate="{ required: true }"
															:class="{
																'is-invalid': errors.has('province'),
																valid: !!province
															}"
														>
															<option
																v-if="!shipData.province"
																disabled
																value=""
																selected="selected"
															></option>
															<option v-else :value="shipData.province" selected="selected">
																{{ shipData.province }}
															</option>
															<option
																v-for="province in provinces"
																:key="province.sigla"
																v-bind:value="province.sigla"
																:selected="province.sigla == shipData.province"
															>
																{{ province.sigla }} - {{ province.provincia }}
															</option>
														</select>
														<span class="highlight"></span>
														<span class="bar"></span>
														<label class="niceInputLabel" for="province">Provincia *</label>
														<div v-if="provincesState">
															<font-awesome-icon
																class="loadSpinner"
																id="loadProvince"
																icon="spinner"
																size="1x"
																pulse
															/>
														</div>
														<div v-if="errors.has('province')" class="invalid-feedback">
															{{ errors.first('province') }}
														</div>
													</div>
												</div>
											</div>
											<div class="form-row">
												<div class="col-md-12 mb-3">
													<div class="group ml-0">
														<select
															class="form-control"
															autocomplete="randomu"
															v-model="city"
															v-on:click="checkProvince"
															id="city"
															name="city"
															data-vv-as="Località"
															v-validate="{ required: true }"
															:disabled="citiesState"
															:class="{
																'is-invalid': errors.has('city'),
																valid: !!city
															}"
														>
															<option
																v-if="!shipData.city"
																disabled
																value=""
																selected="selected"
															></option>
															<option else :value="{ city: shipData.city }" selected="selected">
																{{ shipData.city }}
															</option>
															<option
																v-for="city in cities"
																:key="city.comune"
																v-bind:value="{
																	cityistat: city.istat,
																	city: city.comune
																}"
																:selected="city.comune == shipData.city"
															>
																{{ city.comune }}
															</option>
														</select>

														<span class="highlight"></span>
														<span class="bar"></span>
														<label class="niceInputLabel" for="city"
															>Località *<span v-if="cityError" class="formError"
																>Prima scegli Provincia</span
															></label
														>
														<div v-if="citiesState">
															<font-awesome-icon
																class="loadSpinner"
																id="loadCity"
																icon="spinner"
																size="1x"
																pulse
															/>
														</div>
														<div v-if="errors.has('city')" class="invalid-feedback">
															{{ errors.first('city') }}
														</div>
													</div>
												</div>
											</div>
											<div class="form-row">
												<div class="col-md-12 mb-3">
													<div class="group ml-0">
														<select
															class="form-control"
															autocomplete="randomi"
															v-model="zip"
															v-on:click="checkZip"
															id="zip"
															name="zip"
															data-vv-as="Cap"
															v-validate="{ required: true }"
															:disabled="zipsState"
															:class="{
																'is-invalid': errors.has('zip'),
																valid: !!zip
															}"
														>
															<option
																v-if="!shipData.zip"
																value=""
																disabled
																:selected="true"
															></option>
															<option
																v-for="zip in zips"
																:key="zip.cap"
																v-bind:value="zip.cap"
																:selected="zip.cap == shipData.zip"
															>
																{{ zip.cap }}
															</option>
														</select>
														<span class="highlight"></span>
														<span class="bar"></span>
														<label class="niceInputLabel" for="zip"
															>Cap *<span v-if="zipError" class="formError"
																>Prima scegli Località</span
															>
														</label>
														<div v-if="zipsState">
															<font-awesome-icon
																class="loadSpinner"
																id="loadZips"
																icon="spinner"
																size="1x"
																pulse
															/>
														</div>
														<div v-if="errors.has('zip')" class="invalid-feedback">
															{{ errors.first('zip') }}
														</div>
													</div>
												</div>
											</div>
											<div class="form-row">
												<div class="col-md-12 mb-1">
													<div class="group ml-0">
														<input
															type="text"
															autocomplete="randomo"
															class="form-control"
															v-model="near"
															id="near"
															name="near"
															data-vv-as="Presso"
															v-validate="{ max: 128, min: 2 }"
															:class="{
																'is-invalid': errors.has('near'),
																valid: !!near
															}"
														/>
														<span class="highlight"></span>
														<span class="bar"></span>
														<label class="niceInputLabel" for="lastname"
															>Presso / Nome sul citofono
														</label>

														<div v-if="errors.has('near')" class="invalid-feedback">
															{{ errors.first('near') }}
														</div>
													</div>
												</div>
											</div>

											<div class="form-row center">
												<div class="col-md-12 mb-5 mt-3 center">
													<button type="submit" class="btn btn-primary"> Salva </button>
												</div>
											</div>
										</form>
									</div>
								</div>

								<div
									v-else-if="
										selectedPlay.result_play == 401 &&
										selectedPlay.shipping_address_manage_play == null &&
										false
									"
									class="col-md-8 mt-4 custFormColor"
								>
									<form @submit.prevent="updateShipData" class="pt-0 custForm">
										<div class="row justify-content-center">
											<div class="col-12">
												<h5 class="Bold w-100 pt-4 mb-4 text-center">
													<span class="underline"> Inserisci i dati per la spedizione </span>
												</h5>
											</div>
										</div>

										<div class="row justify-content-center">
											<div class="col-lg-6 col-md-6 mb-3">
												<div class="group">
													<input
														type="text"
														autocomplete="randomq"
														class="form-control"
														v-model="xuserx"
														id="xuserx"
														name="xuserx"
														data-vv-as="Nome"
														v-validate="{ required: true, max: 128, min: 2 }"
														:class="{
															'is-invalid': errors.has('xuserx'),
															valid: !!xuserx
														}"
													/>
													<span class="highlight"></span>
													<span class="bar"></span>
													<label class="niceInputLabel" for="xuserx">Nome *</label>
													<div v-if="errors.has('xuserx')" class="invalid-feedback">
														{{ errors.first('xuserx') }}
													</div>
												</div>
											</div>
											<div class="col-lg-6 col-md-6 mb-3">
												<div class="group">
													<input
														type="text"
														autocomplete="offw"
														class="form-control"
														v-model="lastnamex"
														id="lastnamex"
														name="lastnamex"
														data-vv-as="Cognome"
														v-validate="{ required: true, max: 128, min: 2 }"
														:class="{
															'is-invalid': errors.has('lastnamex'),
															valid: !!lastnamex
														}"
													/>
													<span class="highlight"></span>
													<span class="bar"></span>
													<label class="niceInputLabel" for="lastnamex">Cognome *</label>

													<div v-if="errors.has('lastnamex')" class="invalid-feedback">
														{{ errors.first('lastnamex') }}
													</div>
												</div>
											</div>
										</div>

										<div class="row justify-content-center">
											<div class="col-lg-6 col-md-6 mb-3">
												<div class="group">
													<input
														type="text"
														autocomplete="randome"
														class="form-control"
														v-model="phone"
														id="phone"
														name="phone"
														data-vv-as="Telefono"
														v-validate="{
															required: true,
															max: 11,
															min: 9,
															notZeros: true,
															regex: /^[0,3]+/
														}"
														:class="{
															'is-invalid': errors.has('phone'),
															valid: !!phone
														}"
													/>
													<span class="highlight"></span>
													<span class="bar"></span>
													<label class="niceInputLabel" for="phone">Telefono *</label>
													<div v-if="errors.has('phone')" class="invalid-feedback">
														{{ errors.first('phone') }}
													</div>
												</div>
											</div>
											<div class="col-lg-6 col-md-6 mb-3">
												<div class="group">
													<input
														type="text"
														autocomplete="randomr"
														class="form-control"
														v-model="address"
														id="address"
														name="address"
														data-vv-as="Indirizzo"
														v-validate="{ required: true, max: 128, min: 5 }"
														:class="{
															'is-invalid': errors.has('address'),
															valid: !!address
														}"
													/>
													<span class="highlight"></span>
													<span class="bar"></span>
													<label class="niceInputLabel" for="address">Indirizzo *</label>
													<div v-if="errors.has('address')" class="invalid-feedback">
														{{ errors.first('address') }}
													</div>
												</div>
											</div>
										</div>

										<div class="row justify-content-center">
											<div class="col-lg-6 col-md-6 mb-3">
												<div class="group">
													<input
														type="text"
														autocomplete="randomt"
														class="form-control"
														v-model="civic"
														id="civic"
														name="civic"
														data-vv-as="Civico"
														v-validate="{ required: true, max: 15 }"
														:class="{
															'is-invalid': errors.has('civic'),
															valid: !!civic
														}"
													/>
													<span class="highlight"></span>
													<span class="bar"></span>
													<label class="niceInputLabel" for="civic">Civico *</label>
													<div v-if="errors.has('civic')" class="invalid-feedback">
														{{ errors.first('civic') }}
													</div>
												</div>
											</div>
											<div class="col-lg-6 col-md-6 mb-3">
												<div class="group">
													<select
														v-model="province"
														autocomplete="randomy"
														id="province"
														name="province"
														class="form-control"
														data-vv-as="Provincia"
														v-validate="{ required: true }"
														:class="{
															'is-invalid': errors.has('province'),
															valid: !!province
														}"
													>
														<option
															v-if="!shipData.province"
															disabled
															value=""
															selected="selected"
														></option>
														<option v-else :value="shipData.province" selected="selected">
															{{ shipData.province }}
														</option>
														<option
															v-for="province in provinces"
															:key="province.sigla"
															v-bind:value="province.sigla"
															:selected="province.sigla == shipData.province"
														>
															{{ province.sigla }} - {{ province.provincia }}
														</option>
													</select>
													<span class="highlight"></span>
													<span class="bar"></span>
													<label class="niceInputLabel" for="province">Provincia *</label>
													<div v-if="provincesState">
														<font-awesome-icon
															class="loadSpinner"
															id="loadProvince"
															icon="spinner"
															size="1x"
															pulse
														/>
													</div>
													<div v-if="errors.has('province')" class="invalid-feedback">
														{{ errors.first('province') }}
													</div>
												</div>
											</div>
											<div class="col-12"></div>
											<div class="col-lg-6 col-md-6 mb-3">
												<div class="group">
													<select
														class="form-control"
														autocomplete="randomu"
														v-model="city"
														v-on:click="checkProvince"
														id="city"
														name="city"
														data-vv-as="Località"
														v-validate="{ required: true }"
														:disabled="citiesState"
														:class="{
															'is-invalid': errors.has('city'),
															valid: !!city
														}"
													>
														<option
															v-if="!shipData.city"
															disabled
															value=""
															selected="selected"
														></option>
														<option else :value="{ city: shipData.city }" selected="selected">
															{{ shipData.city }}
														</option>
														<option
															v-for="city in cities"
															:key="city.comune"
															v-bind:value="{
																cityistat: city.istat,
																city: city.comune
															}"
															:selected="city.comune == shipData.city"
														>
															{{ city.comune }}
														</option>
													</select>

													<span class="highlight"></span>
													<span class="bar"></span>
													<label class="niceInputLabel" for="city"
														>Località *<span v-if="cityError" class="formError"
															>Prima scegli Provincia</span
														></label
													>
													<div v-if="citiesState">
														<font-awesome-icon
															class="loadSpinner"
															id="loadCity"
															icon="spinner"
															size="1x"
															pulse
														/>
													</div>
													<div v-if="errors.has('city')" class="invalid-feedback">
														{{ errors.first('city') }}
													</div>
												</div>
											</div>
											<div class="col-lg-6 col-md-6 mb-3">
												<div class="group">
													<select
														class="form-control"
														autocomplete="randomi"
														v-model="zip"
														v-on:click="checkZip"
														id="zip"
														name="zip"
														data-vv-as="Cap"
														v-validate="{ required: true }"
														:disabled="zipsState"
														:class="{
															'is-invalid': errors.has('zip'),
															valid: !!zip
														}"
													>
														<option
															v-if="!shipData.zip"
															value=""
															disabled
															:selected="true"
														></option>
														<option
															v-for="zip in zips"
															:key="zip.cap"
															v-bind:value="zip.cap"
															:selected="zip.cap == shipData.zip"
														>
															{{ zip.cap }}
														</option>
													</select>
													<span class="highlight"></span>
													<span class="bar"></span>
													<label class="niceInputLabel" for="zip"
														>Cap *<span v-if="zipError" class="formError"
															>Prima scegli Località</span
														>
													</label>
													<div v-if="zipsState">
														<font-awesome-icon
															class="loadSpinner"
															id="loadZips"
															icon="spinner"
															size="1x"
															pulse
														/>
													</div>
													<div v-if="errors.has('zip')" class="invalid-feedback">
														{{ errors.first('zip') }}
													</div>
												</div>
											</div>
										</div>
										<div class="row justify-content-center">
											<div class="col-md-12 mb-1">
												<div class="group">
													<input
														type="text"
														autocomplete="randomo"
														class="form-control"
														v-model="near"
														id="near"
														name="near"
														data-vv-as="Presso"
														v-validate="{ max: 128, min: 2 }"
														:class="{
															'is-invalid': errors.has('near'),
															valid: !!near
														}"
													/>
													<span class="highlight"></span>
													<span class="bar"></span>
													<label class="niceInputLabel" for="lastname"
														>Presso / Nome sul citofono
													</label>

													<div v-if="errors.has('near')" class="invalid-feedback">
														{{ errors.first('near') }}
													</div>
												</div>
											</div>
										</div>

										<div class="row justify-content-center back-1">
											<div class="col-12 center py-5">
												<button class="btn btn-primary uppercase" type="submit">
													Salva
													<font-awesome-icon :icon="['fas', 'arrow-right']" class="ml-2 icon" />
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>

							<div
								class="col-md-12 center mt-md-3 mt-5"
								v-if="Object.keys(selectedPlay).length !== 0"
							>
								<div v-scroll-to="{ el: '#app', offset: -100 }">
									<button
										class="btn btn-primary uppercase"
										v-on:click="
											selectedPlay = {}
											modShipData = false
											shipData = {}
										"
									>
										Chiudi
									</button>
								</div>
							</div>
						</div>

						<div v-else-if="!dataArray.length && loadingState" class="row">
							<div class="col center">
								<h3>Carico ....</h3>
							</div>
						</div>

						<div v-else-if="isAuthenticated && moment() < moment(finish)" class="row pb-5">
							<div class="col center">
								<h4 class="Medium">Non hai ancora partecipato</h4>
								<router-link
									v-if="moment() < moment(finish)"
									tag="button"
									class="btn btn-primary uppercase mt-2"
									to="/play"
									>partecipa
								</router-link>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapState } from 'vuex'
	import { FROM_SET } from 'actions/auth'
	import axios from 'axios'
	import { USER_REQUEST } from 'actions/user'
	import ReceiptStatus from '../lib/receipt-status.vue'

	import { locationMixin } from '../../mixins/locationMixin'
	import { checkUniqueMixin } from '../../mixins/checkUniqueMixin'
	import { sharedEnvMixin } from '../../mixins/sharedEnvMixin'
	import { playFunctionMixin } from '../../mixins/playFunctionMixin'
	import { popupConfigurationMixin } from '../../mixins/popupConfigurationMixin'
	import { innerWidthMixin } from '../../mixins/innerWidthMixin'
	import { axiosMixin } from '../../mixins/axiosMixin'
	const moment = require('moment')

	export default {
		name: 'receipts',
		mixins: [
			locationMixin,
			checkUniqueMixin,
			sharedEnvMixin,
			playFunctionMixin,
			popupConfigurationMixin,
			innerWidthMixin,
			axiosMixin
		],
		components: {
			ReceiptStatus
		},
		data() {
			return {
				dataAcquired: false,
				loadingState: false,
				userToken: '',

				dataArray: '',
				selectedPlay: {},
				showReloadInvoice: false,
				imageLoaded: true,
				moment: require('moment'),
				invoiceFront: '',
				invoiceFrontName: '',
				invoiceFrontBucket: '',
				uploadFrontPercentage: 0,
				invoiceRear: '',
				invoiceRearName: '',
				invoiceRearBucket: '',
				uploadRearPercentage: 0,
				modShipData: false,
				// UPDATE SHIP DATA

				particularMot: 'Scontrino modificato/non originale',
				particularMot1: 'Il documento d`acquisto non é parlante',

				cities: [],
				citiesState: false,
				zips: [],
				zipsState: false,

				shipData: {},
				taglia: '',
				taglie: [],
				viewerShow: false,
				urlToView: [
					'front_identity_doc_url_player_jwt',
					'rear_identity_doc_url_player_jwt',
					'url1_manage_play_jwt',
					'url1_play_jwt',
					'url2_manage_play_jwt',
					'url2_play_jwt',
					'url3_manage_play_jwt',
					'url3_play_jwt',
					'url4_manage_play_jwt',
					'url4_play_jwt',
					'url5_manage_play_jwt',
					'url5_play_jwt',
					'url6_manage_play_jwt',
					'url6_play_jwt'
				],
				arrayToView: []
			}
		},
		methods: {
			selectedImage(item) {
				this.selectedPlay = item
				this.showReloadInvoice = false
				this.modShipData = false
				this.shipData = {}
				this.showViever = false
				this.viewerShow = false

				for (const key in this.selectedPlay) {
					if (this.urlToView.includes(key) && this.selectedPlay[key] != '') {
						this.arrayToView.push(this.selectedPlay[key])
					}
				}
			},

			viewUrlJwt() {
				// this.viewerShow = true;
				this.show()
				this.retriveNewData()
			},

			validateShipData() {
				this.$validator.validate().then((valid) => {
					if (valid) {
						this.updateShipData()
					}
				})
			},

			scrollToTop: function () {
				var _vue = this
				setTimeout(function () {
					_vue.$scrollTo('.main', 700, { offset: -2000 })
				}, 100)
			},

			prepareModShip(data) {
				var _vue = this
				var dataNew = data
				this.xuserx = data.first_name
				this.lastnamex = data.last_name
				this.address = data.indirizzo
				this.civic = data.civico
				this.province = data.provincia

				setTimeout(function () {
					var citiesNew = _vue.cities
					for (var item in citiesNew) {
						if (citiesNew[item].comune == dataNew.citta) {
							_vue.city = {}
							_vue.city['cityistat'] = citiesNew[item].istat
							_vue.city['city'] = citiesNew[item].comune
						}
					}

					setTimeout(function () {
						_vue.zip = dataNew.cap
					}, 900)
				}, 900)

				this.phone = data.telefono
				this.near = data.presso
			},

			show() {
				this.loadingState = true

				var el = this.$refs['viewer']

				var _vue = this
				el.destroyViewer()
				setTimeout(function () {
					el.createViewer()

					el.$viewer.show()
					_vue.loadingState = false
				}, 1000)
			},

			onLoadHandler: function () {
				this.imageLoaded = true
			},
			loaded: function () {
				this.imageLoaded = true
			},

			async retriveNewData() {
				this.loadingState = true
				var _vue = this
				var data = {
					player_token_player: this.getProfile.player_token_player
				}

				let r = await this.axiosCall('api/myAttempt', data, 'POST')

				let message = r.data.message
				let statusCode = r.data.statusCode
				let description = r.data.message.description

				this.loadingState = false

				try {
					if (r.status < parseInt(process.env.VUE_APP_CATCH)) {
						// then
						if (message) {
							this.dataAcquired = true
							this.dataArray = message

							this.dataArray = message.filter((el) => [400, 401, 402, 403].includes(el.result_play))
						}
					}
				} catch (error) {}
			}
		},
		watch: {
			phone: function (val) {
				if (val) {
					this.phone = val.replace(/\D+/g, '')
				}
			},

			selectedPlay: function (array) {
				this.imageLoaded = true
			},

			modShipData: function (val) {
				if (val) {
					this.populateProvinces()
				}
			}
		},
		mounted: function () {
			this.loadingState = true
			this.populateProvinces()

			this.$store.dispatch(FROM_SET, '')

			setTimeout(() => {
				this.retriveNewData()
			}, 2000)
		},
		created() {
			this.$validator.extend('notZeros', {
				getMessage: (field) => ' Il campo Numero di telefono non ha un formato valido.',
				validate: (value) => {
					// value must be > zero
					if (
						value > 1000000 &&
						value.length > 1 &&
						value != 30000000 &&
						value != 300000000 &&
						value != 3000000000 &&
						value != 30000000000
					)
						return true
					return false
				}
			})
		},
		computed: {
			urlCrypted() {
				this.selectedPlay.practice_code_play
				this.selectedPlay.player_token_player

				let token = this.e({
					practice_code_play: this.selectedPlay.practice_code_play,
					player_token_player: this.selectedPlay.player_token_player
				})

				return token
			},

			...mapGetters([
				'getProfile',
				'isAuthenticated',
				'isProfileLoaded',
				'acceptCookies',
				'checkAge',
				'acceptSalesForce',
				'acceptRelay42',
				'getFrom'
			]),
			...mapState({ profile: (state) => state.user.profile })
		}
	}
</script>

<style lang="css" scoped></style>
