/* eslint-disable promise/param-names */
import {
	AUTH_REQUEST,
	DECRYPT,
	ENCRYPT,
	AUTH_OK,
	AUTH_SOCIAL,
	SET_PROMOTION,
	ACCEPT_REG,
	AUTH_ERROR,
	AUTH_NOT_CONFIRMED,
	AUTH_SUCCESS,
	AUTH_LOGOUT,
	AUTH_REGISTRATION,
	AUTH_COMPLATE_REGISTRATION,
	COMPLATE_REGISTRATION_SUCCESS,
	REGISTRATION_SUCCESS,
	REGISTRATION_ERROR,
	FROM_SET,

} from '../actions/auth'
import {
	USER_REQUEST,
	USER_UPDATE_DATA
} from '../actions/user'
import axios from 'axios'
import Vue from 'vue'
import CryptoJS from 'crypto-js'
const state = {
	token: localStorage.getItem(process.env.VUE_APP_WORMY + process.env.VUE_APP_ICODE) || '',
	status: '',
	tempEncrypt: '',
	tempCrypt: '',
	login_error: '',
	confirm_error: '',
	loadingState: '',
	hasLoadedOnce: false,
	promotions: {},
	promo: 'no',
	regulations: JSON.parse(localStorage.getItem('accept_reg')) || {},
	from: '',

}
const moment = require('moment')
const getters = {
	promoOpen: state => state.promo,
	accepted_regulations: state => state.regulations,
	getPromotions: state => state.promotions,
	isAuthenticated: state => !!state.token,
	authStatus: state => state.status,
	loginerror: state => state.login_error,
	confirmerror: state => state.confirm_error,
	loadingState: state => state.loadingState,
	getFrom: state => state.from,


}

const actions = {
	[AUTH_REQUEST]: ({
		commit,
		dispatch
	}, user) => {
		return new Promise((resolve, reject) => { // The Promise used for router redirect in login
			commit(AUTH_REQUEST)
			if (user.go) {
				const token = user.message.player_token_player
				const jwt = user.message.jwt
				localStorage.setItem('55a5s1dffe$fswe' + process.env.VUE_APP_ICODE, '$234' + token.split("").reverse().join("") + '2324234')
				localStorage.setItem('66a5s2dtte$fsw' + process.env.VUE_APP_ICODE, '$vr4' + moment().format('YYYY-MM-DD HH:mm:ss') + '3453455')
				localStorage.setItem('99a3s2dgfe$fsw' + process.env.VUE_APP_ICODE, '$34f' + moment().format('YYYY-MM-DD HH:mm:ss') + 'wer345f')
				localStorage.setItem('01a5s2dhge$fsw' + process.env.VUE_APP_ICODE, '$fwe' + moment().format('YYYY-MM-DD HH:mm:ss') + 'werweqe')
				localStorage.setItem('98a0s2ddee$fsw' + process.env.VUE_APP_ICODE, '$5fs' + token + moment().format('YYYY-MM-DD HH:mm:ss') + 'fertfdw')
				localStorage.setItem('75a9s2dsse$fsw' + process.env.VUE_APP_ICODE, '$4ff' + moment().format('YYYY-MM-DD HH:mm:ss') + 'werfsfd')
				localStorage.setItem(process.env.VUE_APP_WORMY + process.env.VUE_APP_ICODE, token)
				localStorage.setItem(process.env.VUE_APP_WORMY + process.env.VUE_APP_ICODE + '#$%', jwt)
				localStorage.setItem('96a3s2djje$fsw' + process.env.VUE_APP_ICODE, '$45f' + token + '435wefs')
				localStorage.setItem('81a4s2dyye$fsw' + process.env.VUE_APP_ICODE, '$4fe' + token + moment().format('YYYY-MM-DD HH:mm:ss') + 'sdtrrdf')
				localStorage.setItem('72a5s2diie$fsw' + process.env.VUE_APP_ICODE, '$5fs' + moment().format('YYYY-MM-DD HH:mm:ss') + 'dswe443')
				localStorage.setItem('97a6s2dkke$fsw' + process.env.VUE_APP_ICODE, '$ng4' + moment().format('YYYY-MM-DD HH:mm:ss') + 'cvrt433')
				localStorage.setItem('00a7s2dwde$fsw' + process.env.VUE_APP_ICODE, '$8hs' + moment().format('YYYY-MM-DD HH:mm:ss') + 'gfdgerg')
				sessionStorage.setItem('token', '$2th#eto#ket$h%a6tyo^ua@ret$ryi%ng^tof&5in$di^snota)9vaiable')
				localStorage.removeItem('55a5s1dfflo$fswe' + process.env.VUE_APP_ICODE)
				localStorage.removeItem('66a5s2dttlo$fsw' + process.env.VUE_APP_ICODE)
				localStorage.removeItem('99a3s2dgflo$fsw' + process.env.VUE_APP_ICODE)
				localStorage.removeItem('01a5s2dhglo$fsw' + process.env.VUE_APP_ICODE)
				localStorage.removeItem('98a0s2ddelo$fsw' + process.env.VUE_APP_ICODE)
				localStorage.removeItem('75a9s2dsslo$fsw' + process.env.VUE_APP_ICODE)
				localStorage.removeItem('96a3s2djjlo$fsw' + process.env.VUE_APP_ICODE)
				localStorage.removeItem('81a4s2dyylo$fsw' + process.env.VUE_APP_ICODE)
				localStorage.removeItem('72a5s2diilo$fsw' + process.env.VUE_APP_ICODE)
				localStorage.removeItem('97a6s2dkklo$fsw' + process.env.VUE_APP_ICODE)
				localStorage.removeItem('55a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE)
				localStorage.removeItem('00a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE)
				localStorage.removeItem('11a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE)
				localStorage.removeItem('44a7s2dwdlo$fsw' + process.env.VUE_APP_ICODE)
				localStorage.removeItem('77a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE)
				localStorage.removeItem('88a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE)
				localStorage.removeItem('99a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE)
				localStorage.removeItem('10a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE)
				commit(AUTH_SUCCESS, token)
				// you have your token, now log in your user :)
				dispatch(USER_REQUEST, token)
				resolve(user.message)

			} else {
				axios({
					url: process.env.VUE_APP_ROOT_API + 'api/login',
					data: user,
					method: 'POST',
					headers: {
						'Authorization': 'Bearer ' + process.env.VUE_APP_KITTY,
						'Content-type': 'application/json'
					}
				})
					.then(resp => {

						commit(DECRYPT, resp.data)
						resp.data = state.tempCrypt

						if (resp.data.message == 'player_not_confirmed') {
							commit(AUTH_NOT_CONFIRMED, resp.data.message)
							// commit(AUTH_ERROR, resp.data.message)
							return true
						}

						const token = resp.data.message.player_token_player
						const jwt = resp.data.message.jwt
						localStorage.setItem('55a5s1dffe$fswe' + process.env.VUE_APP_ICODE, '$234' + token.split("").reverse().join("") + '2324234')
						localStorage.setItem('66a5s2dtte$fsw' + process.env.VUE_APP_ICODE, '$vr4' + moment().format('YYYY-MM-DD HH:mm:ss') + '3453455')
						localStorage.setItem('99a3s2dgfe$fsw' + process.env.VUE_APP_ICODE, '$34f' + moment().format('YYYY-MM-DD HH:mm:ss') + 'wer345f')
						localStorage.setItem('01a5s2dhge$fsw' + process.env.VUE_APP_ICODE, '$fwe' + moment().format('YYYY-MM-DD HH:mm:ss') + 'werweqe')
						localStorage.setItem('98a0s2ddee$fsw' + process.env.VUE_APP_ICODE, '$5fs' + token + moment().format('YYYY-MM-DD HH:mm:ss') + 'fertfdw')
						localStorage.setItem('75a9s2dsse$fsw' + process.env.VUE_APP_ICODE, '$4ff' + moment().format('YYYY-MM-DD HH:mm:ss') + 'werfsfd')
						localStorage.setItem(process.env.VUE_APP_WORMY + process.env.VUE_APP_ICODE, token)
						localStorage.setItem(process.env.VUE_APP_WORMY + process.env.VUE_APP_ICODE + '#$%', jwt)
						localStorage.setItem('96a3s2djje$fsw' + process.env.VUE_APP_ICODE, '$45f' + token + '435wefs')
						localStorage.setItem('81a4s2dyye$fsw' + process.env.VUE_APP_ICODE, '$4fe' + token + moment().format('YYYY-MM-DD HH:mm:ss') + 'sdtrrdf')
						localStorage.setItem('72a5s2diie$fsw' + process.env.VUE_APP_ICODE, '$5fs' + moment().format('YYYY-MM-DD HH:mm:ss') + 'dswe443')
						localStorage.setItem('97a6s2dkke$fsw' + process.env.VUE_APP_ICODE, '$ng4' + moment().format('YYYY-MM-DD HH:mm:ss') + 'cvrt433')
						localStorage.setItem('00a7s2dwde$fsw' + process.env.VUE_APP_ICODE, '$8hs' + moment().format('YYYY-MM-DD HH:mm:ss') + 'gfdgerg')
						sessionStorage.setItem('token', '$2th#eto#ket$h%a6tyo^ua@ret$ryi%ng^tof&5in$di^snota)9vaiable')
						localStorage.removeItem('55a5s1dfflo$fswe' + process.env.VUE_APP_ICODE)
						localStorage.removeItem('66a5s2dttlo$fsw' + process.env.VUE_APP_ICODE)
						localStorage.removeItem('99a3s2dgflo$fsw' + process.env.VUE_APP_ICODE)
						localStorage.removeItem('01a5s2dhglo$fsw' + process.env.VUE_APP_ICODE)
						localStorage.removeItem('98a0s2ddelo$fsw' + process.env.VUE_APP_ICODE)
						localStorage.removeItem('75a9s2dsslo$fsw' + process.env.VUE_APP_ICODE)
						localStorage.removeItem('96a3s2djjlo$fsw' + process.env.VUE_APP_ICODE)
						localStorage.removeItem('81a4s2dyylo$fsw' + process.env.VUE_APP_ICODE)
						localStorage.removeItem('72a5s2diilo$fsw' + process.env.VUE_APP_ICODE)
						localStorage.removeItem('97a6s2dkklo$fsw' + process.env.VUE_APP_ICODE)
						localStorage.removeItem('55a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE)
						localStorage.removeItem('00a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE)
						localStorage.removeItem('11a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE)
						localStorage.removeItem('44a7s2dwdlo$fsw' + process.env.VUE_APP_ICODE)
						localStorage.removeItem('77a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE)
						localStorage.removeItem('88a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE)
						localStorage.removeItem('99a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE)
						localStorage.removeItem('10a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE)
						commit(AUTH_SUCCESS, token)
						// you have your token, now log in your user :)
						dispatch(USER_REQUEST, token)
						resolve(resp.data)
					})
					.catch(err => {

						commit(AUTH_ERROR, err)
						commit(DECRYPT, err.response.data)
						err.response.data = state.tempCrypt
						commit(AUTH_ERROR, err.response.data.message)
						localStorage.removeItem(process.env.VUE_APP_WORMY + process.env.VUE_APP_ICODE) // if the request fails, remove any possible user token if possible
						reject(err)
					})
			}

		})
	},
	[AUTH_SOCIAL]: ({
		commit,
		dispatch
	}, token) => {
		return new Promise((resolve, reject) => { // The Promise used for router redirect in login 
			commit(AUTH_SOCIAL)
			localStorage.setItem(process.env.VUE_APP_WORMY + process.env.VUE_APP_ICODE, token) // store the token in localstorage
			commit(AUTH_SUCCESS, token)
			// you have your token, now log in your user :)
			dispatch(USER_REQUEST, token)
			resolve(token)

		})
	},
	[AUTH_REGISTRATION]: ({
		commit,
		dispatch
	}, user) => {
		return new Promise((resolve, reject) => { // The Promise used for router redirect in login
			commit(AUTH_REGISTRATION)
			var data = user
			axios({
				url: process.env.VUE_APP_ROOT_API + 'api/addNewUser',
				data: {
					data
				},
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + process.env.VUE_APP_KITTY,
					'Content-type': 'application/json'
				}
			})
				.then(resp => {
					commit(DECRYPT, resp.data)
					resp.data = state.tempCrypt
					commit(REGISTRATION_SUCCESS, resp.data)
					resolve(resp.data)
				})
				.catch(err => {
					commit(DECRYPT, err.response.data)
					err.response.data = state.tempCrypt
					commit(REGISTRATION_ERROR)
					reject(err)
				})
		})
	},
	[AUTH_COMPLATE_REGISTRATION]: ({
		commit,
		dispatch
	}, user) => {
		return new Promise((resolve, reject) => { // The Promise used for router redirect in login
			commit(AUTH_COMPLATE_REGISTRATION)
			axios({
				url: process.env.VUE_APP_ROOT_API + 'api/editUser',
				data: user,
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + process.env.VUE_APP_KITTY,
					'Content-type': 'application/json'
				}
			})
				.then(resp => {
					commit(DECRYPT, resp.data)
					resp.data = state.tempCrypt
					commit(COMPLATE_REGISTRATION_SUCCESS, resp.data)
					dispatch(USER_UPDATE_DATA, resp.data)
					resolve(resp.data)
				})
				.catch(err => {
					commit(DECRYPT, err.response.data)
					err.response.data = state.tempCrypt
					commit(REGISTRATION_ERROR)
					reject(err)
				})
		})
	},
	[AUTH_LOGOUT]: ({
		commit,
		dispatch
	}) => {
		return new Promise((resolve, reject) => {
			commit(AUTH_LOGOUT)
			localStorage.removeItem(process.env.VUE_APP_WORMY + process.env.VUE_APP_ICODE)
			localStorage.removeItem(process.env.VUE_APP_WORMY + process.env.VUE_APP_ICODE + '#$%')
			localStorage.removeItem('55a5s1dffe$fswe' + process.env.VUE_APP_ICODE)
			localStorage.removeItem('66a5s2dtte$fsw' + process.env.VUE_APP_ICODE)
			localStorage.removeItem('99a3s2dgfe$fsw' + process.env.VUE_APP_ICODE)
			localStorage.removeItem('01a5s2dhge$fsw' + process.env.VUE_APP_ICODE)
			localStorage.removeItem('98a0s2ddee$fsw' + process.env.VUE_APP_ICODE)
			localStorage.removeItem('75a9s2dsse$fsw' + process.env.VUE_APP_ICODE)
			localStorage.removeItem('96a3s2djje$fsw' + process.env.VUE_APP_ICODE)
			localStorage.removeItem('81a4s2dyye$fsw' + process.env.VUE_APP_ICODE)
			localStorage.removeItem('72a5s2diie$fsw' + process.env.VUE_APP_ICODE)
			localStorage.removeItem('97a6s2dkke$fsw' + process.env.VUE_APP_ICODE)
			localStorage.removeItem('00a7s2dwde$fsw' + process.env.VUE_APP_ICODE)
			localStorage.removeItem('11a5s2dffe$fswe' + process.env.VUE_APP_ICODE)
			sessionStorage.removeItem('token')
			sessionStorage.setItem('token', '$2n@3ow5$thet^ok&en8^th86atyo7uar8etr&yi(ng)to)_findisava$#iabl3efo%%rc$$hac##kingme')
			var token2 = '$2y$10$weQPwu16AY/qh1UXJJxkQubT.s0dcVF9rHnwpwDH8URb2ZlaUruJ2';
			localStorage.setItem('55a5s1dfflo$fswe' + process.env.VUE_APP_ICODE, '$234' + token2.split("").reverse().join("") + '2324234')
			localStorage.setItem('66a5s2dttlo$fsw' + process.env.VUE_APP_ICODE, '$vr4' + moment().format('YYYY-MM-DD HH:mm:ss') + token2.substring(1, 8) + '3453455')
			localStorage.setItem('99a3s2dgflo$fsw' + process.env.VUE_APP_ICODE, '$34f' + token2.substring(9, 20) + moment().format('YYYY-MM-DD HH:mm:ss') + 'wer345f')
			localStorage.setItem('01a5s2dhglo$fsw' + process.env.VUE_APP_ICODE, '$fwe' + moment().format('YYYY-MM-DD HH:mm:ss') + token2.substring(1, 20) + 'werweqe')
			localStorage.setItem('98a0s2ddelo$fsw' + process.env.VUE_APP_ICODE, '$5fs' + token2.substring(3, 20) + moment().format('YYYY-MM-DD HH:mm:ss') + 'fertfdw')
			localStorage.setItem('75a9s2dsslo$fsw' + process.env.VUE_APP_ICODE, '$4ff' + moment().format('YYYY-MM-DD HH:mm:ss') + token2.substring(2, 37) + 'werfsfd')
			localStorage.setItem('22a5s2dfflo$fsw' + process.env.VUE_APP_ICODE, token2 + moment().format('YYYY-MM-DD HH:mm:ss'))
			localStorage.setItem('96a3s2djjlo$fsw' + process.env.VUE_APP_ICODE, '$45f' + token2.substring(2, 40) + '435wefs')
			localStorage.setItem('81a4s2dyylo$fsw' + process.env.VUE_APP_ICODE, '$4fe' + token2 + moment().format('YYYY-MM-DD HH:mm:ss') + token2.substring(1, 20) + 'sdtrrdf')
			localStorage.setItem('72a5s2diilo$fsw' + process.env.VUE_APP_ICODE, '$5fs' + moment().format('YYYY-MM-DD HH:mm:ss') + token2.substring(4, 28) + 'dswe443')
			localStorage.setItem('97a6s2dkklo$fswe568', '$ng4' + token2.substring(7, 27) + moment().format('YYYY-MM-DD HH:mm:ss') + 'cvrt433')
			localStorage.setItem('00a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE, '$1hs' + moment().format('YYYY-MM-DD HH:mm:ss') + token2.substring(7, 29) + 'gfdgerg')
			localStorage.setItem('11a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE, '$2hs' + moment().format('YYYY-MM-DD HH:mm:ss') + token2.substring(6, 30) + 'gfdgerg')
			localStorage.setItem('44a7s2dwdlo$fsw' + process.env.VUE_APP_ICODE, '$5hs' + moment().format('YYYY-MM-DD HH:mm:ss') + token2.substring(4, 30) + 'gfdgerg')
			localStorage.setItem('55a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE, '$6hs' + moment().format('YYYY-MM-DD HH:mm:ss') + token2.substring(5, 40) + 'gfdgerg')
			localStorage.setItem('77a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE, '$8hs' + moment().format('YYYY-MM-DD HH:mm:ss') + token2.substring(4, 30) + 'gfdgerg')
			localStorage.setItem('88a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE, '$9hs' + moment().format('YYYY-MM-DD HH:mm:ss') + token2.substring(3, 20) + 'gfdgerg')
			localStorage.setItem('99a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE, '$0hs' + moment().format('YYYY-MM-DD HH:mm:ss') + token2.substring(2, 40) + 'gfdgerg')
			localStorage.setItem('10a7s2dwdlo$fswe' + process.env.VUE_APP_ICODE, '$1hs' + moment().format('YYYY-MM-DD HH:mm:ss') + token2.substring(1, 30) + 'gfdgerg')
			resolve()
		})
	},
	[AUTH_OK]: ({
		commit,
		dispatch
	}) => {
		return new Promise((resolve, reject) => {
			resolve()
		})
	},


	[SET_PROMOTION]: ({
		commit,
		dispatch
	}, promo) => {
		return new Promise((resolve, reject) => {
			commit(SET_PROMOTION, promo)
			resolve(promo)
		})
	},
	[ACCEPT_REG]: ({
		commit,
		dispatch
	}, promo) => {
		return new Promise((resolve, reject) => {

			commit(ACCEPT_REG, promo)
			resolve(promo)
		})
	},

	[FROM_SET]: ({
		commit,
		dispatch
	}, value) => {
		return new Promise((resolve, reject) => {
			commit(FROM_SET, value)
		})
	},

}

const mutations = {
	[AUTH_REQUEST]: (state) => {
		state.status = 'loading'
		state.login_error = false
		state.confirm_error = false
		state.loadingState = true
	},

	[AUTH_SOCIAL]: (state) => {
		state.status = 'loading'
		state.login_error = false
		state.loadingState = true
	},
	[AUTH_SUCCESS]: (state, resp) => {
		state.status = 'success'
		state.token = resp
		state.hasLoadedOnce = true
		state.loadingState = false
	},
	[AUTH_ERROR]: (state, error) => {
		state.status = error
		state.login_error = true
		state.hasLoadedOnce = true
		state.loadingState = false
	},
	[AUTH_NOT_CONFIRMED]: (state, error) => {
		state.status = error
		state.confirm_error = true
		state.loadingState = false
	},
	[AUTH_LOGOUT]: (state) => {
		state.token = ''
		state.status = ''
	},
	[AUTH_REGISTRATION]: (state) => {
		state.status = 'loading'
		state.loadingState = true
	},
	[AUTH_COMPLATE_REGISTRATION]: (state) => {
		state.status = 'loading'
		state.loadingState = true
	},
	[REGISTRATION_SUCCESS]: (state) => {
		state.status = 'success'
		state.loadingState = false
	},
	[COMPLATE_REGISTRATION_SUCCESS]: (state) => {
		state.status = 'success'
		state.loadingState = false
	},
	[REGISTRATION_ERROR]: (state) => {
		state.status = 'error'
		state.loadingState = false
	},
	[SET_PROMOTION]: (state, value) => {
		state.promo = value
	},
	[ACCEPT_REG]: (state, value) => {
		state.regulations[value] = true
		localStorage.setItem('accept_reg', JSON.stringify(state.regulations)) // store the token in localstorage
	},
	[DECRYPT]: (state, resp) => {

		let ckey = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_DOGGY);
		var ciphertext = CryptoJS.enc.Hex.parse(resp);
		let srcs = CryptoJS.enc.Base64.stringify(ciphertext);
		let decrypt = CryptoJS.AES.decrypt(srcs, ckey, {
			mode: CryptoJS.mode.ECB,
			padding: CryptoJS.pad.Pkcs7
		});
		let decrypted = decrypt.toString(CryptoJS.enc.Utf8);
		state.tempCrypt = JSON.parse(decrypted)

	},
	[ENCRYPT]: (state, resp) => {

		let ckey = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_DOGGY);
		let encrypted = CryptoJS.AES.encrypt(JSON.stringify(resp), ckey, {
			mode: CryptoJS.mode.ECB,
			padding: CryptoJS.pad.Pkcs7
		});
		state.tempEncrypt = encrypted.ciphertext.toString();

	},

	[FROM_SET]: (state, value) => {
		state.from = value
	},



}

export default {
	state,
	getters,
	actions,
	mutations,
}