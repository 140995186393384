export const gtagMixin = {

	data() {
		return {}
	},

	methods: {

		gtagCheck(act, cli, cat, sta) {
			if (
				process.env.VUE_APP_GTAGAWSTATUS == 'ON' && process.env.VUE_APP_GTAGSTATUS == 'OFF'
			) {

				this.$track('Gtag', this.$gtag);
				this.$gtag.reportConversion({
					send_to: process.env.VUE_APP_SENDTO, // the custom tag to be reported
					action: act,
					status: sta,
					clients: cli,
				});
			} else if (
				process.env.VUE_APP_GTAGSTATUS == 'ON' && process.env.VUE_APP_GTAGAWSTATUS == 'OFF'
			) {

				this.$gtag.event('send', {
					event_category: cat,
					event_label: act,
					value: cli,
				});

				this.$track('Gtag', this.$gtag);
			}
		},
	},

}