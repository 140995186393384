<template>
  <div
    class="
      widthFull
      min-height-calc--footer
      customBg--repeat
      pass
      container-fluid
    "
  >
    <loading v-if="loadingState" />

    <div v-if="renderPage">
      <div class="row center mb-5">
        <div class="col-12">
          <h3 class="Bold py-2 color-2 pt-5">Richiesta premio</h3>

          <h3 class="Regular text-center">
            Compila il form per completare la richiesta del premio
          </h3>
        </div>
      </div>

      <form
        @submit.prevent="completed"
        class="widthFull mb-0"
        autocomplete="qwerqrt"
      >
        <div class="form-row justify-content-center">
          <div class="col-md-8">
            <div class="group mb-0">
              <h5 class="Regular color-2">
                Inserisci l'indirizzo per organizzare la cena con lo Chef a
                domicilio Chefbooking
              </h5>

              <hr class="line-pass line-pass--image" />
            </div>
          </div>
        </div>

        <div class="form-row justify-content-center">
          <div class="col-md-8 col-lg-4 mb-4 mb-md-3">
            <div class="group">
              <input
                type="text"
                class="form-control"
                v-model="name"
                id="name"
                name="name"
                data-vv-as="Nome"
                v-validate="{ required: true, max: 128, min: 2 }"
                :class="{
                  'is-invalid': errors.has('name'),
                  valid: !!name,
                }"
              />
              <label for="name" class="niceInputLabel">Nome </label>

              <div v-if="errors.has('name')" class="invalid-feedback">
                {{ errors.first("name") }}
              </div>
            </div>
          </div>

          <div class="col-md-8 col-lg-4 mb-4 mb-md-3">
            <div class="group">
              <input
                type="text"
                class="form-control"
                v-model="lastName"
                id="lastName"
                name="lastName"
                data-vv-as="Cognome"
                v-validate="{ required: true, max: 128, min: 2 }"
                :class="{
                  'is-invalid': errors.has('lastName'),
                  valid: !!lastName,
                }"
              />
              <label for="lastName" class="niceInputLabel">Cognome</label>

              <div v-if="errors.has('lastName')" class="invalid-feedback">
                {{ errors.first("lastName") }}
              </div>
            </div>
          </div>
        </div>

        <div class="form-row justify-content-center">
          <div class="col-md-8 col-lg-3 mb-4 mb-md-3">
            <div class="group">
              <input
                type="text"
                class="form-control"
                v-model="phone"
                id="phone"
                name="phone"
                data-vv-as="Telefono"
                v-validate="{
                  required: true,
                  max: 11,
                  min: 8,
                  regex: /^[3]+/,
                }"
                :class="{
                  'is-invalid': errors.has('phone'),
                  valid: !!phone,
                }"
              />
              <label for="phone" class="niceInputLabel">Telefono</label>

              <div
                v-if="existPhone"
                class="invalid-feedback"
                style="display: block"
              >
                <span>Numero di telefono già presente</span>
              </div>
              <div v-if="errors.has('phone')" class="invalid-feedback">
                {{ errors.first("phone") }}
              </div>
            </div>
          </div>
          <div class="col-md-8 col-lg-3 mb-4 mb-md-3">
            <div class="group">
              <input
                type="text"
                class="form-control"
                v-model="address"
                id="address"
                name="address"
                data-vv-as="Indirizzo"
                v-validate="{ required: true, max: 128 }"
                :class="{
                  'is-invalid': errors.has('address'),
                  valid: !!address,
                }"
              />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label class="niceInputLabel" for="address">Indirizzo</label>
              <div v-if="errors.has('address')" class="invalid-feedback">
                {{ errors.first("address") }}
              </div>
            </div>
          </div>
          <div class="col-md-8 col-lg-2 mb-4 mb-md-3">
            <div class="group">
              <input
                type="text"
                class="form-control"
                v-model="civic"
                id="civic"
                name="civic"
                data-vv-as="Civico"
                v-validate="{ required: true, max: 7 }"
                :class="{ 'is-invalid': errors.has('civic'), valid: !!civic }"
              />
              <span class="highlight"></span>
              <span class="bar"></span>
              <label class="niceInputLabel" for="civic">Civico</label>
              <div v-if="errors.has('civic')" class="invalid-feedback">
                {{ errors.first("civic") }}
              </div>
            </div>
          </div>
        </div>

        <div class="form-row justify-content-center">
          <div class="col-md-8 col-lg-3 mb-3 mb-4 mb-md-3">
            <div class="group">
              <select
                v-model="province"
                id="province"
                name="province"
                class="form-control"
                data-vv-as="Provincia"
                v-validate="{ required: true }"
                :class="{
                  'is-invalid': errors.has('province'),
                  valid: !!province,
                }"
              >
                <option disabled value="" selected="selected"></option>
                <option
                  v-for="province in provinces"
                  :key="province.sigla"
                  v-bind:value="province.sigla"
                >
                  {{ province.sigla }} - {{ province.provincia }}
                </option>
              </select>

              <label class="niceInputLabel" for="province">Provincia</label>
              <div v-if="provincesState">
                <font-awesome-icon
                  class="loadSpinner"
                  id="loadProvince"
                  icon="spinner"
                  size="1x"
                  pulse
                />
              </div>
              <div v-if="errors.has('province')" class="invalid-feedback">
                {{ errors.first("province") }}
              </div>
            </div>
          </div>
          <div class="col-md-8 col-lg-3 mb-3 mb-4 mb-md-3">
            <div class="group">
              <select
                class="form-control"
                v-model="city"
                v-on:click="checkProvince"
                id="city"
                name="city"
                data-vv-as="Località"
                v-validate="{ required: true }"
                :disabled="citiesState"
                :class="{
                  'is-invalid': errors.has('city'),
                  valid: !!city,
                }"
              >
                <option disabled value="" selected="selected"></option>
                <option
                  v-for="city in cities"
                  :key="city.comune"
                  v-bind:value="{ cityistat: city.istat, city: city.comune }"
                >
                  {{ city.comune }}
                </option>
              </select>

              <label class="niceInputLabel" for="city"
                >Località
                <span v-if="cityError" class="formError"
                  >Prima scegli Provincia</span
                ></label
              >
              <div v-if="citiesState">
                <font-awesome-icon
                  class="loadSpinner"
                  id="loadCity"
                  icon="spinner"
                  size="1x"
                  pulse
                />
              </div>
              <div v-if="errors.has('city')" class="invalid-feedback">
                {{ errors.first("city") }}
              </div>
            </div>
          </div>
          <div class="col-md-8 col-lg-2 mb-3 mb-4 mb-md-3">
            <div class="group">
              <select
                class="form-control"
                v-model="zip"
                v-on:click="checkZip"
                id="zip"
                name="zip"
                data-vv-as="Cap"
                v-validate="{ required: true }"
                :disabled="zipsState"
                :class="{
                  'is-invalid': errors.has('zip'),
                  valid: !!zip,
                }"
              >
                <option value="" disabled :selected="true"></option>
                <option
                  v-for="zip in zips"
                  :key="zip.cap"
                  v-bind:value="zip.cap"
                >
                  {{ zip.cap }}
                </option>
              </select>

              <label class="niceInputLabel" for="zip"
                >Cap
                <span v-if="zipError" class="formError"
                  >Prima scegli Comune</span
                >
              </label>
              <div v-if="zipsState">
                <font-awesome-icon
                  class="loadSpinner"
                  id="loadZips"
                  icon="spinner"
                  size="1x"
                  pulse
                />
              </div>
              <div v-if="errors.has('zip')" class="invalid-feedback">
                {{ errors.first("zip") }}
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-8 offset-md-2">
            <div class="group">
              <input
                type="text"
                class="form-control"
                v-model="note"
                id="note"
                name="note"
                data-vv-as="note"
                v-validate="{
                  required: true,
                  max: 124,
                }"
                :class="{
                  'is-invalid': errors.has('note'),
                  valid: !!note,
                }"
              />
              <label for="note" class="niceInputLabel">Nome sul citofono</label>

              <div v-if="errors.has('note')" class="invalid-feedback">
                {{ errors.first("note") }}
              </div>
            </div>
          </div>
        </div>

        <div class="form-row justify-content-center mt-5">
          <div class="col-md-8">
            <div class="group mb-0">
              <h5 class="Regular color-2">
                Scegli 2 opzioni di data per organizzare la cena (è possibile
                scegliere la data a partire dal 150 giorno da oggi)
              </h5>

              <hr class="line-pass" />
            </div>
          </div>
        </div>

        <div class="form-row justify-content-center">
          <div class="col-md-8 col-lg-4 mb-4 mb-md-3">
            <div class="group">
              <datepicker
                :language="it"
                v-model="date1"
                :full-month-name="true"
                :initialView="'month'"
                :format="'dd MMMM yyyy'"
                :monday-first="true"
                :disabledDates="disabledDates"
                id="date1"
                name="date1"
                input-class="form-control "
              ></datepicker>

              <input
                type="text"
                v-show="false"
                v-model="date1"
                class="form-control datepicker"
                id="date1"
                name="date1"
                data-vv-as="Data 1"
                v-validate="{ required: true }"
                :class="{
                  'is-invalid': errors.has('date1'),
                  valid: !!date1,
                }"
              />

              <label for="date1" class="niceInputLabel">Data 1</label>
              <span class="highlight"></span>
              <span class="bar"></span>

              <div v-if="errors.has('date1')" class="invalid-feedback">
                {{ errors.first("date1") }}
              </div>

              <div v-if="dataError" class="red">
                Le due date selezionate non devono coincidere
              </div>
            </div>
          </div>
          <div class="col-md-8 col-lg-4 mb-4 mb-md-3">
            <div class="group">
              <datepicker
                :language="it"
                v-model="date2"
                :full-month-name="true"
                :initialView="'month'"
                :format="'dd MMMM yyyy'"
                :monday-first="true"
                :disabledDates="disabledDates"
                id="date2"
                name="date2"
                input-class="form-control "
              ></datepicker>

              <input
                type="text"
                v-show="false"
                v-model="date2"
                class="form-control datepicker"
                id="date2"
                name="date2"
                data-vv-as="Data 2"
                v-validate="{ required: true }"
                :class="{
                  'is-invalid': errors.has('date2'),
                  valid: !!date2,
                }"
              />

              <label for="date2" class="niceInputLabel">Data 2</label>
              <span class="highlight"></span>
              <span class="bar"></span>

              <div v-if="errors.has('date2')" class="invalid-feedback">
                {{ errors.first("date2") }}
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-8 offset-md-2">
            <div class="group">
              <input
                type="text"
                class="form-control"
                v-model="restrictions"
                id="restrictions"
                name="restrictions"
                data-vv-as="Restrizioni"
                v-validate="{
                  required: false,
                  max: 124,
                }"
                :class="{
                  'is-invalid': errors.has('restrictions'),
                  valid: !!restrictions,
                }"
              />
              <label for="restrictions" class="niceInputLabel"
                >Restrizioni alimentari</label
              >

              <div v-if="errors.has('restrictions')" class="invalid-feedback">
                {{ errors.first("restrictions") }}
              </div>
            </div>
          </div>
        </div>

        <div class="form-row justify-content-center mt-5">
          <div class="col-md-4">
            <div class="group mb-0">
              <h5 class="Regular color-2">Disponi del forno?</h5>

              <hr class="line-pass" />
              <div class="form-check my-3 my-md-0">
                <div class="d-flex align-items-center">
                  <div class="mr-5">
                    <input
                      v-model="hoven"
                      class="mt-3 mr-3"
                      type="radio"
                      name="hoven"
                      id="pr1"
                      value="1"
                      v-validate="'required|included:0,1'"
                      :class="{
                        'is-invalid': errors.has('hoven'),
                      }"
                      data-vv-as="Forno"
                    />

                    <label for="pr1" class="">
                      <div class="">
                        <span class="uppercase">Sì</span>
                      </div>
                    </label>

                    <div v-if="errors.has('hoven')" class="invalid-feedback">
                      {{ errors.first("hoven") }}
                    </div>
                  </div>

                  <div class="ml-3">
                    <input
                      v-model="hoven"
                      class="mt-3 mr-3"
                      type="radio"
                      name="hoven"
                      id="pr2"
                      value="0"
                      v-validate="'required|included:0,1'"
                    />

                    <label for="pr2" class="">
                      <div class="">
                        <span class="uppercase">No</span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="group mb-0">
              <h5 class="Regular color-2">Tipologia del piano cottura</h5>

              <hr class="line-pass" />
              <div class="form-check my-3 my-md-0">
                <div class="d-flex align-items-center">
                  <div class="mr-5">
                    <input
                      v-model="hob"
                      class="mt-3 mr-3"
                      type="radio"
                      name="hob"
                      id="pr3"
                      value="1"
                      v-validate="'required|included:0,1'"
                      :class="{
                        'is-invalid': errors.has('hob'),
                      }"
                      data-vv-as="Piano cottura"
                    />

                    <label for="pr3" class="">
                      <div class="">
                        <span class="uppercase">Induzione</span>
                      </div>
                    </label>
                    <div v-if="errors.has('hob')" class="invalid-feedback">
                      {{ errors.first("hob") }}
                    </div>
                  </div>

                  <div class="ml-3">
                    <input
                      v-model="hob"
                      class="mt-3 mr-3"
                      type="radio"
                      name="hob"
                      id="pr4"
                      value="0"
                      v-validate="'required|included:0,1'"
                    />

                    <label for="pr4" class="">
                      <div class="">
                        <span class="uppercase">Gas</span>
                      </div>
                    </label>
                  </div>
                </div>
                <label class="red" v-show="errors.has('agreement1_player')">
                  Consenso per finalità di marketing va accettato oppure
                  rifiutato
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mt-2">
          <div class="col-12 col-md-8">
            <div class="group">
              <h5 class="Regular color-2">
                <a :href="win_menu_link" target="_blank" class="color-2"
                  >Consulta il menu</a
                >
              </h5>
            </div>
          </div>
        </div>

        <template v-if="selectMenu">
          <div class="form-row justify-content-center mt-5">
            <div class="col-md-8">
              <div class="group mb-0">
                <h5 class="Regular color-2">
                  Componi il tuo menù, seleziona i tuoi piatti
                </h5>
                <hr class="line-pass" />
              </div>
            </div>
          </div>

          <div class="form-row justify-content-center">
            <div class="col-md-8 col-lg-4 mb-3 mb-4 mb-md-3">
              <div class="group">
                <select
                  v-model="antipasti1"
                  id="antipasti1"
                  name="antipasti1"
                  class="form-control"
                  data-vv-as="Antipasti 1"
                  v-validate="{ required: true }"
                  :class="{
                    'is-invalid': errors.has('antipasti1'),
                    valid: !!antipasti1,
                  }"
                >
                  <option
                    v-for="item in MENU.antipasti"
                    :key="item.id"
                    v-bind:value="item.text"
                  >
                    {{ item.text.toUpperCase() }}
                  </option>
                </select>

                <label class="niceInputLabel" for="antipasti1"
                  >Antipasti 1</label
                >

                <div v-if="errors.has('antipasti1')" class="invalid-feedback">
                  {{ errors.first("antipasti1") }}
                </div>
              </div>
            </div>
            <div class="col-md-8 col-lg-4 mb-3 mb-4 mb-md-3">
              <div class="group">
                <select
                  v-model="antipasti2"
                  id="antipasti2"
                  name="antipasti2"
                  class="form-control"
                  data-vv-as="Antipasti 2"
                  v-validate="{ required: true }"
                  :class="{
                    'is-invalid': errors.has('antipasti2'),
                    valid: !!antipasti2,
                  }"
                >
                  <option disabled value="" selected="selected"></option>
                  <option
                    v-for="item in MENU.antipasti"
                    :key="item.id"
                    v-bind:value="item.text"
                  >
                    {{ item.text.toUpperCase() }}
                  </option>
                </select>

                <label class="niceInputLabel" for="antipasti2"
                  >Antipasti 2</label
                >

                <div v-if="errors.has('antipasti2')" class="invalid-feedback">
                  {{ errors.first("antipasti2") }}
                </div>
              </div>
            </div>
          </div>

          <div class="form-row justify-content-center">
            <div class="col-md-8 col-lg-4 mb-3 mb-4 mb-md-3">
              <div class="group">
                <select
                  v-model="antipasti3"
                  id="antipasti3"
                  name="antipasti3"
                  class="form-control"
                  data-vv-as="Antipasti 3"
                  v-validate="{ required: true }"
                  :class="{
                    'is-invalid': errors.has('antipasti3'),
                    valid: !!antipasti3,
                  }"
                >
                  <option
                    v-for="item in MENU.antipasti"
                    :key="item.id"
                    v-bind:value="item.text"
                  >
                    {{ item.text.toUpperCase() }}
                  </option>
                </select>

                <label class="niceInputLabel" for="antipasti3"
                  >Antipasti 3</label
                >

                <div v-if="errors.has('antipasti3')" class="invalid-feedback">
                  {{ errors.first("antipasti3") }}
                </div>
              </div>
            </div>

            <div class="col-md-8 col-lg-4 mb-3 mb-4 mb-md-3">
              <div class="group">
                <select
                  v-model="antipasti4"
                  id="antipasti4"
                  name="antipasti4"
                  class="form-control"
                  data-vv-as="Antipasti 4"
                  v-validate="{ required: true }"
                  :class="{
                    'is-invalid': errors.has('antipasti4'),
                    valid: !!antipasti4,
                  }"
                >
                  <option disabled value="" selected="selected"></option>
                  <option
                    v-for="item in MENU.antipasti"
                    :key="item.id"
                    v-bind:value="item.text"
                  >
                    {{ item.text.toUpperCase() }}
                  </option>
                </select>

                <label class="niceInputLabel" for="antipasti4"
                  >Antipasti 4</label
                >

                <div v-if="errors.has('antipasti4')" class="invalid-feedback">
                  {{ errors.first("antipasti4") }}
                </div>
              </div>
            </div>
          </div>

          <div class="form-row justify-content-center">
            <div class="col-md-8 col-lg-4 mb-3 mb-4 mb-md-3">
              <div class="group">
                <select
                  v-model="risotti"
                  id="risotti"
                  name="risotti"
                  class="form-control"
                  data-vv-as="Risotti"
                  v-validate="{ required: true }"
                  :class="{
                    'is-invalid': errors.has('risotti'),
                    valid: !!risotti,
                  }"
                >
                  <option
                    v-for="item in MENU.risotti"
                    :key="item.id"
                    v-bind:value="item.text"
                  >
                    {{ item.text.toUpperCase() }}
                  </option>
                </select>

                <label class="niceInputLabel" for="risotti">Risotti</label>

                <div v-if="errors.has('risotti')" class="invalid-feedback">
                  {{ errors.first("risotti") }}
                </div>
              </div>
            </div>

            <div class="col-md-8 col-lg-4 mb-3 mb-4 mb-md-3">
              <div class="group">
                <select
                  v-model="dolci"
                  id="dolci"
                  name="dolci"
                  class="form-control"
                  data-vv-as="Dolci"
                  v-validate="{ required: true }"
                  :class="{
                    'is-invalid': errors.has('dolci'),
                    valid: !!dolci,
                  }"
                >
                  <option
                    v-for="item in MENU.dolci"
                    :key="item.id"
                    v-bind:value="item.text"
                  >
                    {{ item.text.toUpperCase() }}
                  </option>
                </select>

                <label class="niceInputLabel" for="dolci">Dolci</label>

                <div v-if="errors.has('dolci')" class="invalid-feedback">
                  {{ errors.first("dolci") }}
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="row justify-content-center">
          <div class="col-12 center py-5">
            <button class="btn btn-primary-inv uppercase" type="submit">
              Richiedi il premio
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import { ACCEPT_COOKIES, USER_REQUEST } from "actions/user";
import { ACCEPT_REG } from "actions/auth";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import { it } from "vuejs-datepicker/dist/locale";
import { checkUniqueMixin } from "../../mixins/checkUniqueMixin";
import { locationMixin } from "../../mixins/locationMixin";
import { playFunctionMixin } from "../../mixins/playFunctionMixin";
import { toDateMixin } from "../../mixins/toDateMixin";
import { popupConfigurationMixin } from "../../mixins/popupConfigurationMixin";
import { axiosMixin } from "../../mixins/axiosMixin";

const moment = require("moment");

export default {
  name: "ManageData",
  mixins: [
    checkUniqueMixin,
    locationMixin,
    playFunctionMixin,
    toDateMixin,
    popupConfigurationMixin,
    axiosMixin,
  ],
  components: {
    Datepicker,
    VueTimepicker,
  },

  data() {
    return {
      selectMenu: true,
      win_menu_link:
        "https://storage.googleapis.com/21249moretti/menu_vincitore.pdf",
      loadingState: false,
      dataError: false,
      player_token_player: "",
      practice_code_play: "",
      winPlay: {},
      it: it,
      disabledDates: {
        to: "",
        from: "",
      },
      cities: [],
      citiesState: false,
      zips: [],
      zipsState: false,

      name: "",
      lastName: "",
      phone: "",
      address: "",
      civic: "",
      province: "",
      city: "",
      zip: "",
      note: "",
      restrictions: "",
      date1: "",
      date2: "",
      hoven: "",
      hob: "",

      sendData: {},
      renderPage: false,

      antipasti1: "",
      antipasti2: "",
      antipasti3: "",
      antipasti4: "",
      risotti: "",
      dolci: "",

      MENU: {
        antipasti: [
          {
            id: 1,
            text: "Sfere di Polletto alla birra",
          },
          {
            id: 2,
            text: "Focaccia Gourmet salumi e formaggi",
          },
          {
            id: 3,
            text: "CRUDI D’ITALIA",
          },
          {
            id: 4,
            text: "INVOLTINI DI MANZO PROFUMATI",
          },
          {
            id: 5,
            text: "Gamberi alla birra con maionese artigianale",
          },
          {
            id: 6,
            text: "SPIEDINO DI SALMONE AL BASILICO",
          },
          {
            id: 7,
            text: "FOCACCIA GOURMET acciughe e pomodoro",
          },
          {
            id: 8,
            text: "Focaccia Gourmet mozzarella e verdure",
          },
          {
            id: 9,
            text: "SFERE DI MARE DORATE",
          },
          {
            id: 10,
            text: "INSALATA RUSSA SCOMPOSTA",
          },
          {
            id: 11,
            text: "Sfere di verdure con salsa artigianale",
          },
          {
            id: 12,
            text: "PATATE DORATE AL FORNO",
          },
        ],
        risotti: [
          {
            id: 13,
            text: "Guanciale alle erbe aromatiche",
          },
          {
            id: 14,
            text: "Cubotti di salmone e agrumi",
          },
          {
            id: 15,
            text: "Pesto di zucchine e menta",
          },
        ],
        dolci: [
          {
            id: 16,
            text: "Tiramisù alla birra, classico",
          },
          {
            id: 17,
            text: "Tiramisù alla birra, pistacchio",
          },
          {
            id: 18,
            text: "Tiramisù alla birra, lamponi",
          },
        ],
      },
    };
  },
  methods: {
    completed() {
      let _vue = this;
      this.$validator.validate().then((valid) => {
        if (
          moment(_vue.date1).format("DD-MM-YYYY") !=
          moment(_vue.date2).format("DD-MM-YYYY")
        ) {
          _vue.dataError = false;
          _vue.sendData = JSON.stringify({
            name: _vue.name,
            lastName: _vue.lastName,
            phone: _vue.phone,
            address: _vue.address,
            civic: _vue.civic,
            province: _vue.province,
            city: _vue.city,
            zip: _vue.zip,
            note: _vue.note,
            restrictions: _vue.restrictions,
            date1: moment(_vue.date1).format("DD-MM-YYYY"),
            date2: moment(_vue.date2).format("DD-MM-YYYY"),
            hoven: _vue.hoven,
            hob: _vue.hob,
            antipasti1: _vue.antipasti1.toUpperCase() || "",
            antipasti2: _vue.antipasti2.toUpperCase() || "",
            antipasti3: _vue.antipasti3.toUpperCase() || "",
            antipasti4: _vue.antipasti4.toUpperCase() || "",
            risotti: _vue.risotti.toUpperCase() || "",
            dolci: _vue.dolci.toUpperCase() || "",
          });

          if (valid) {
            _vue.updateShipData(_vue.sendData);
          }
        } else {
          this.dataError = true;
        }
      });
    },

    async getAllData(token) {
      await this.retrivePlays(token);

      this.retrieveData = this.retrieveData.data;

      this.$track("retrieveData", this.retrieveData);

      if (this.retrieveData["message"].length > 0) {
        this.winPlay = this.retrieveData["message"].filter(
          (el) =>
            el.practice_code_play == this.practice_code_play &&
            el.validation_manage_play == 1 &&
            el.field6_manage_play == null
        );

        if (
          this.winPlay.length > 0 &&
          this.winPlay[0].player_token_player == this.player_token_player
        ) {
          this.name = this.winPlay[0].first_name_player;
          this.lastName = this.winPlay[0].last_name_player;

          this.renderPage = true;
        } else {
          this.popupErrorText(
            "info",
            "",
            "Sembra che tu abbia già salvato i dati",
            "5000",
            "home"
          );
        }
      } else {
        this.popupErrorText(
          "info",
          "",
          "Sembra che tu abbia già salvato i dati",
          "5000",
          "home"
        );
      }
    },
  },

  watch: {},
  mounted: function () {
    this.populateProvinces();
    let param = this.$route.params.token;

    this.$track("param", param);

    // OBJECT TO PASS:
    // {"practice_code_play": "1249121468", "player_token_player": "c68ccb674b5b582a2d62abbaeb10962a"}

    try {
      let deCryptToken = this.d(param);

      this.player_token_player = deCryptToken.player_token_player;
      this.practice_code_play = deCryptToken.practice_code_play;

      this.$track("player_token_player", this.player_token_player);
      this.$track("practice_code_play", this.practice_code_play);

      this.loadingState = true;

      let _vue = this;

      _vue.getAllData(this.player_token_player);
      // setTimeout(async function () {
      // }, 3000);
    } catch (error) {
      if (param != "menu_vincitore.pdf") {
        this.popupErrorText(
          "error",
          "Ops...",
          "Sembra che il link della pagina non sia corretto",
          "5000",
          "home"
        );
      }
    }

    let _vue = this;
    setTimeout(function () {
      _vue.disabledDates = {
        to: _vue.calcToDateAward(),
        from: _vue.calcFromDateAward(),
      };

      _vue.$track("disabledDates", _vue.disabledDates);
    }, 1500);
  },
  created: async function () {},
  computed: {
    ...mapGetters([
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "acceptCookies",
      "accepted_regulations",
    ]),
    ...mapState({
      authLoading: (state) => state.auth.status === "loading",
      profile: (state) => state.user.profile,
    }),
  },
};
</script>

