
<template>
  <div class="bg_load">
    <img class="loadScreen imgLoad" src="../../assets/images/load.gif" />
  </div>
</template>

<style scoped>
/*  LOADSCREEN  */

.bg_load {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: #00000082;
  z-index: 100;
}

.imgLoad {
  position: absolute;
  top: 48.5%;
  margin-left: 48.5vw;
  width: 3vw;
  background: transparent;
}

@media (min-width: 767px) and (max-width: 1140px) {
  .imgLoad {
    position: absolute;
    top: 47.5%;
    margin-left: 47.5vw;
    width: 5vw;
  }
}

@media (min-width: 20px) and (max-width: 767px) {
  .imgLoad {
    position: absolute;
    top: 45%;
    margin-left: 45vw;
    width: 10vw;
  }
}
</style>

<script>
export default {
  name: "loading",
  props: {},
  components: {},
  data() {
    return {};
  },
};
</script>
