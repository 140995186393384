<template>
	<div class="widthFull">
		<nav
			class="navbar navbar-light navbar-expand-lg fixed-top"
			style="z-index: 15"
		>

			<div class="container-fluid widthFull">

				<div
					class="navbar-header"
					v-if="vw < 998"
				>
					<router-link
						to="/"
						v-scroll-to="{ el: '.main', offset: -2000 }"
						class="navbar-brand router-link-exact-active router-link-active"
					>
					</router-link>

				</div>

				<button
					v-if="!(this.moment(start) > timeStore)"
					class="navbar-toggler navbar-toggler-right ml-auto mt-0"
					type="button"
					data-toggle="collapse"
					data-target="#navbarCollapse"
				>
					<span class="navbar-toggler-icon"></span>
				</button v-else>

				<div
					class="collapse navbar-collapse flex-column ml-0"
					id="navbarCollapse"
					v-if="!(this.moment(start) > timeStore)"
				>

					<ul
						class="navbar-nav align-items-center"
						:class="{'row' : vw > 992}"
					>

						<div
							class="navbar-header mr-auto"
							:class="{'col-2' : vw > 992}"
							v-if="vw > 998"
						>
							<router-link
								to="/"
								v-scroll-to="{ el: '.main', offset: -2000 }"
								class="navbar-brand router-link-exact-active router-link-active d-flex align-items-center"
							>

							</router-link>

						</div>
<li class="nav-item">

							<router-link
								v-if="$route.path != '/'"
								class="nav-link"
								to="/"
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
							><span class="menuItem ">Home </span></router-link>
						</li>
						<li class="nav-item">

							<router-link
								v-if="isProfileLoaded"
								class="nav-link"
								to="/play"
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
							><span class="menuItem ">Partecipa </span></router-link>
							<router-link
								v-else
								class="nav-link"
								to="/login"
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
							><span class="menuItem ">Partecipa</span></router-link>
						</li>

						<!--<li class="nav-item">

							<router-link
								class="nav-link"
								to="/"
								v-scroll-to="{ el: '#section01', offset: this.vw > 768 ? -this.navHeight : -this.navHeightSmall}"
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
							><span class="menuItem ">Come partecipare</span></router-link>

						</li>

						<li class="nav-item">

							<router-link
								class="nav-link"
								to="/where-to-buy"
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
							><span class="menuItem ">Dove acquistare Crodino</span></router-link>

						</li>-->

						<li class="nav-item">

							<router-link
								class="nav-link"
								to="/"
								v-scroll-to="{ el: '#section02', offset: this.vw > 768 ? -this.navHeight : -this.navHeightSmall}"
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
							><span class="menuItem ">I premi</span></router-link>

						</li>

						<li class="nav-item">

							<a
								href="regolamento.pdf"
								target="_blank"
								class="nav-link"
							><span class="menuItem ">Regolamento</span></a>

						</li>

					<!--	<li>
							<router-link
								class="nav-link"
								:to="{ path: '/faq' }"
								v-scroll-to="{ el: '.main', offset: -150 }"
								data-toggle="collapse"
								data-target=".navbar-collapse.show"
							>
								<span class="menuItem">Faq</span>
							</router-link>
						</li>-->

						<li
							class="nav-item"
							v-if="!isProfileLoaded"
						>

							<router-link
								v-if="$route.path != '/login'"
								class="nav-link"
								to="/login"
							>
								<span class="menuItem ipadMenu">Accedi</span>
							</router-link>

							<router-link
								v-else
								class="nav-link"
								to="/registration"
								v-scroll-to="{ el: '.main', offset: -2000 }"
							>
								<span class="menuItem ipadMenu">Registrati</span>
							</router-link>

						</li>

						<li
							v-if="isProfileLoaded && hasLogin"
							class="nav-item dropdown mr-0 pd-0"
						>
							<a
								class="nav-link dropdown-toggle menuItem mr-0 pd-0"
								href="#"
								id="navbardrop"
								data-toggle="dropdown"
							>
								<font-awesome-icon
									class="pointer mr-0 pd-0"
									icon="user"
									size="1x"
								/>
								Ciao {{ first_name_player }}
							</a>
							<div
								class="dropdown-menu bgDrop"
								data-toggle="collapse "
								data-target=".navbar-collapse.show"
							>
								<router-link
									class="dropdown-item menuItem"
									to="/account"
									v-scroll-to="'.main'"
									data-toggle="collapse"
									data-target=".navbar-collapse.show"
								><span class="menuItem ipadMenu">Il Mio Profilo</span></router-link>
								<router-link
									class="dropdown-item menuItem"
									to="/receipts"
									data-toggle="collapse"
									data-target=".navbar-collapse.show"
								><span class="menuItem ipadMenu">Le mie giocate</span></router-link>
								<a
									class="dropdown-item pointer menuItem black"
									@click="logout"
								><span class="menuItem ipadMenu">Esci</span></a>
							</div>
						</li>
					</ul>

				</div>

				</ul>
			</div>

		</nav>

		<div
			class="container-fluid fixed back-1 p-4 "
			v-if="!acceptCookies && !cookieBot"
		>

			<div class="row justify-content-center">

				<div class="col-12 col-md-5 offset-md-2 color-3">

					<span class="Medium color-3">

						Utilizziamo i cookie al fine di personalizzare e migliorare la tua
						esperienza sul nostro sito.
						<br />Visita la nostra
						<a
							style="color: white !important; text-decoration: underline"
							target="_blank"
							:href="privacyPolicy"
						>informativa sulla privacy</a>
						per acquisire maggiori informazioni. <br />Utilizzando il nostro
						sito accetti l'utilizzo dei cookie da parte nostra.
					</span>
				</div>

				<div class="col-md-4 text-center  ">
					<button
						class="btn btn-primary  pr-3 pl-3"
						v-on:click="doAcceptCookies"
					>
						OK
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
 
<script>
import { mapGetters, mapState } from "vuex";
import { AUTH_LOGOUT, SET_PROMOTION, GET_PROMOTIONS } from "actions/auth";
import { ACCEPT_COOKIES } from "actions/user";

import { innerWidthMixin } from "../../mixins/innerWidthMixin";
import { sharedEnvMixin } from "../../mixins/sharedEnvMixin";
import { checkSoftwareVersion } from "../../mixins/checkSoftwareVersion";
import { popupConfigurationMixin } from "../../mixins/popupConfigurationMixin";

const moment = require("moment");
export default {
  name: "navigation",
  mixins: [
    innerWidthMixin,
    sharedEnvMixin,
    checkSoftwareVersion,
    popupConfigurationMixin,
  ],
  data() {
    return {
      currentPage: "",
      visible: true,
      opacity: { opacity: 1 },

      moment: moment,
    };
  },
  methods: {
    visibilityChanged(isVisible, entry) {},
    logout: function () {
      this.$store
        .dispatch(AUTH_LOGOUT)
        .then(() => (this.$route.path != "/" ? this.$router.push("/") : ""));
    },
    scrollToHowParticipate: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo("#how-participate", 700, { offset: -75 });
      }, 100);
    },
    scrollToAward: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo("#award", 700, { offset: -75 });
      }, 100);
    },
    scrollToTop: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo(".main", 700, { offset: -125 });
      }, 100);
    },
    doAcceptCookies: function () {
      this.$store.dispatch(ACCEPT_COOKIES).then((resp) => {
        //this.$router.push('/')
      });
    },
  },
  watch: {
    $route(to, from) {
      this.checkSoftwareVersion();
    },

    "$route.path": function (route) {
      if (route != "/") {
        this.visible = false;
        this.opacity = {
          opacity: 0,
          "-ms-filter": "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)",
          filter: "alpha(opacity=0)",
        };
      } else {
        this.opacity = {
          opacity: 1,
          "-ms-filter": "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)",
          filter: "alpha(opacity=100)",
        };
        this.visible = true;
      }
    },
    acceptCookies: function () {},
  },

  computed: {
    timeStore() {
      return this.$timestore.now;
    },
    ...mapGetters([
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "acceptCookies",
      "promoOpen",
      "getPromotions",
    ]),
    ...mapState({
      authLoading: (state) => state.auth.status === "loading",
      first_name_player: (state) => `${state.user.profile.first_name_player}`,
    }),
  },

  updated() {
    this.currentPage = this.$route.name;
  },
  mounted: function () {
    this.currentPage = this.$route.name;
    this.$track("isAuthenticated", this.isAuthenticated);
    var _vue = this;
    if (this.cookieBot) {
      let cookieBotScript = document.createElement("script");
      cookieBotScript.setAttribute(
        "src",
        "https://consent.cookiebot.com/uc.js"
      );
      cookieBotScript.setAttribute("data-cbid", process.env.SERVICE);
      cookieBotScript.setAttribute("data-blockingmode", "auto");
      cookieBotScript.async = true;
      cookieBotScript.setAttribute("type", "text/javascript");
      document.head.appendChild(cookieBotScript);
    }
    setTimeout(function () {
      if (_vue.$route.path != "/") {
        _vue.visible = false;
        _vue.opacity = {
          opacity: 0,
          "-ms-filter": "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)",
          filter: "alpha(opacity=0)",
        };
      }
    }, 300);
  },
};
</script>

