var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widthFull relative mt-3 min-height-calc",class:{ 'back-3': _vm.$route.path == '/', customBg: _vm.$route.path != '/' },attrs:{"id":"loginH"}},[_c('div',{staticClass:"container-fluid"},[(_vm.loadingState)?_c('loading'):_vm._e(),_c('form',{staticClass:"login",attrs:{"id":"login-form"},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[(!_vm.resend_success)?_c('div',{staticClass:"bg-theme"},[_vm._m(0),_c('div',{staticClass:"form-row justify-content-center"},[(_vm.moment(_vm.finish) > _vm.timeStore)?_c('div',{staticClass:"col-md-10 center mb-3 mt-4"},[_c('h4',{staticClass:"Regular"},[_vm._v(" Non sei registrato? "),_c('router-link',{staticClass:"underline Regular mt-3",attrs:{"to":"/registration"}},[_c('b',[_vm._v(" Registrati ")])])],1)]):_vm._e()]),_c('div',{staticClass:"form-row justify-content-center"},[_c('div',{staticClass:"col-10 col-md-5 col-lg-3 mb-5 mb-md-3"},[_c('div',{staticClass:"group login-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username_player),expression:"username_player"},{name:"validate",rawName:"v-validate",value:({
									required: true,
									email: true,
									temporaryEmail: true,
									plusIssueEmail: true,
									max: 56
								}),expression:"{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\temail: true,\n\t\t\t\t\t\t\t\t\ttemporaryEmail: true,\n\t\t\t\t\t\t\t\t\tplusIssueEmail: true,\n\t\t\t\t\t\t\t\t\tmax: 56\n\t\t\t\t\t\t\t\t}"}],staticClass:"form-control",class:{
									'is-invalid': _vm.errors.has('username_player'),
									valid: !!_vm.username_player
								},attrs:{"type":"text","id":"username_player","name":"username_player","data-vv-as":"Email"},domProps:{"value":(_vm.username_player)},on:{"blur":_vm.checkIfExistEmail,"input":function($event){if($event.target.composing){ return; }_vm.username_player=$event.target.value}}}),_c('span',{staticClass:"highlight"}),_c('span',{staticClass:"bar"}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"username_player"}},[_vm._v("Email")]),(_vm.errors.has('username_player'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('username_player'))+" ")]):_vm._e(),(_vm.socialEmail && _vm.socialEmail != 'Manual')?_c('div',{staticClass:"yellow mt-3"},[_vm._v(" Sei registrato con: "),_c('strong',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.socialEmail))]),_vm._v(". Per effetuare accesso clicca sulla apposita icona ")]):_vm._e(),(_vm.socialEmail == 'Manual')?_c('div',{staticClass:"yellow mt-3"},[_c('b',[_vm._v("Siamo spiacenti ma non è possibile collegare il tuo account social, hai già effettuato una registrazione utilizzando questo indirizzo e-mail. Puoi accedere utilizzando password scelto in fase di iscrizione. ")])]):_vm._e()])])]),_c('div',{staticClass:"form-row justify-content-center"},[_c('div',{staticClass:"col-10 col-md-5 col-lg-3"},[_c('div',{staticClass:"group user-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_player),expression:"password_player"},{name:"validate",rawName:"v-validate",value:({
									required: true,
									passwordCheck: true,
									min: 8,
									max: 56
								}),expression:"{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tpasswordCheck: true,\n\t\t\t\t\t\t\t\t\tmin: 8,\n\t\t\t\t\t\t\t\t\tmax: 56\n\t\t\t\t\t\t\t\t}"}],staticClass:"form-control",class:{
									'is-invalid': _vm.errors.has('password_player'),
									valid: !!_vm.password_player
								},attrs:{"autocomplete":"","type":"password","id":"password_player","name":"password_player","data-vv-as":"Password"},domProps:{"value":(_vm.password_player)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_player=$event.target.value}}}),_c('span',{staticClass:"highlight"}),_c('span',{staticClass:"bar"}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"password_player"}},[_vm._v("Password")]),(_vm.errors.has('password_player'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('password_player'))+" ")]):_vm._e(),(_vm.loginerror)?_c('div',{staticClass:"mb-3"},[_c('span',{staticClass:"formError ml-1"},[_vm._v("Email o password errati.")])]):_vm._e(),(!_vm.confirmed)?_c('div',{staticClass:"mb-3"},[_c('h5',{staticClass:"text-center"},[_c('a',{staticClass:"underline Regular ml-1",on:{"click":function($event){return _vm.popupButton(
												'info',
												'Info',
												'Segui le indicazioni ricevute nella mail di conferma registrazione per confermare il tuo account... <br> Non hai ricevuto l\'e-mail?'
											)}}},[_c('b',[_vm._v("Account non confermato!")]),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'info-circle']}})],1)])]):_vm._e()])])]),_c('div',{staticClass:"form-row justify-content-center"},[_c('div',{staticClass:"col-md-5 col-lg-3 mt-2"},[_c('div',{staticClass:"group"},[_c('h4',{staticClass:"black text-center"},[_c('router-link',{staticClass:"underline Regular",attrs:{"to":"/forgot-password"}},[_c('b',[_vm._v(" Password dimenticata? ")])])],1)])])]),_c('div',{staticClass:"form-row justify-content-center mt-3"},[_vm._m(1),(_vm.social && _vm.social != 'Manual')?_c('div',{staticClass:"yellow mb-5"},[_vm._v(" Sei registrato con altra social network: "),_c('strong',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.social))]),_vm._v(" Per effetuare accesso clicca sulla apposita icona ")]):(_vm.social == 'Manual')?_c('div',{staticClass:"yellow mt-3"},[_c('b',[_vm._v("Siamo spiacenti ma non è possibile collegare il tuo account social, hai già effettuato una registrazione utilizzando questo indirizzo e-mail. Puoi accedere utilizzando password scelto in fase di iscrizione. ")])]):_vm._e()])]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-8 text-center"},[_c('h3',{staticClass:"col-12 center Bold pb-2 color-1 uppercase"},[_vm._v(" Accedi ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-6 mb-5 text-center"},[_c('button',{staticClass:"btn btn-primary uppercase",attrs:{"type":"submit"}},[_vm._v(" Entra ")])])}]

export { render, staticRenderFns }