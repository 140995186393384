<template>
  <div class="widthFull relative back-3 padding-top-calc min-height-calc">
    <loading v-if="loadingState" />

    <div class="row center mt-4">
      <div class="col-12">
        <h1 class="col-12 center Regular pb-2 color-1 uppercase">
          Crea nuova password
        </h1>
      </div>
    </div>

    <div class="container-fluid">
      <form
        class="recovery-password"
        id="recovery-form"
        @submit.prevent="recoveryValidation"
      >
        <div v-if="!state">
          <div class="form-row justify-content-center">
            <div class="col-md-4">
              <label for="password" class="widthFull text-center"> </label>
              <div class="group mt-5">
                <input
                  autocomplete="on"
                  type="password"
                  v-model="password"
                  class="form-control mt-1"
                  id="password"
                  name="password"
                  v-validate="{
                    required: true,
                    passwordCheck: true,
                    min: 8,
                    max: 56,
                  }"
                  :class="{
                    'is-invalid': errors.has('password'),
                    valid: !!password,
                  }"
                />
                <span class="highlight"></span>

                <label class="niceInputLabel" for="password"
                  >Nuova password</label
                >

                <div v-if="errors.has('password')" class="invalid-feedback">
                  {{ errors.first("password") }}
                </div>
              </div>
              <div class="group mt-5">
                <input
                  autocomplete="on"
                  type="password"
                  v-model="confirm_password"
                  class="form-control mt-3"
                  id="confirm_password"
                  name="confirm_password"
                  data-vv-as="conferma password"
                  v-validate="{
                    required: true,
                    passwordCheck: true,
                    min: 8,
                    max: 56,
                    confirmed: password,
                  }"
                  :class="{
                    'is-invalid': errors.has('confirm_password'),
                    valid: !!confirm_password,
                  }"
                />
                <span class="highlight"></span>

                <label class="niceInputLabel" for="password"
                  >Ripeti password</label
                >

                <div
                  v-if="errors.has('confirm_password')"
                  class="invalid-feedback"
                >
                  {{ errors.first("confirm_password") }}
                </div>
                <div v-if="error" class="mt-3">
                  <span class="formError ml-1">{{ error }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row justify-content-center">
            <div class="col-md-6 mb-3 mt-5 text-center">
              <button class="btn btn-primary mb-4 px-5" type="submit">
                RIPRISTINA
              </button>
            </div>
          </div>
        </div>
        <div v-else="!state">
          <div class="form-row justify-content-center">
            <div class="col-md-4 mb-5 mt-5">
              <h4 for="email" class="center widthFull glade" role="alert">
                La password è stata aggiornata correttamente, <br />
                adesso puoi accedere utilizzando la tua nuova password.
              </h4>
            </div>
          </div>
          <div class="form-row justify-content-center">
            <div class="col-md-6 mb-3 text-center">
              <router-link class="btn btn-primary mb-4 px-5" to="/login"
                >Accedi</router-link
              >
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { AUTH_REQUEST } from "actions/auth";
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import { axiosMixin } from "../../mixins/axiosMixin";

export default {
  name: "recoverypassword",
  mixins: [axiosMixin],
  data() {
    return {
      password: "",
      confirm_password: "",
      state: false,
      error: "",
      loadingState: false,
    };
  },
  methods: {
    async recoveryCall(token) {
      const { password, confirm_password } = this;

      let data = {
        password_player: password,
        temp_token_player: token,
      };

      let r = await axiosMixin.methods.axiosCall(
        "api/changePassword",
        data,
        "POST"
      );
      let message = r.data.message;
      let statusCode = r.data.statusCode;
      let description = r.data.message.description;
      this.loadingState = false;
      try {
        if (r.status < parseInt(process.env.VUE_APP_CATCH)) {
          // then
          if (message) {
            this.state = true;
          }
        } else {
          this.state = false;
          this.error = process.env.VUE_APP_TOKEN_NOT_EXIST;
        }
      } catch (error) {
        this.state = false;
        this.error = process.env.VUE_APP_TOKEN_NOT_EXIST;
      }
    },

    recoveryValidation: function () {
      const token = this.$route.query.token;
      this.$validator.validate().then((valid) => {
        if (valid && token) {
          this.loadingState = true;
          this.recoveryCall(token);
        }
      });
    },
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "loginerror",
      "isAuthenticated",
      "isProfileLoaded",
    ]),
    ...mapState({
      //login_error: state => `${state.login_error}`,
    }),
  },
};
</script>
