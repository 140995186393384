<template>
  <div
    class="widthFull relative min-height-calc--footer customBg--repeat"
    id="privacy"
  >
    <loading v-if="loadingState" />
    <div class="container-fluid">
      <div class="row pb-0 center">
        <div class="col-12 pt-2">
          <h3 class="Bold py-2 color-2 pt-5">
            informativa sul trattamento <br />
            dei dati personali
          </h3>
        </div>
      </div>
    </div>

    <div class="container mt-5 pb-5 regulationContainer color-2">
      <p>
        <strong
          >AI SENSI DELL&rsquo;ART. 13 DEL Regolamento UE 2016/679
          (&ldquo;GDPR&rdquo;)</strong
        >
      </p>
      <p>
        La societ&agrave; Heineken Italia S.p.A., societ&agrave; unipersonale
        soggetta a direzione e coordinamento di Heineken N.V. ai sensi
        dell&rsquo;articolo 2497 <em>bis</em> del codice civile, con sede legale
        in Pollein (AO), Localit&agrave; Autoporto n. 11, e con sede
        amministrativa in Sesto San Giovanni (MI), Viale Edison, 110, capitale
        sociale Euro 47.320.000,00 i.v., Codice Fiscale e nr. iscrizione al
        Registro delle Imprese di Aosta 00869580159, Partita IVA 00610140071, in
        persona del legale rappresentante pro tempore (di seguito
        &ldquo;<strong>Heineken</strong>&rdquo; o la
        &ldquo;<strong>Societ&agrave;</strong>&rdquo;), in qualit&agrave; di
        titolare del trattamento dei dati, con la presente Le fornisce
        l&rsquo;informativa relativa al trattamento dei dati personali, come
        definiti dal GDPR (di seguito
        l&rsquo;&rdquo;<strong>Informativa</strong>&rdquo;).
      </p>
      <ul>
        <strong
          >1) QUALI DATI PERSONALI CHE LA RIGUARDANO POSSONO ESSERE
          RACCOLTI</strong
        >
      </ul>
      <p>
        La Societ&agrave; Le comunica che al fine della partecipazione alla
        manifestazione &ldquo;BIRRA MORETTI TI REGALA I DUE BICCHIERI DI
        FILTRATA A FREDDO&rdquo;, (di seguito
        &ldquo;<strong>Manifestazione</strong>&rdquo;), come previsto nel
        regolamento della Manifestazione (di seguito il
        &ldquo;<strong>Regolamento</strong>&rdquo;), la stessa potr&agrave;
        trattare, le seguenti categorie di dati personali che La riguardano (con
        il termine &ldquo;<strong>Dati Personali</strong>&rdquo; si intenderanno
        tutte le categorie di seguito riportate, congiuntamente considerate):
      </p>
      <ul>
        <li>
          <strong>Dati di contatto</strong>: informazioni relative al Suo nome,
          cognome, email, citt&agrave; di domicilio/residenza indirizzo di
          domicilio/residenza, numero di telefono;
        </li>
        <li>
          <strong>Altri dati personali</strong>: informazioni relative
          all&rsquo;acquisto da Lei effettuato (cd &ldquo;scontrino
          parlante&rdquo; come definito nel Regolamento).
        </li>

        <li>
          Interessi: informazioni che Lei ci fornisce in merito ai Suoi
          interessi;
        </li>
        <li>
          Utilizzo del Sito: informazioni relative alle modalità con cui
          utilizza il Sito, incluse le informazioni raccolte tramite cookie (può
          trovare la nostra Informativa sui Cookie che ne regola i dettagli).
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <strong>2) COME RACCOGLIAMO I SUOI DATI PERSONALI</strong>
      </ul>
      <p>
        La Societ&agrave; pu&ograve; raccogliere e trattare i Suoi Dati
        Personali per la partecipazione alla Manifestazione mediante
        compilazione del relativo modulo presente nell&rsquo;apposita sezione
        del sito internet www.inostribicchieri.it
      </p>
      <p>
        Se Lei fornisce Dati Personali per conto di qualcun altro deve
        assicurarsi, preventivamente, che gli interessati abbiano preso visione
        della presente Informativa.
      </p>
      <p>
        La preghiamo di aiutarci a mantenere aggiornati i Suoi Dati Personali,
        informandoci di qualsiasi modifica.
      </p>
      <ul>
        <strong
          >3) PER QUALI FINALIT&Agrave; POSSONO ESSERE UTILIZZATI I SUOI DATI
          PERSONALI, SU QUALE PRESUPPOSTO GIURIDICO E NATURA DI TALE
          CONFERIMENTO</strong
        >
      </ul>
      <p>
        La Societ&agrave; pu&ograve; trattare i Suoi Dati Personali raccolti per
        la partecipazione alla Manifestazione per una o pi&ugrave; delle
        seguenti finalit&agrave;, sulla base del presupposto giuridico di volta
        in volta indicato.
      </p>
      <ul>
        <strong
          >a) Gestione della Sua iscrizione e partecipazione alla Manifestazione
          ed eventuale consegna del premio.</strong
        >

        <p>
          La Societ&agrave; pu&ograve; trattare i Suoi Dati anagrafici e di
          contatto e gli Altri dati personali al fine di gestire correttamente
          la Sua iscrizione e partecipazione alla Manifestazione.
        </p>
        <p>
          <u>Presupposto per il trattamento</u>: esecuzione di un contratto e/o
          di misure precontrattuali di cui l&rsquo;interessato &egrave; parte.
        </p>
        <p>
          Il conferimento dei Dati anagrafici e di contatto e degli Altri dati
          personali per tale finalit&agrave; &egrave; obbligatorio,
          poich&eacute; in difetto la Societ&agrave; si trover&agrave;
          nell&rsquo;impossibilit&agrave; di permetterLe la partecipazione alla
          Manifestazione.
        </p>
      </ul>
      <ul>
        <strong
          >b) Attivit&agrave; di marketing per fornirLe offerte
          promozionali.</strong
        >

        <p>
          Previo Suo esplicito e specifico consenso, la Societ&agrave;
          pu&ograve; trattare i Suoi Dati anagrafici e di contatto per
          finalit&agrave; di marketing e comunicazione pubblicitaria diretta ad
          informarLa su iniziative promozionali di vendita, realizzate mediate
          modalit&agrave; automatizzate di contatto (posta elettronica, sms,
          MMS, chat, instant messaging, social network e altri strumenti di
          messaggistica massiva, notifiche push, ecc.) e modalit&agrave;
          tradizionali di contatto (ad esempio, telefonata con operatore, posta
          tradizionale, ecc.), ovvero per ricerche di mercato o indagini
          statistiche.
        </p>
        <p>
          Presupposto per il trattamento: il consenso dell&rsquo;interessato.
        </p>
        <p>
          ll conferimento dei Dati Personali &egrave; facoltativo e il mancato
          conferimento degli stessi non comporta conseguenze circa la Sua
          partecipazione alla Manifestazione.
        </p>
      </ul>
      <ul>
        <strong
          >c) Attivit&agrave; di marketing profilato per fornirLe offerte
          promozionali in linea con le Sue preferenze.</strong
        >

        <p>
          Sempre previo Suo apposito e specifico consenso, la Societ&agrave;
          pu&ograve;, altres&igrave;, trattare i Suoi Dati Anagrafici e di
          contatto e gli Altri dati personali, attraverso una loro elaborazione
          statistica, creando un Suo profilo individuale per inviarLe
          comunicazioni commerciali in linea con le Sue preferenze, sulla base
          delle analisi delle Sue abitudini e scelte di acquisto. Tali
          comunicazioni personalizzate potrebbero essere realizzate mediante
          modalit&agrave; automatizzate di contatto (posta elettronica, sms,
          MMS, chat, instant messaging, social network e altri strumenti di
          messaggistica massiva, notifiche push, ecc.) e modalit&agrave;
          tradizionali di contatto (ad esempio, telefonata con operatore, posta
          tradizionale, ecc.).
        </p>
        <p>
          <u>Presupposto per il trattamento</u>: il consenso
          dell&rsquo;interessato.
        </p>
        <p>
          ll conferimento dei Dati Personali &egrave; facoltativo e il mancato
          conferimento degli stessi non comporta conseguenze circa la Sua
          partecipazione alla Manifestazione.
        </p>
        <p>
          Con riferimento alle finalit&agrave; di cui ai punti b) e c) che
          precedono, Lei potr&agrave; in qualsiasi momento indicare la
          modalit&agrave; di contatto che preferisce tra quelle sopra riportate
          e potr&agrave; opporsi al ricevimento di comunicazioni promozionali
          attraverso tutte o solo alcune di tali modalit&agrave; di contatto
          scrivendo all&rsquo;indirizzo e-mail
          <a href="mailto:privacy@heinekenitalia.it"
            >privacy@heinekenitalia.it</a
          >.
        </p>
      </ul>
      <ul>
        <strong
          >d) Comunicazione dei Suoi Dai Personali a terzi per finalità di
          marketing.</strong
        >

        <p>
          Previo Suo apposito e specifico consenso, la Società potrà comunicare
          i Suoi Dati anagrafici e di contatto ad altre società del gruppo
          Heineken N.V. (tra cui nello specifico Beerwulf N.V., Heineken
          Experience N.V., Dibevit S.r.l., Partesa S.p.A.) che li potranno
          utilizzare per effettuare nei Suoi confronti attività promozionale con
          le modalità automatizzate di cui all´art. 130, comma 1 e 2, D.lgs.
          101/2018 senza dover acquisire nuovamente il Suo consenso.
        </p>
        <p>Presupposto per il trattamento: il consenso dell’interessato.</p>
        <p>
          Il consenso può essere revocato in ogni momento, con effetto per i
          successivi trattamenti, inviando un’email alla casella di posta
          privacy@heinekenitalia.it alla cortese attenzione del Privacy Officer.
        </p>

        <p>
          Tale consenso potrà essere revocato, in ogni momento, con effetto per
          i successivi trattamenti.
        </p>
      </ul>
      <ul>
        <strong
          >e) Difesa dei diritti nel corso di procedimenti giudiziali,
          amministrativi o stragiudiziali, e nell&rsquo;ambito di controversie
          sorte in relazione alla Manifestazione.</strong
        >

        <p>
          La Societ&agrave; pu&ograve; trattare i Suoi Dati Personali per
          difendere i propri diritti o agire o anche avanzare pretese nei
          confronti Suoi o di terze parti.
        </p>
        <p>
          <u>Presupposto per il trattamento</u>: legittimo interesse della
          Societ&agrave; alla tutela dei propri diritti.
        </p>
        <p>
          Il conferimento dei Dati Personali per tale finalit&agrave; &egrave;
          obbligatorio poich&eacute; in difetto la Societ&agrave; si
          trover&agrave; nell&rsquo;impossibilit&agrave; di difendere i propri
          diritti.
        </p>
      </ul>
      <ul>
        <strong
          >f) Finalit&agrave; connesse agli obblighi previsti da leggi,
          regolamenti o dalla normativa comunitaria, da disposizioni / richieste
          di autorit&agrave; a ci&ograve; legittimate dalla legge e/o da organi
          di vigilanza e controllo.</strong
        >

        <p>
          La Societ&agrave; pu&ograve; trattare i Suoi Dati Personali per
          adempiere agli obblighi cui la stessa &egrave; tenuta.
        </p>
        <p>
          <u>Presupposto per il trattamento</u>: adempimento di un obbligo
          legale.
        </p>
        <p>
          Il conferimento dei Dati Personali per tale finalit&agrave; &egrave;
          obbligatorio poich&eacute; in difetto la Societ&agrave; si
          trover&agrave; nell&rsquo;impossibilit&agrave; di adempiere a
          specifici obblighi di legge.
        </p>
      </ul>
      <ul>
        <strong>4) COME MANTENIAMO SICURI I SUOI DATI PERSONALI</strong>
      </ul>
      <p>
        La Societ&agrave; utilizza tutte le misure di sicurezza necessarie al
        miglioramento della protezione e la manutenzione della sicurezza,
        dell&rsquo;integrit&agrave; e dell&rsquo;accessibilit&agrave; dei Suoi
        Dati Personali.
      </p>
      <p>
        Tutti i Suoi Dati Personali sono conservati sui nostri server protetti
        (o copie cartacee idoneamente archiviate) o su quelli dei nostri
        fornitori e sono accessibili ed utilizzabili in base ai nostri standard
        e alle nostre policy di sicurezza (o standard equivalenti per i nostri
        fornitori).
      </p>
      <p>&nbsp;</p>
      <ul>
        <strong>5) PER QUANTO TEMPO CONSERVIAMO I SUOI DATI PERSONALI</strong>
      </ul>
      <p>
        Conserviamo i Suoi Dati Personali solo per il tempo necessario al
        conseguimento delle finalit&agrave; per le quali sono stati raccolti o
        per qualsiasi altra legittima finalit&agrave; collegata. Pertanto, se i
        Dati Personali sono trattati per due differenti finalit&agrave;,
        conserveremo tali Dati Personali fino a che non cesser&agrave; la
        finalit&agrave; con il termine pi&ugrave; lungo; tuttavia non tratteremo
        pi&ugrave; i Dati Personali per quella finalit&agrave; il cui periodo di
        conservazione sia venuto meno.
      </p>
      <p>
        Qui di seguito riportiamo i tempi di conservazione in relazione alle
        differenti finalit&agrave; sopra elencate:
      </p>
      <ol>
        <p>
          <strong
            >a) Gestione della Sua iscrizione e partecipazione alla
            Manifestazione ed eventuale consegna del premio</strong
          >: i Dati Personali trattati per questa finalit&agrave; potranno
          essere conservati dalla Societ&agrave; per il tempo necessario a
          gestire la Sua richiesta di iscrizione e partecipazione alla
          Manifestazione, nonch&eacute; l&rsquo;eventuale consegna del premio e
          le attivit&agrave; ad essa connesse, e successivamente per 180 giorni
          dalla conclusione della Manifestazione.
        </p>
        <p>
          <strong
            >b) Attivit&agrave; di marketing per fornirLe offerte
            promozionali</strong
          >: i Dati Personali trattati per finalit&agrave; di marketing potranno
          essere conservati dalla Societ&agrave; per 24 mesi dalla loro
          raccolta.
        </p>
        <p>
          <strong
            >c) Attivit&agrave; di marketing profilato per fornirLe offerte
            promozionali in linea con le Sue preferenze</strong
          >: i Dati Personali trattati per finalit&agrave; di marketing
          profilato potranno, invece, essere conservati dalla Societ&agrave; per
          12 mesi dalla loro raccolta.
        </p>
        <p>
          <strong
            >d) Difesa dei diritti nel corso di procedimenti giudiziali,
            amministrativi o stragiudiziali, e nell&rsquo;ambito di controversie
            sorte in relazione alla Manifestazione</strong
          >: i Dati Personali trattati per questa finalit&agrave; potranno
          essere conservati dalla Societ&agrave; per il tempo strettamente
          necessario alla realizzazione di tale finalit&agrave;.
        </p>
        <p>
          <strong
            >e) Finalit&agrave; connesse agli obblighi previsti da leggi,
            regolamenti o dalla normativa comunitaria, da disposizioni /
            richieste di autorit&agrave; a ci&ograve; legittimate dalla legge
            e/o da organi di vigilanza e controllo</strong
          >: i Dati Personali trattati per queste finalit&agrave; potranno
          essere conservati dalla Societ&agrave; per il tempo strettamente
          necessario alla realizzazione di tali finalit&agrave;.
        </p>
      </ol>
      <p>&nbsp;</p>
      <ul>
        <strong>6) CON CHI POSSIAMO CONDIVIDERE I SUOI DATI PERSONALI</strong>
      </ul>
      <p>
        Ferme restando le comunicazioni eseguite in adempimento di obblighi di
        legge, ai Suoi Dati Personali possono avere accesso i dipendenti della
        Societ&agrave; debitamente autorizzati, nonch&eacute; il fornitore
        esterno NOVATAG Srl designato e nominato dalla Societ&agrave;
        Responsabile del trattamento, a cui &egrave; affidata la gestione della
        Manifestazione. La Preghiamo di contattarci agli indirizzi di seguito
        riportati se desidera chiedere di poter visionare la lista dei
        responsabili del trattamento e degli altri soggetti cui comunichiamo i
        Suoi Dati Personali.
      </p>
      <ul>
        <strong>7) CONTATTI</strong>
      </ul>
      <p>I dati di contatto della Societ&agrave; sono i seguenti:</p>
      <p>
        Heineken Italia S.p.A., Viale Edison n. 110, Sesto San Giovanni (MI).
      </p>
      <p>
        La Societ&agrave; ha nominato un Responsabile della protezione dei dati
        (&ldquo;DPO&rdquo;) che pu&ograve; essere contattato al seguente
        indirizzo di posta elettronica:
        <a href="mailto:dpo@heinekenitalia.it">dpo@heinekenitalia.it</a>.
      </p>
      <p>&nbsp;</p>
      <ul>
        <strong
          >8) I SUOI DIRITTI IN MATERIA DI PROTEZIONE DEI DATI PERSONALI E IL
          SUO DIRITTO DI PROPORRE RECLAMO DINNANZI ALL&rsquo;AUTORIT&Agrave;
          GARANTE</strong
        >
      </ul>
      <p>
        A certe condizioni, Lei ha il diritto di chiedere alla Societ&agrave;:
      </p>
      <ul>
        <li>l&rsquo;accesso ai Suoi Dati Personali;</li>
        <li>
          la copia dei Dati Personali da Lei forniti alla Societ&agrave;, in un
          formato strutturato, di uso comune e leggibile da dispositivo
          automatico e la trasmissione di tali Dati Personali ad un altro
          titolare del trattamento (portabilit&agrave;);
        </li>
        <li>la rettifica dei Dati Personali in nostro possesso;</li>
        <li>
          la cancellazione di qualsiasi Dato Personale per il quale la
          Societ&agrave; non ha pi&ugrave; alcun presupposto giuridico per il
          trattamento;
        </li>
        <li>
          la revoca del Suo consenso, nel caso in cui il trattamento sia fondato
          sul consenso;
        </li>
        <li>la limitazione del trattamento.</li>
      </ul>
      <p>
        <u>Diritto di opposizione</u>: oltre ai diritti in precedenza elencati,
        Lei ha sempre facolt&agrave; di opporsi in qualsiasi momento, per motivi
        connessi alla Sua situazione particolare, al trattamento dei Suoi Dati
        svolto dal Titolare per il perseguimento del proprio legittimo
        interesse. Inoltre pu&ograve; sempre opporsi in qualsiasi momento
        qualora i Dati siano trattati per finalit&agrave; di marketing e di
        marketing profilato.
      </p>
      <p>
        La richiesta di opposizione va indirizzata al seguente indirizzo:
        privacy@heinekenitalia.it.
      </p>
      <p>
        L&rsquo;esercizio di tali diritti &egrave; gratuito e non &egrave;
        soggetto a vincoli di carattere formale, ma soggiace ad alcune eccezioni
        finalizzate alla salvaguardia dell&rsquo;interesse pubblico (ad esempio
        la prevenzione o l&rsquo;identificazione di crimini) e di interessi
        della Societ&agrave; (ad esempio il mantenimento del segreto
        professionale). Nel caso in cui Lei esercitasse uno qualsiasi dei
        summenzionati diritti, sar&agrave; onere della Societ&agrave; verificare
        che Lei sia legittimato ad esercitarlo e darLe riscontro, di regola,
        entro un mese.
      </p>
      <p class="pb-5 mb-5">
        Nel caso ritenga che il trattamento dei Dati Personali a Lei riferiti
        avvenga in violazione di quanto previsto dal GDPR ha il diritto di
        proporre reclamo al Garante per la protezione dei dati personali,
        utilizzando i riferimenti disponibili sul sito internet
        <a href="http://www.garanteprivacy.it">www.garanteprivacy.it</a>, o di
        adire le opportune sedi giudiziarie.
      </p>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
import axios from "axios";
import {
  USER_REQUEST,
  ACCEPT_RELAY_COOKIES,
  ACCEPT_SALESFORCE_COOKIES,
} from "actions/user";
import { mapGetters } from "vuex";

export default {
  name: "informativaPrivacy",
  data() {
    return {
      loadingState: "",
      userToken: "",
    };
  },
  methods: {
    scrollToTop: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo(".main", 700, { offset: -220 });
      }, 100);
    },
  },
  watch: {},
  mounted: function () {
    this.scrollToTop();
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "acceptCookies",
      "checkAge",
      "acceptSalesForce",
      "acceptRelay42",
    ]),
  },
};
</script>