import axios from 'axios';
import a from 'crypto-js'

export const axiosMixin = {
	data() {
		return {

		}
	},
	methods: {

		e(c, add = true) {
			if (add) {
				c.HBGRF = process.env.VUE_APP_HBGRF,
					c.vector_HBGRF = process.env.VUE_APP_ICODE,
					c = JSON.stringify(c)
			}
			let e = a.enc.Utf8.parse('B%t$AD!YMfnCZnfD');
			let b = a.AES.encrypt(c, e, {
				mode: a.mode.ECB,
				padding: a.pad.Pkcs7
			});
			return b.ciphertext.toString();
		},

		d(c) {
			let ckey = a.enc.Utf8.parse('B%t$AD!YMfnCZnfD');
			var m = a.enc.Hex.parse(c);
			let srcs = a.enc.Base64.stringify(m);
			let l = a.AES.decrypt(srcs, ckey, {
				mode: a.mode.ECB,
				padding: a.pad.Pkcs7
			});
			let b = l.toString(a.enc.Utf8);
			return JSON.parse(b)
		},

		callLog(string, data) {
			if (process.env.VUE_APP_LOG == 'ON') {
				console.log(string + '-> call', {
					data
				})
			}
			return true
		},

		respLog(string, data, type) {
			if (process.env.VUE_APP_LOG == 'ON') {

				switch (type) {
					case 'warn':
						console.warn(string + '-> resp', {
							data
						})
						break;
					case 'err':
						console.error(string + '-> resp', {
							data
						})
						break;
					default:
						console.log(string + '-> resp', {
							data
						})
						break;
				}
			}
			return true
		},

		axiosCall(path, data, type, stringify = true) {

			this.callLog(path, data);
			stringify ? data = this.e(data) : this.e(data, false)

			const promise = axios({
				url: process.env.VUE_APP_ROOT_API + path,
				method: type,
				data: {
					data
				},
				headers: {
					Authorization: 'Bearer ' + process.env.VUE_APP_KITTY,
					'Content-type': 'application/json',
				},
			});

			const dataPromise = promise.then((resp) => ({
				status: resp.status,
				data: this.d(resp.data),
				log: this.respLog(path, this.d(resp.data), 'warn')
			})).catch((err) => ({


				status: err.response.status,
				data: this.d(err.response.data),
				log: this.respLog(path, this.d(err.response.data), 'err')
			}))
			return dataPromise
		},

	},

	mounted() {},

}