import {
	mapGetters
} from 'vuex';

export const popupConfigurationMixin = {

	data() {
		return {
			popupConf: [],
		}
	},

	methods: {

		popupErrorCode(code, html = '') {
			// FIND CORRESPOND POPUPCODE
			const element = this.popupConf.find(el => el.code_popup == code);

			// IF POPUP CONFIGURATION FOR THIS CODE NOT ACTIVE -> EXIT
			if (element.active_json_popup_outcome == 0) {
				return true
			}

			let functionOnClose = '';
			const json = JSON.parse(element.json_configuration_popup_outcome);


			if (json.function_onclose_active) {
				functionOnClose = json.function_onclose
			}

			this.$swal({
				type: json.type,
				title: json.title,
				html: json.html,
				confirmButtonText: 'CHIUDI',
				confirmButtonColor: '#15267',
				timer: json.time || '',

				onClose: () => {
					switch (functionOnClose) {
						case 'reload':
							window.location.reload()
							break;
						case 'home':
							this.$router.push('/');
							break;
						case 'receipts':
							this.$router.push('/receipts');
							break;
						default:
							break;
					}
				},
			});
		},

		popupErrorText(type, title, html, timer = '', callbackOnClose = '') {
			this.$swal({
				type,
				title,
				html,
				confirmButtonText: 'CHIUDI',
				confirmButtonColor: '#15267',
				timer,

				onClose: () => {

					switch (callbackOnClose) {
						case 'reload':
							window.location.reload()
							break;
						case 'home':
							this.$router.push('/');
							break;
						case 'receipts':
							this.$router.push('/receipts');
							break;
						default:
							break;
					}
				},
			});
		},

		popupImage(html, callbackOnClose = '') {
			this.$swal({
				showConfirmButton: false,
				imageUrl: require('../assets/images/popup-title.png'),
				html: html,
				onClose: () => {

					switch (callbackOnClose) {
						case 'reload':
							window.location.reload()
							break;
						case 'home':
							this.$router.push('/');
							break;
						case 'receipts':
							this.$router.push('/receipts');
							break;
						default:
							break;
					}
				},
			});
		},


		// DA FARE.. POP UP DINAMICO CON BOTTONE PER RICHIAMARE UNA FUNZIONE
		popupButton(type, title, html, timer = '', callbackOnClose = '') {
			this.$swal({
				type,

				html,
				timer,
				confirmButtonText: 'INVIA NUOVA E-MAIL',
				confirmButtonColor: '#15267',
				showCancelButton: true,
				cancelButtonText: 'CHIUDI',
			}).then((res) => {
				if (res.value) {

					this.resendConfirmEmail()

					this.$swal({
						type: 'success',
						html: 'E-mail inviata con successo'
					}).then((res) => {
						this.reload()
					})
				}
			})
		},

		reload: function () {
			window.location.reload();
		},
	},

	mounted() {

		let _vue = this
		setTimeout(function () {
			_vue.popupConf = _vue.getPopup.arr
			// _vue.$track('popupConfigurationMixin getPopup', _vue.popupConf)
		}, 1000);

	},

	computed: {
		...mapGetters(['isAuthenticated', 'authStatus', 'getPopup']),
	}

}