<template>
  <div class="widthFull relative min-height-calc--footer customBg--repeat">
    <loading v-if="loadingState" />

    <div class="container-fluid">
      <div class="row pb-0 center">
        <div class="col-12 pt-2">
          <h3 class="Bold py-2 color-2 pt-5">Regolamento</h3>
        </div>
      </div>
    </div>

    <div class="container color-2 Regular mt-5">
      <div>
        <p>Concorso a premi INSTANT WIN promosso da:</p>
        <p>HEINEKEN ITALIA S.p.A. Viale Edison 110, Sesto San Giovanni (MI)</p>
        <p>P. I.V.A.: 00610140071</p>
        <p>Codice Fiscale: 00869580159</p>
        <p>Telefono: 02/ 270761</p>
        <p>Fax : 02/27075601</p>
        <p>E-MAIL: <a href="mailto:dued@dued.mi.it">dued@dued.mi.it</a></p>
      </div>
      <table class="color-2">
        <tbody>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>
              <p>DENOMINATO:</p>
            </td>
            <td>
              <p>
                <strong
                  >VINCI UNA CENA CON BIRRA MORETTI FILTRATA A FREDDO</strong
                >
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>AREA:</p>
            </td>
            <td>
              <p>Territorio nazionale</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>PERIODO:</p>
            </td>
            <td>
              <p>Dal 01 Marzo 2022 al 30 Aprile 2022.</p>
              <p>
                Assegnazione dei premi instant win ed eventuale estrazione di
                recupero dei premi non assegnati o non convalidati entro il 31
                Maggio 2022.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>DESTINATARI:</p>
            </td>
            <td>
              <p>I maggiorenni domiciliati o residenti in Italia.</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>PUBBLICITA&rsquo;:</p>
            </td>
            <td>
              <p>
                materiali all&rsquo;interno dei punti vendita aderenti; siti di
                rivenditori on line; campagna TV, affissioni; sito
                <a href="http://www.birramoretti.it">www.birramoretti.it</a>;
                social networks; video Platform; attivit&agrave; di PR
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p><strong>&nbsp;</strong><strong>MECCANICA &nbsp;</strong></p>
      <p>
        Per partecipare all&rsquo;iniziativa il concorrente dovr&agrave;
        acquistare, dal 01 marzo 2022 al 30 aprile 2022
        <u>con un unico scontrino parlante</u> almeno un prodotto di Birra
        Moretti Filtrata a Freddo da 55cl.
      </p>

      <p>L&rsquo;acquisto potr&agrave; essere effettuato:</p>
      <ul>
        <li>
          presso un negozio fisico situato sul territorio nazionale. Per
          &ldquo;negozio fisico&rdquo; si intendono ipermercati, supermercati,
          superette e discount;
        </li>
      </ul>
      <p>oppure</p>
      <ul>
        <li>
          su siti on line di rivenditori italiani, con server in Italia, che
          emettono uno scontrino parlante o un documento equiparabile allo
          scontrino che attesti l&rsquo;avvenuto acquisto; il consumatore che
          acquister&agrave; su siti on line dovr&agrave; quindi preventivamente
          accertarsi che verr&agrave; emesso uno scontrino parlante o una
          ricevuta, attestante l&rsquo;acquisto, parlante.
          <u
            >Per gli acquisti su siti on line lo scontrino o la ricevuta
            attestante l&rsquo;acquisto necessaria per partecipare al concorso
            verr&agrave; inviata unitamente alla consegna di quanto acquistato,
            pertanto sar&agrave; possibile partecipare al concorso solamente con
            acquisti che prevedono la consegna entro il 30 aprile 2022</u
          >.
        </li>
      </ul>

      <p>
        Verranno ritenuti validi solo gli scontrini &ldquo;parlanti&rdquo;
        &ndash; o un documento equiparabile - in cui sono indicati, totalmente o
        almeno parzialmente, i prodotti Birra Moretti Filtrata a Freddo da 55cl
        acquistati (le seguenti diciture generiche, REP 01/REPARTO 1/BIRRA ad
        esempio non saranno ritenute valide). La societ&agrave; promotrice
        si&nbsp; riserva inoltre di effettuare gli&nbsp; opportuni&nbsp;
        controlli&nbsp; di&nbsp; coerenza&nbsp; del&nbsp; costo&nbsp; del&nbsp;
        prodotto promozionato acquistato ed indicato nello scontrino.
      </p>

      <p>
        <u
          >Con lo stesso scontrino/ricevuta si pu&ograve; partecipare una sola
          volta.
        </u>
      </p>

      <p>
        L&rsquo;eventuale acquisto, con il medesimo scontrino/ricevuta, di
        pi&ugrave; prodotti promozionati dar&agrave; comunque diritto ad una
        sola partecipazione.
      </p>

      <p>
        I consumatori potranno partecipare pi&ugrave; volte al concorso,
        utilizzando ogni volta scontrini/ricevute diversi. Gli
        scontrini/ricevute dovranno avere sempre data e orario di partecipazione
        compresi nel periodo promozionale e antecedenti alla data e
        all&rsquo;ora di effettuazione della partecipazione.
      </p>

      <p>
        Il partecipante potr&agrave; accedere al concorso con le seguenti due
        modalit&agrave;:
      </p>

      <ul>
        <li>
          tramite WEB collegandosi al sito birramoretti.it ed accedendo alla
          sezione dedicata alla promozione (birramoretti.it/vinciunacena);
        </li>
      </ul>
      <p>oppure, in alternativa</p>
      <ul>
        <li>
          tramite WhatsApp&reg; dopo la prima registrazione al sito
          birramoretti.it accedendo alla sezione dedicata alla promozione
          (birramoretti.it/vinciunacena) il partecipante deve inviare un
          messaggio con la foto dello scontrino al numero dedicato 3917054053.
        </li>
      </ul>
      <p>
        Solo per facilitare la partecipazione alcuni materiali promozionali
        presenti nei punti di vendita conterranno un QR-CODE che
        permetter&agrave; al concorrente, se lo desidera, di accedere
        direttamente ai canali WEB e WhatsApp inquadrando il QR-CODE.
      </p>
      <p><u>&nbsp;</u></p>
      <p>
        <strong
          >Partecipazione tramite WEB sul sito birramoretti.it - sezione
          dedicata alla promozione (birramoretti.it/vinciunacena)</strong
        >
      </p>
      <p><strong>&nbsp;</strong></p>
      <p>
        Il concorrente dovr&agrave; collegarsi dalle ore 00:00:00 del 01/03/2022
        alle ore 23:59:59 del 30/04/2022 al sito birramoretti.it ed accedere
        alla sezione dedicata alla promozione (birramoretti.it/vinciunacena).
      </p>

      <p>
        Dovr&agrave; poi compilare tutti i dati indicati come obbligatori
        nell&rsquo;apposito form di registrazione, accettare il regolamento e -
        nella sezione dedicata - fare l&rsquo;upload della foto dello scontrino
        che documenta l'acquisto (o un documento equiparabile allo scontrino).
        Nel caso in cui si fosse gi&agrave; registrato all&rsquo;iniziativa il
        consumatore dovr&agrave; effettuare il login con le credenziali di
        accesso gi&agrave; in suo possesso.
      </p>

      <p>
        La presenza di qualsiasi altro tipo di immagine che non sia lo scontrino
        di acquisto o un documento equiparabile render&agrave; non valida la
        partecipazione.
      </p>

      <p>
        Per la trasmissione delle immagini della prova
        d&rsquo;acquisto/scontrino si dovranno rispettare i limiti di peso ed i
        formati indicati sulla pagina del sito dedicata al caricamento delle
        immagini (formato pdf, jpg e png con peso massimo di 10 MB). &nbsp;
      </p>

      <p>
        Successivamente ai sopra indicati passaggi il sistema di lettura
        automatica degli scontrini OCRTAG&reg; dalla fotografia della prova
        d'acquisto rilever&agrave; automaticamente e far&agrave; visualizzare
        immediatamente al partecipante i dati di seguito indicati al fine
        possano essere verificati:
      </p>
      <ul>
        <li>data della prova d'acquisto (giorno, mese, anno);</li>
        <li>ora di emissione della prova d'acquisto (ora, minuti);</li>
        <li>importo totale;</li>
        <li>numero progressivo della prova d'acquisto (scontrino/ricevuta).</li>
      </ul>

      <p>
        Il partecipante, dopo aver verificato la corretta corrispondenza tra
        quanto rilevato dal sistema di lettura automatica OCRTAG&reg; (che
        visualizzer&agrave; a video) e i dati riportati sulla prova
        d&rsquo;acquisto in suo possesso, potr&agrave; cliccare sul pulsante
        &ldquo;PARTECIPA&rdquo;.
      </p>

      <p>
        Si attiver&agrave; quindi il programma di assegnazione dei premi che
        individuer&agrave; il vincitore in modo del tutto casuale.
      </p>
      <p>
        Il concorrente visualizzer&agrave; subito l'esito della partecipazione,
        sia in caso di esito vincente che non vincente (se vincente gli
        verr&agrave; richiesto di compilare un apposito form con i dati
        necessari alla gestione della spedizione del premio quali indirizzo di
        spedizione e numero di telefono).
      </p>

      <p><strong>Partecipazione tramite WhatsApp&reg;</strong></p>
      <p><strong>&nbsp;</strong></p>
      <p>
        La partecipazione tramite canale WhatsApp&reg; comporta al primo invio
        collegarsi dalle ore 00:00:00 del 01/03/2022 alle ore 23:59:59 del
        30/04/2022 al sito birramoretti.it ed accedere alla sezione dedicata
        alla promozione (birramoretti.it/vinciunacena), registrarsi indicando il
        numero di telefono mobile non schermato da cui verranno inviati i
        successivi scontrini (o documenti equiparabili). Sono ammessi a
        partecipare WhatsApp&reg; inviati attraverso tutti gli operatori
        nazionali da apparecchi cellulari mobili con numero in chiaro.
      </p>
      <p>
        Dalla successiva partecipazione l'utenza gi&agrave; registrata
        sar&agrave; incanalata automaticamente nella modalit&agrave;
        WhatsApp&reg;.&nbsp;
      </p>
      <p>
        Il partecipante dovr&agrave; poi inviare
        <strong>al numero dedicato 3917054053 </strong>un messaggio
        WhatsApp&reg; contenente la fotografia (FORMATO JPG) dello
        scontrino/ricevuta. Il sistema di lettura automatica degli scontrini
        OCRTAG&reg;, dalla fotografia della prova d'acquisto, rilever&agrave;
        automaticamente i dati di seguito indicati:
      </p>
      <ul>
        <li>data della prova d'acquisto (giorno, mese, anno);</li>
        <li>ora di emissione della prova d'acquisto (ora, minuti);</li>
        <li>importo totale;</li>
        <li>numero progressivo della prova d'acquisto (scontrino/ricevuta).</li>
      </ul>

      <p>
        La presenza di qualsiasi altro tipo di immagine che non sia lo scontrino
        di acquisto render&agrave; non valida la partecipazione.
      </p>

      <p>
        L'esito della partecipazione verr&agrave; comunicato subito tramite
        WhatsApp&reg;; l&rsquo;utente ricever&agrave; un messaggio di risposta
        automatico tramite WhatsApp&reg;, il cui costo &egrave; a carico del
        promotore.
      </p>
      <p>
        In caso di esito vincente gli verr&agrave; inviato un link con la
        richiesta di compilare un apposito form con i dati necessari alla
        gestione della spedizione del premio quali indirizzo di spedizione e
        numero di telefono).
      </p>
      <p><strong>&nbsp;</strong></p>
      <p>
        Per entrambe le modalit&agrave;, WEB o WhatsApp&reg;, qualora non
        dovesse essere leggibile l'importo o il numero dello scontrino/ricevuta
        il sistema richieder&agrave; al partecipanti, con appositi messaggi
        interlocutori, il/i dati mancanti. La lettura della data e dell'ora
        &egrave;, invece, condizione necessaria per aver accesso all'esito della
        giocata. Qualora il sistema non dovesse riconoscere la data e l'ora
        dello scontrino il consumatore verr&agrave; invitato ad inviare una
        nuova fotografia in cui risultino ben visibili la data e l'ora.
      </p>

      <p>
        L&rsquo;immagine dello scontrino/ricevuta deve essere completa in
        un&rsquo;unica facciata, non verranno accettati scontrini/ricevute
        divisi in pi&ugrave; foto e/o scontrini fronte-retro che riportino uno
        dei dati appena citati sul retro. L&rsquo;immagine della prova di
        acquisto deve comprendere tutto il documento d&rsquo;acquisto originale
        non saranno pertanto ritenute valide immagini parziali o altre&nbsp;
        tipologie&nbsp; di&nbsp; illustrazioni.
      </p>
      <p>
        La prova di acquisto deve essere chiaramente leggibile in ogni sua parte
        ed avere una data compresa tra il 01 marzo 2022 e il 30 aprile 2022 (non
        saranno, in ogni caso, accettate partecipazioni precedenti o successive
        alle date indicate).
      </p>
      <p>
        L&rsquo;immagine deve essere centrata e con i bordi allineati, non deve
        essere inclinata e lo scontrino non deve essere curvo (si consiglia di
        appoggiare lo scontrino su una superficie piatta); deve essere
        correttamente a fuoco (la foto non deve mossa o sfuocata) e deve
        risultare leggibile nella sua totalit&agrave; anche ad occhio umano.
        Qualora le immagini caricate dovessero risultare incomplete,
        illeggibili, e/o contraffatte o dovessero contenere dati
        incomprensibili, incompleti e/o palesemente non veritieri verranno
        ritenute non valide ai fini della partecipazione alla presente
        manifestazione. In questo senso, a titolo meramente esemplificativo e
        non esaustivo, non saranno considerati validi ai fini del concorso
        scontrini/ricevute non integri, con cancellature, abrasioni e
        alterazioni oppure con uno o pi&ugrave; dati (tra quelli richiesti)
        ricoperti con nastro adesivo o qualsiasi altro materiale.
      </p>
      <p><u>&nbsp;</u></p>
      <p>
        Anche se il partecipante ha ricevuto il messaggio automatico di vincita
        si precisa che il promotore si riserva di effettuare tutte le verifiche
        che riterr&agrave; opportune prima di confermare la vincita. Tale
        conferma avverr&agrave; solo dopo un attento riscontro finale delle
        risultanze indicate dal software e dopo aver effettuato il controllo
        della documentazione come descritto nel paragrafo &ldquo;convalida della
        vincita&rdquo;.
        <u
          >Il partecipante dovr&agrave; conservare lo scontrino/ricevuta in
          originale indispensabile per la convalida dell&rsquo;eventuale
          vincita.</u
        >
      </p>
      <p><strong>&nbsp;</strong></p>
      <p>
        <strong
          >UN PREMIO INSTANT WIN IN PALIO OGNI GIORNO PER UN TOTALE DI N.61
          PREMI INSTANT WIN.</strong
        >
      </p>
      <p>
        Le partecipazioni inviate tramite WEB o WhatsApp&reg; attivano
        automaticamente il medesimo sistema informatico che, con metodo random
        non programmato, assegna ogni giorno per tutto il periodo di svolgimento
        del concorso <strong>n.61</strong> <strong><u>PREMI</u> </strong>(un
        premio al giorno) consistenti ciascuno in: una cena con Chef a domicilio
        di Chefbooking per 6 persone (un vincitore + cinque ospiti), tutti
        maggiorenni. Men&ugrave; Sharing food composto da 6 portate. Il
        vincitore potr&agrave; comporre il men&ugrave; tra una lista di ricette
        predefinite (in caso di intolleranze, allergie o problematiche
        alimentari, potranno essere fatte variazioni sui piatti ad hoc dallo
        chef). L&rsquo;evento potr&agrave; essere riservato con minimo 14 giorni
        di preavviso, salvo disponibilit&agrave; dello chef e del vincitore.
        Periodo di fruizione entro il 30 Novembre 2022.
      </p>
      <p>
        Nel caso in cui gli ospiti fossero un numero inferiore rispetto a cinque
        il vincitore non avr&agrave; diritto ad alcun rimborso o premio
        alternativo. Dovranno essere a carico del vincitore i costi di eventuali
        ospiti aggiuntivi, quindi in numero maggiore rispetto a quelli indicati
        nella descrizione del premio del presente regolamento.
      </p>
      <p>
        Tutte le eventuali spese e gli extra non compresi nel premio sono a
        carico del vincitore.
      </p>
      <p>
        <u
          >Il premio non &egrave; cedibile e non pu&ograve; essere trasferito
          dal vincitore ad un altro soggetto.</u
        >
      </p>
      <p>
        <strong>Totale Montepremi in comune commercio: </strong>(Euro 600,00 X
        61 vincitori) =
      </p>
      <p><strong>E</strong><strong>uro 36.600,00 IVA ESCLUSA. </strong></p>

      <p>
        Qualora il premio giornaliero non dovesse essere assegnato per qualsiasi
        motivo si sommer&agrave; a quello in palio nel giorno successivo e
        cos&igrave; via. Eventuali premi residui nell&rsquo;ultimo giorno di
        concorso oppure quelli non assegnati, a titolo esemplificativo, per:
        dati inseriti non veritieri, vincite doppie, partecipante minorenne, e
        in generale per il mancato rispetto del presente regolamento verranno
        assegnati nel corso dell&rsquo;estrazione a recupero pi&ugrave; avanti
        descritta.
      </p>

      <p>
        Ogni consumatore potr&agrave; partecipare pi&ugrave; volte al concorso,
        utilizzando ogni volta scontrini diversi, ma potr&agrave; vincere UN
        SOLO PREMIO NEL CORSO DELL&rsquo;INTERA INIZIATIVA. Se, in fase di
        convalida, pi&ugrave; di una vincita instant win risultasse assegnata
        allo stesso partecipante, le vincite eccedenti saranno annullate.
      </p>
      <p><strong>&nbsp;</strong></p>
      <p><strong>CONVALIDA DELLA VINCITA DEI PREMI INSTANT WIN</strong></p>
      <p>
        In caso di messaggio vincente, per aver diritto al premio, il vincitore
        dovr&agrave; inviare in busta chiusa, tramite posta prioritaria,
        regolarmente affrancata (o preferibilmente con lettera raccomandata),
        entro 5 giorni dal messaggio automatico di vincita (farà fede la data
        riportata sulla busta di invio):
      </p>
      <ul>
        <li>
          lo scontrino parlante (o il documento equiparabile) attestante
          l&rsquo;acquisto <strong><u>in originale</u></strong> utilizzato per
          la partecipazione al concorso e risultato vincente. Non verranno
          tenute in considerazione le fotocopie o documenti alternativi allo
          scontrino/ricevuta in originale;
        </li>
        <li>
          la fotocopia della propria carta di identità fronte/retro in corso di
          validit&agrave;;
        </li>
        <li>il proprio indirizzo completo e numero di telefono cellulare</li>
      </ul>
      <p>
        a: CONCORSO &ldquo;VINCI UNA CENA CON BIRRA MORETTI FILTRATA A
        FREDDO&rdquo;
      </p>
      <p>C/O NOVATAG Srl</p>
      <p>Via Alessandro Volta 60 &ndash; 20047 &ndash; Cusago MI</p>

      <p>
        <u
          >A tale proposito si specifica che &egrave; a cura del vincitore
          effettuare le verifiche relative al corretto ricevimento da parte
          dell&rsquo;agenzia NOVATAG Srl della documentazione spedita per la
          convalida della vincita.</u
        >
      </p>

      <p>
        Successivamente al ricevimento della documentazione richiesta,
        sar&agrave; effettuato un controllo da parte della societ&agrave;
        incaricata dalla promotrice che verificher&agrave; la regolarit&agrave;
        della documentazione ricevuta. Nel caso in cui la documentazione fosse
        inviata parzialmente, in ritardo, o non pervenisse del tutto &ndash; per
        qualsiasi ragione indipendente dalla promotrice - all&rsquo;indirizzo
        indicato il premio non verr&agrave; confermato. Nel caso in cui i dati
        inviati non siano conformi a quanto previsto dal presente regolamento o
        con quelli registrati dal sistema software che ha assegnato il premio la
        vincita non sar&agrave; convalidata. Il vincitore decadr&agrave;
        altres&igrave; dal diritto al premio nel caso in cui lo
        scontrino/ricevuta attestante l&rsquo;acquisto inviata non venisse
        ritenuto valido (a titolo esemplificativo e non esaustivo: fosse una
        fotocopia, fosse illeggibile, manomesso, recasse abrasioni,
        cancellazioni, non indicasse, tra i prodotti acquistati Birra Moretti
        Filtrata a Freddo da 55 cl).
      </p>
      <p><strong>&nbsp;</strong></p>
      <p><strong>ASSEGNAZIONE DEI PREMI</strong></p>
      <p>
        <strong><u>Entro il 31 maggio 2022</u></strong
        >, alla presenza di un notaio o di un funzionario camerale, verr&agrave;
        effettuata l&rsquo;assegnazione dei premi immediati consistenti in n. 61
        cene con Chef a domicilio Chefbooking.
      </p>

      <p>
        Il Notaio/funzionario camerale proceder&agrave; inoltre ad una
        <u>eventuale</u> <u>ESTRAZIONE DI RECUPERO </u>tra tutti coloro che
        hanno partecipato ma non sono risultati vincitori dei premi instant win.
      </p>
      <p>
        L&rsquo;estrazione di recupero avverr&agrave; solo nel caso in cui ci
        siano premi non attribuiti, per qualsiasi motivo. A titolo
        esemplificativo e non esaustivo: carenza di partecipanti, vincite
        doppie, non convalidate, incoerenza tra dati forniti all&rsquo;atto
        della partecipazione ed i dati spediti, minore et&agrave; del
        partecipante, ecc. Verranno quindi estratti, tra coloro che sono
        risultati non vincitori, gli eventuali premi non assegnati.
      </p>

      <p>
        ESTRATTI DI RISERVA: verranno inoltre estratti, dal tabulato con i dati
        dei partecipanti non risultati vincitori, n.70 vincitori di riserva. Le
        riserve verranno contattate in caso di irreperibilit&agrave;
        dell&rsquo;avente diritto oppure nel caso in cui il vincitore decadesse
        dal diritto al premio.&nbsp;
      </p>

      <p>Il Notaio/funzionario camerale avr&agrave; a disposizione:</p>
      <p>
        -un tabulato con l&rsquo;indicazione dei dati personali dei vincitori
        INSTANT WIN (nome, cognome, indirizzo completo, numero di telefono
        cellulare) e la data di avvenuta assegnazione del premio da parte del
        software e se hanno vinto con la modalit&agrave; WEB o WhatsApp&reg;;
      </p>
      <p>
        -un tabulato con i dati e la modalit&agrave; di partecipazione (WEB o
        WhatsApp&reg;) di coloro che hanno validamente partecipato ma non sono
        risultati vincitori di un premio instant win (per l&rsquo;estrazione
        eventuale di recupero e per l&rsquo;estrazione delle riserve).
      </p>

      <p>
        Ai fini dell&rsquo;estrazione di recupero e delle riserve ogni
        partecipante sar&agrave; presente nel tabulato cartaceo o elettronico
        tante volte quante saranno gli scontrini/ricevute di acquisto non
        vincenti giocati correttamente.
      </p>

      <p>
        <strong
          >CONVALIDA DELLA VINCITA (ESTRAZIONE EVENTUALE DI RECUPERO E
          RISERVE).</strong
        >
        Anche per i vincitori dei premi assegnati tramite estrazione di recupero
        o nel caso delle riserve verr&agrave; richiesto l&rsquo;invio dello
        scontrino/ricevuta di acquisto in originale per convalidare la vincita.
        I concorrenti estratti dovranno inviare in busta chiusa, tramite posta
        prioritaria, regolarmente affrancata (o lettera raccomandata)
        (far&agrave; fede la data riportata sulla busta di invio): entro 5
        giorni di calendario dal ricevimento della comunicazione di vincita:
      </p>
      <ul>
        <li>
          lo scontrino/ricevuta parlante attestante l&rsquo;acquisto
          <strong><u>in originale</u></strong> utilizzato per la partecipazione
          al concorso e risultato vincente. Non verranno tenute in
          considerazione le fotocopie o documenti alternativi allo
          scontrino/ricevuta in originale;
        </li>
        <li>
          la fotocopia della propria carta di identità fronte/retro in corso di
          validit&agrave;;
        </li>
        <li>il proprio indirizzo completo e numero di telefono cellulare</li>
      </ul>

      <p>a:</p>
      <p>
        CONCORSO &ldquo;VINCI UNA CENA CON BIRRA MORETTI FILTRATA A
        FREDDO&rdquo;
      </p>
      <p>C/O NOVATAG Srl</p>
      <p>Via Alessandro Volta 60 &ndash; 20047 &ndash; Cusago MI</p>

      <p>
        Verranno seguiti i medesimi criteri di convalida indicati al paragrafo
        &ldquo;CONVALIDA DELLA VINCITA PREMI INSTANT WIN&rdquo;.
      </p>

      <p><strong>CONSEGNA DEL PREMIO:</strong></p>
      <p>
        Il vincitore ricever&agrave; una email, all&rsquo;indirizzo da lui
        fornito all&rsquo;atto della sua partecipazione, con la quale
        potr&agrave; subito richiedere, sul sito che verr&agrave; indicato al
        vincitore, il premio - cena con Chef a domicilio Chefbooking - seguendo
        le indicazioni che gli verranno fornite. L&rsquo;email avr&agrave;
        funzione di documento di legittimazione per la fruizione del premio.
      </p>

      <p>
        Nel caso in cui il vincitore, edotto del premio, che ha ricevuto il
        documento di legittimazione non usufruisse del premio entro il termine
        indicato &ndash; per qualsiasi motivo &ndash; non avr&agrave; diritto a
        nessun rimborso o premio alternativo. In questo caso il premio si
        riterr&agrave; a tutti gli effetti consegnato.
      </p>

      <p>
        La Societ&agrave; promotrice non si assume alcuna responsabilit&agrave;
        nel caso in cui si verifichi una delle seguenti condizioni:
      </p>
      <ul>
        <li>la mailbox del vincitore risulti piena;</li>
        <li>la mailbox del vincitore risulti disabilitata;</li>
        <li>
          l&rsquo;e-mail indicata dal vincitore risulti inesistente, errata o
          incompleta;
        </li>
        <li>
          l&rsquo;e-mail indicata dal vincitore sia inserita in una blacklist;
        </li>
        <li>
          l&rsquo;email venga inserita nelle cartelle di posta indesiderata
          dell&rsquo;utente.
        </li>
      </ul>

      <p>
        Qualora l&rsquo;e-mail con il codice dovesse tornare al mittente per
        cause non imputabili al promotore, ad esempio per i motivi sopra
        esposti, il vincitore decadr&agrave; dal diritto al premio e
        verr&agrave; contattata la prima riserva disponibile.
      </p>

      <p>
        Il partecipante &egrave; l&rsquo;unico responsabile della gestione della
        propria casella di posta elettronica con particolare riferimento:
      </p>
      <ul>
        <li>alla presa visione della e-mail inviata dal Promotore;</li>
        <li>
          all&rsquo;adozione delle misure di sicurezza che impediscano ad altri
          soggetti dallo stesso non autorizzati di accedere alla propria casella
          di posta.
        </li>
      </ul>

      <p>
        <strong>SOFTWARE</strong>: E&rsquo; stata redatta, ai sensi
        dell&rsquo;art. 9, comma 1 del DPR 430/2001, una dichiarazione
        sostitutiva di atto notorio resa dal tecnico che ha realizzato il
        software relativa al corretto funzionamento del congegno.
      </p>

      <p>
        Le partecipazioni pervenute nelle modalit&agrave; WEB o WHATSAPP&reg;
        confluiranno nel medesimo database e concorreranno
        all&rsquo;assegnazione dei medesimi premi.
      </p>

      <p>
        Il tecnico dichiara di aver adottato ogni possibile tutela per garantire
        il rispetto della fede pubblica.
      </p>

      <p>Nella dichiarazione vengono indicate:</p>
      <p>-le specifiche del programma di assegnazione casuale dei premi;</p>
      <p>
        -l'adozione di opportuni accorgimenti per impedire interventi esterni
        per determinare le vincite;
      </p>
      <p>
        -l&rsquo;adozione delle misure di sicurezza necessarie e sufficienti a
        garantire la tutela della fede pubblica.
      </p>
      <p>
        L&rsquo;attribuzione dei premi immediati viene effettuata con criteri di
        assoluta casualit&agrave; da un apposito software che comprende un
        algoritmo che assegna, in forza di una formula matematica, i premi. Non
        &egrave; mai possibile determinare a priori quale sar&agrave; la
        partecipazione vincente e quella perdente; infatti, per garantire al
        partecipante che la vincita sia veramente casuale e non preordinata, il
        software sar&agrave; programmato per le vincite secondo le regole della
        totale casualit&agrave;.
      </p>

      <p>
        Ogni scontrino/ricevuta con data compresa tra il 01/3/2022 e il
        30/04/2022 permetter&agrave; una sola partecipazione e, una volta
        utilizzato, il software provveder&agrave; ad annullarlo impedendone il
        riutilizzo. Gli scontrini/ricevute dovranno&nbsp; avere&nbsp; una&nbsp;
        data&nbsp; e&nbsp; un&nbsp; orario&nbsp; di partecipazione&nbsp;
        compresi&nbsp; nel&nbsp; periodo promozionato.
      </p>
      <p>
        I consumatori&nbsp; potranno&nbsp; partecipare&nbsp; pi&ugrave;&nbsp;
        volte&nbsp; al&nbsp; concorso, utilizzando&nbsp; ogni&nbsp; volta
        scontrini/ricevute diversi, ma potranno vincere un solo premio per tutta
        la durata della manifestazione.
      </p>

      <p>
        Il costo sostenuto dal concorrente per la partecipazione corrisponde
        all&rsquo;ordinario costo al medesimo addebitato in base al contratto
        attivato con l&rsquo;operatore telefonico prescelto.
      </p>

      <p>
        La numerazione 3917054053 non &egrave; una numerazione premium a valore
        aggiunto.
      </p>

      <p>
        <strong
          ><u
            >Il software, i server e database sono ubicati sul territorio
            italiano presso Datacenter AWS situato a Milano</u
          ></strong
        >
      </p>
      <p><strong>&nbsp;</strong></p>
      <p>
        <strong>CAUZIONE:</strong> a garanzia dei premi la Societ&agrave;
        promotrice ha richiesto apposita fideiussione&nbsp; a favore del
        Ministero dello Sviluppo Economico, di importo pari al 100% del
        montepremi messo in palio.
      </p>

      <p>
        <strong>ONLUS :</strong> i premi non richiesti o non assegnati, diversi
        da quelli rifiutati, saranno devoluti alla seguente ONLUS Fondazione
        Opera San Francesco per i Poveri ONLUS - Viale Piave 2 &ndash; 20129
        Milano. C.F.: 97176630156.
      </p>

      <p><strong>PRECISAZIONI FINALI</strong></p>
      <p>
        La partecipazione alla presente manifestazione a premi comporta per il
        partecipante l&rsquo;accettazione incondizionata e totale delle regole e
        delle clausole contenute nel presente regolamento, senza limitazione
        alcuna.
      </p>

      <p>
        Il Promotore non sar&agrave; responsabile in alcun modo per qualsiasi
        genere di problematica che fosse imputabile a cause non dipendenti dal
        Promotore stesso quali forza maggiore (a titolo esemplificativo e non
        esaustivo: pandemie come il Covid-19).
      </p>
      <p>
        Nel caso in cui la Societ&agrave; Promotrice non potesse erogare il
        premio cos&igrave; come descritto per cause di forza maggiore a lei non
        imputabili o nel caso in cui il vincitore, ad esempio, non avesse lo
        spazio necessario per ospitare cinque persone e lo chef verr&agrave;
        consegnato al vincitore il seguente
        <strong><u>PREMIO ALTERNATIVO</u> </strong>di pari valore rispetto al
        premio originario posto in palio: un box contenente la spesa completa
        necessaria a comporre il men&ugrave; tra una lista di ricette
        predefinite, con il corso di cucina on demand da parte dello Chef di
        Chefbooking della durata di 3 ore circa. L&rsquo;evento potr&agrave;
        essere riservato con minimo 14 giorni di preavviso, salvo
        disponibilit&agrave; dello chef e del vincitore. Periodo di fruizione
        entro il 30 Novembre 2022. Per la consegna del premio alternativo
        verranno seguite le medesime modalit&agrave; e regole indicate nel
        paragrafo &ldquo;CONSEGNA DEL PREMIO&rdquo;.
      </p>

      <p>
        <strong
          >Tutti i partecipanti dovranno conservare lo scontrino/ricevuta di
          acquisto originale, attestante l&rsquo;acquisto dei prodotti
          promozionati, in quanto dovr&agrave; essere presentato in fase di
          convalida della vincita Instant Win e/o in caso di assegnazione del
          premio in sede di estrazione di recupero/riserve.
          <u
            >Si invitano pertanto tutti i partecipanti a conservare la prova di
            acquisto in originale per un periodo non inferiore a 180 giorni
            dalla data di verbalizzazione dei vincitori.</u
          ></strong
        >
      </p>

      <p>
        I browser minimi supportati sono: Edge 0.10, Firefox 22.0 e superiori,
        Chrome 28.0 e superiori,&nbsp;Safari 5.1 e superiori ed Opera 16.0 e
        superiori.
      </p>
      <p>
        La Societ&agrave; Promotrice non si assume responsabilit&agrave; per
        qualsiasi problema di accesso, impedimento, disfunzione o
        difficolt&agrave; riguardante gli strumenti tecnici, la linea telefonica
        o internet che possa impedire ad un utente di partecipare al concorso.
        La societ&agrave; promotrice non si assume responsabilit&agrave; per
        versioni di browser di navigazione obsolete o sistemi operativi di
        mobile obsoleti che dovessero impedire la partecipazione al concorso a
        premi.
      </p>

      <p>
        La partecipazione al concorso, prevede l&rsquo;utilizzo di telefono
        cellulare di un operatore italiano di telefonia mobile e l&rsquo;invio
        di messaggi WhatsApp&reg; da un numero &ldquo;in chiaro&rdquo;. Non
        &egrave; possibile partecipare con messaggi WhatsApp&reg; inviati
        tramite operatori di telefonia cellulare stranieri, via Internet o da
        telefono fisso. In ogni caso, eventuali partecipazioni effettuate
        tramite i succitati canali non saranno ritenute valide.
      </p>

      <p>
        La societ&agrave; promotrice non si assume responsabilit&agrave; per
        clausole presenti nel piano tariffario sottoscritto con il proprio
        gestore telefonico che possa impedire al consumatore di inviare il
        messaggio WhatsApp&reg; o di ricevere il messaggio WhatsApp&reg;
        corretto di risposta.
      </p>

      <p>
        Questa promozione non &egrave; in alcun modo sponsorizzata, appoggiata o
        amministrata da WhatsApp o associata a WhatsApp. Nessuna
        responsabilit&agrave; &egrave; imputabile a WhatsApp nei confronti dei
        partecipanti. Qualsiasi domanda, commento o lamentela sulla
        manifestazione a premi va rivolta alla Societ&agrave; Promotrice e non a
        WhatsApp.
      </p>

      <p>
        <strong
          ><u
            >Il vincitore ha diritto al premio e non al suo valore economico.</u
          ></strong
        >
      </p>

      <p>
        Le prove di acquisto sono ritenute personali per cui un utente non
        potr&agrave; partecipare con scontrini/ricevute attestanti
        l&rsquo;acquisto gi&agrave; caricati da altri utenti, che pertanto
        saranno ritenuti non validi ai fini della partecipazione.
      </p>

      <p>
        Lo scontrino utilizzato per questa manifestazione a premi non
        potr&agrave; essere nuovamente utilizzato per altre manifestazioni a
        premio promosse dalla Societ&agrave; Promotrice. Nel caso in cui il
        partecipante utilizzasse nuovamente, per un&rsquo;altra iniziativa a
        premi promossa da Heineken Italia S.p.A., la medesima prova di acquisto
        gi&agrave; indicata per questa iniziativa a premi non avr&agrave;
        diritto al premio e l&rsquo;eventuale vincita verr&agrave;, in ogni
        caso, annullata.
      </p>

      <p>
        La presente manifestazione a premi &egrave; rivolta esclusivamente a
        consumatori finali.
      </p>
      <p>
        Non verranno accettate fatture o ricevute di acquisto emesse verso una
        Partita IVA. Sono esclusi gli acquisti effettuati nell&rsquo;ambito di
        attivit&agrave; professionali o comunque diverse dal consumo privato.
      </p>
      <p>Sono esclusi gli acquisti effettuati su Amazon.</p>
      <p>
        <u
          >Sono inoltre esclusi gli acquisti effettuati presso strutture
          ristorative quali, a titolo esemplificativo e non esaustivo,
          ristoranti, bar, pizzerie, trattorie, ecc. o strutture ricettive;
          alberghi, pensioni, ecc.
        </u>
      </p>

      <p>
        La societ&agrave; promotrice si riserva il diritto di richiedere in ogni
        momento gli originali degli scontrini/ricevute.
      </p>

      <p>
        Gli scontrini non sono cedibili a terzi. Tale prassi &egrave; vietata
        categoricamente.
      </p>

      <p>
        E&rsquo; incombenza del vincitore effettuare, nei tempi indicati da
        questo regolamento, l&rsquo;invio della documentazione richiesta. La
        societ&agrave; promotrice non ha responsabilit&agrave; diretta o
        indiretta in ordine a ogni negativo accadimento che non consentisse alla
        documentazione trasmessa dal concorrente vincitore di giungere nei tempi
        precisati.
      </p>
      <p>
        La societ&agrave; promotrice non si assume responsabilit&agrave; in
        merito alle buste non pervenute per eventuali disguidi postali o cause
        di qualunque altro genere. l vincitore avr&agrave; la facolt&agrave; di
        scegliere il servizio postale che preferisce per effettuare la
        spedizione della&nbsp; documentazione&nbsp; richiesta. Per una maggior
        garanzia e per avere una tracciabilit&agrave;&nbsp; della spedizione si
        consiglia di effettuare la spedizione con raccomandata A.R. Il&nbsp;
        ricevimento&nbsp; della&nbsp; documentazione&nbsp; indicata&nbsp;
        &egrave;&nbsp; condizione&nbsp; necessaria&nbsp; per&nbsp; la
        convalida&nbsp; della&nbsp; vincita; la Societ&agrave; Promotrice non si
        assume alcuna responsabilit&agrave; per la mancata ricezione di
        partecipazioni dovute all&rsquo;invio di buste attraverso servizi di
        Posta di cui non &egrave; tracciabile la consegna.
      </p>

      <p>
        La Ditta Promotrice non si assume inoltre alcuna responsabilit&agrave;
        in caso di mancato recapito dell&rsquo;avviso di vincita o del premio
        dovuto all&rsquo;indicazione, da parte del concorrente, di dati non
        corretti, non veritieri, o di indirizzi/numeri non pi&ugrave; attivi.
      </p>

      <p>
        Ai fini del concorso sar&agrave; ammessa un&rsquo;unica registrazione
        per ciascun partecipante. Il Promotore si riserva di verificare e
        annullare le eventuali registrazioni aggiuntive del medesimo
        partecipante.
      </p>
      <p>
        La Ditta Promotrice si riserva di impedire la partecipazione o annullare
        la vincita a tutti gli utenti che non parteciperanno in buona fede
        (doppie identit&agrave;, registrazioni multiple, utilizzo di sistemi di
        gioco automatizzati etc.). Al fine di evitare&nbsp; comportamenti&nbsp;
        fraudolenti&nbsp; da&nbsp; parte&nbsp; dei&nbsp; concorrenti,&nbsp;
        quali&nbsp; a titolo&nbsp;&nbsp; esemplificativo&nbsp;&nbsp;
        e&nbsp;&nbsp; non&nbsp;&nbsp; esaustivo,&nbsp;&nbsp;
        utilizzo&nbsp;&nbsp; di&nbsp;&nbsp; scontrini&nbsp;&nbsp;
        fasulli,&nbsp;&nbsp; caricamento&nbsp; di&nbsp; scontrini&nbsp;
        senza&nbsp; acquisto&nbsp; dei prodotti&nbsp; oggetto&nbsp; della&nbsp;
        promozione, ecc.&nbsp; la Societ&agrave; Promotrice (anche tramite un
        suo delegato) si riserva la facolt&agrave; di bloccare la partecipazione
        a tali utenti e di segnalare gli stessi alle autorit&agrave; competenti
        senza procedere alla validazione delle partecipazioni relative ed al
        conseguente invio dei premi.
      </p>
      <p>
        Qualora la documentazione richiesta dovesse risultare incompleta,
        illeggibile e/o contraffatta, verr&agrave; ritenuta non valida ai fini
        della partecipazione al concorso.
      </p>

      <p>
        La societ&agrave; promotrice ha facolt&agrave; di verificare la
        veridicit&agrave; degli scontrini inviati dai consumatori presso gli
        esercizi che li hanno emessi.
      </p>

      <p>
        La Ditta Promotrice, o le societ&agrave; incaricate dalla stessa alla
        gestione del concorso, si riservano il diritto di procedere, nei termini
        giudicati pi&ugrave; opportuni, e nel rispetto delle leggi vigenti, per
        limitare ed inibire ogni iniziativa volta ad aggirare il sistema ideato.
      </p>

      <p>
        Heineken Italia S.p.A. si riserva, in caso di indisponibilit&agrave; -
        sopravvenuta e alla stessa non imputabile - dei premi di sostituirli con
        altri di pari o maggior valore.
      </p>

      <p>
        I premi non sono convertibili in denaro n&eacute; &egrave; data alcuna
        facolt&agrave; ai vincitori di richiedere, con o senza l&rsquo;aggiunta
        di somme in denaro, la possibilit&agrave; di ricevere un premio diverso.
      </p>

      <p>
        Per questo concorso si rinuncia ai diritti di rivalsa di cui
        all&rsquo;art. 30 D.P.R. 29/9/73 n. 600.
      </p>

      <p>
        Il regolamento completo sar&agrave; reperibile sul sito
        www.birramoretti.it e anche sul sito dedicato alla promozione
        birramoretti.it/vinciunacena
      </p>
      <p><strong>&nbsp;</strong></p>
      <p><strong>TRATTAMENTO DEI DATI &ndash;</strong></p>
      <p>
        Ai sensi dell&rsquo;art. 13 del Regolamento UE 679/2016
        (&ldquo;GDPR&rdquo;) il trattamento dei dati personali verr&agrave;
        effettuato ai fini di:
      </p>
      <p>
        - consentire all&rsquo;interessato di partecipare alla presente
        iniziativa;
      </p>
      <p>
        - inviare comunicazioni pubblicitarie per finalit&agrave; di marketing e
        marketing profilato, iniziative promozionali di vendita, realizzate
        mediante modalit&agrave; automatizzate di contatto (posta elettronica,
        invio di newsletter periodica, sms, MMS, chat, instant messaging, social
        network e altri strumenti di messaggistica massiva, notifiche push,
        ecc.) e modalit&agrave; tradizionali di contatto (ad esempio, telefonata
        con operatore, posta tradizionale, ecc.), ovvero per ricerche di mercato
        o indagini statistiche, creando un profilo individuale in linea con
        preferenze, analisi delle abitudini e scelte di acquisto.
      </p>
      <p>
        Il trattamento dei dati ai fini della partecipazione alla presente
        iniziativa non richiede il consenso; il consenso facoltativo &egrave;
        invece necessario per ricevere comunicazioni con finalit&agrave; di
        marketing e marketing profilato per fornire offerte promozionali. Il
        mancato conferimento dei dati personali implicher&agrave;
        l&rsquo;impossibilit&agrave; di partecipare alla presente iniziativa,
        mentre il mancato conferimento del consenso per attivit&agrave; di
        marketing non comporta conseguenze sulla partecipazione alla presente
        iniziativa.
      </p>
      <p>
        I dati potranno essere comunicati al personale di HEINEKEN ITALIA S.p.A.
        appositamente incaricato, a societ&agrave; delegate e/o nominate
        responsabili e coinvolte nella gestione dell&rsquo;iniziativa,
        nonch&eacute; ad altri soggetti terzi incaricati dai titolari della
        fornitura dei servizi strumentali o comunque necessari alla gestione
        dell&rsquo;iniziativa. I dati personali, previo rilascio di apposito
        consenso, potranno altres&igrave; essere trattati e condivisi con
        Heineken BV, Beerwulf e altre societ&agrave; del gruppo Heineken per
        finalit&agrave; di marketing e di attivit&agrave; promozionale con le
        modalit&agrave; automatizzate di cui all&acute;art. 130, comma 1 e 2,
        D.lgs. 101/2018 senza dover acquisire nuovamente il consenso
        dell&rsquo;interessato.
      </p>
      <p>
        Titolare del trattamento &egrave; HEINEKEN ITALIA S.p.A., societ&agrave;
        unipersonale, soggetta a direzione e coordinamento di Heineken N.V. ai
        sensi dell&rsquo;art. 2497 bis del codice civile, con sede legale a
        Pollein (AO), Loc. Autoporto n. 11 e sede amministrativa a Sesto San
        Giovanni (MI), Viale Edison, 110. Il Responsabile della protezione dei
        dati (&ldquo;DPO&rdquo;) che pu&ograve; essere contattato al seguente
        indirizzo di posta elettronica: dpo@heinekenitalia.it.
      </p>
      <p>
        Responsabili del trattamento sono NOVATAG S.r.l., Via A. Volta, 60
        &ndash; 20047 Cusago (MI) e CHEFBOOKING S.r.l.s., Piaz. Giov. dalle
        bande nere, 9 &ndash; 20146 Milano.
      </p>
      <p>
        I partecipanti alla presente iniziativa hanno diritto in qualunque
        momento di ottenere la conferma dell&rsquo;esistenza o meno dei dati e
        di conoscerne il contenuto e l&rsquo;origine, verificarne
        l&rsquo;esattezza o chiederne l&rsquo;integrazione o
        l&rsquo;aggiornamento, oppure la rettificazione o la cancellazione degli
        stessi o la limitazione del trattamento dei dati che li riguardano o di
        opporsi al loro trattamento, oltre al diritto alla portabilit&agrave;
        dei dati, scrivendo una email all&rsquo;indirizzo
        privacy@heinekenitalia.it. Avranno, inoltre, il diritto di revocare in
        ogni momento il consenso prestato al trattamento dei loro dati per
        l&rsquo;invio di comunicazioni con finalit&agrave; di marketing e
        marketing profilato e di proporre reclamo al Garante Privacy. I dati
        personali raccolti per la partecipazione alla presente iniziativa
        saranno conservati per il tempo necessario a gestire gli adempimenti
        connessi alla realizzazione dell&rsquo;iniziativa e comunque per un
        tempo non superiore a sei mesi dalla realizzazione dell&rsquo;iniziativa
        stessa, mentre i dati personali trattati per finalit&agrave; di
        marketing potranno essere conservati per 24 mesi dalla raccolta, per
        finalit&agrave; di marketing profilato per 12 mesi dalla raccolta.
      </p>
      <p><strong>&nbsp;</strong></p>
      <p>
        <strong
          >Per qualsiasi informazione o chiarimento in merito
          all&rsquo;attivit&agrave; o spedizione del premio il consumatore
          dovr&agrave; contattare la societ&agrave; NOVATAG S.r.l. al numero 02
          94 7575 60 attivo tutti i giorni&nbsp; dalle 9:00 alle 13:00 e dalle
          14:00 alle 18:00, (festivi esclusi) oppure dovr&agrave; scrivere
          all&rsquo;indirizzo di posta elettronica </strong
        ><strong>vinciunacena@birramoretti.it</strong>
      </p>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
import axios from "axios";
import {
  USER_REQUEST,
  ACCEPT_RELAY_COOKIES,
  ACCEPT_SALESFORCE_COOKIES,
} from "actions/user";
import { mapGetters } from "vuex";

export default {
  name: "informativaPrivacy",
  data() {
    return {
      loadingState: "",
      userToken: "",
    };
  },
  methods: {
    scrollToTop: function () {
      var _vue = this;
      setTimeout(function () {
        _vue.$scrollTo(".main", 700, { offset: -220 });
      }, 100);
    },
  },
  watch: {},
  mounted: function () {
    this.scrollToTop();
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "acceptCookies",
      "checkAge",
      "acceptSalesForce",
      "acceptRelay42",
    ]),
  },
};
</script>


