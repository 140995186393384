<template>
  <div>
    <NavMenuLeft v-if="navType == 'MENU_LEFT_LOGO'" />
    <NavMenuCentral v-if="navType == 'MENU_CENTRAL_LOGO'" />
    <NavLogo v-if="navType == 'NO_MENU'" />
  </div>
</template>

<script>
import NavLogo from "./nav-logo.vue";
import NavMenuLeft from "./nav-menu-left.vue";
import NavMenuCentral from "./nav-menu-central.vue";
import { sharedEnvMixin } from "../../mixins/sharedEnvMixin";

export default {
  name: "navigation",
  mixins: [sharedEnvMixin],
  components: {
    NavLogo,
    NavMenuLeft,
    NavMenuCentral,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
