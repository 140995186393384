<template>
  <span :class="statusPlay.class" v-if="!practice">{{
    statusPlay.status
  }}</span>

  <div v-else>
    <h5 class="Regular color-2">
      Pratica: <b class="color-7"> {{ practice }} </b>
    </h5>
    <h5 class="Regular color-2">
      Stato: <b class="color-7"> {{ statusPlay.status }}</b>
    </h5>
  </div>
</template>

<script>
export default {
  name: "receipt-status",
  props: ["practice", "validation", "result"],
  components: {},
  data() {
    return {};
  },
  methods: {},
  watch: {},
  computed: {
    statusPlay() {
      if ([400, 403, 404, 405].includes(this.result)) {
        return { status: "Non vincente", class: "red" };
      }

      switch (this.validation) {
        case 0:
          return { status: "In fase di convalida", class: "text-warning" };
          break;
        case 1:
          return { status: "Convalidata", class: "text-success" };
          break;
        case 2:
          return { status: "Non è stata convalidata", class: "red" };
          break;
        case 3:
          return { status: "Errore dati", class: "red" };
          break;
        case 4:
          return { status: "Errore immagine", class: "red" };
          break;

        default:
          return true;
          break;
      }
    },
  },
  mounted() {},
};
</script>

<style>
</style>