<template>
	<div class="widthFull container-fluid section02 customBg" id="award">
		<div class="row justify-content-center mb-3">
			<div class="col-lg-6 col-md-6 col-10 back-image center Bold white h4 pt-2 pb-2">
				IN PALIO FANTASTICI PREMI
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="col-md-4 col-6 center">
				<img src="../../assets/images/borsa.png" class="img img-fluid"
			/></div>
			<div class="col-md-4 col-6 center">
				<img src="../../assets/images/dubai.png" class="img img-fluid"
			/></div>
			<div class="col-10 center mt-4 pb-5">
				<h3 class="color-1 Bold"
					><a :href="regolamentoPolicy" target="_blank" class="color-1 text-decoration-none"
						>REGOLAMENTO</a
					></h3
				>
				<SEPARATOR />
				<div class="ml-auto" :class="{ 'title-img': vw >= 998 }">
					<div class="pt-2 pb-md-5 text-center">
						<button-custom
							v-if="timeStore < moment(finish)"
							:to="!isAuthenticated ? '/login' : '/play'"
							:scrollTo="'#section01'"
						>
						</button-custom>

						<div v-else class="Bold color-1">Il concorso è terminato</div>
					</div>
				</div>
				<small class="color-1 Particular"
					>*A scelta tra i seguenti formati: Peroni Nastro Azzurro 3x33cl; Peroni Nastro Azzurro
					66cl; Peroni Nastro Azzurro 6x66cl; Peroni Nastro Azzurro 50cl; Peroni Nastro Azzurro Mais
					Nostrano 3x33cl; Peroni Nastro Azzurro Mais Nostrano 66cl (Bottiglia singola); Peroni
					Nastro Azzurro 0.0 3x33cl; Peroni Nastro Azzurro 0.0 33cl (Bottiglia singola); Peroni
					Nastro Azzurro Sardegna 3x33cl; Peroni Nastro Azzurro Sardegna 66cl (Bottiglia singola);
					Peroni Nastro Azzurro lattina singola da 50cl (lattina singola); Peroni Nastro Azzurro
					2x33cl (confezione da 2 lattine da 33cl ciascuna); “Vivi ogni Momento” è un concorso a
					premi, riservato ai maggiori di 18 anni, valido dal 01/07/2022 al 2/10/2022, tutti i
					giorni dalle ore 12:00 alle ore 20:00. Estrazione finale entro il 31/10/2022. Montepremi
					totale: € 107.760,00 (IVA esclusa). Scopri come partecipare e consulta il regolamento
					completo sul sito vinciilgusto.nastroazzurro.it</small
				>
			</div></div
		>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	import SEPARATOR from './separator'
	import ButtonCustom from '../lib/button'
	const moment = require('moment')

	import { sharedEnvMixin } from '../../mixins/sharedEnvMixin'
	import { innerWidthMixin } from '../../mixins/innerWidthMixin'
	import NavLogoVue from '../00-navigation/nav-logo.vue'

	export default {
		name: 'award',
		mixins: [sharedEnvMixin, innerWidthMixin],
		data() {
			return {
				oldPresent: false,
				moment: moment
			}
		},
		methods: {},
		mounted: function () {},
		computed: {
			...mapGetters(['isAuthenticated', 'authStatus', 'getPromotions', 'getOldPromotions']),
			timeStore() {
				return this.$timestore.now
			}
		},
		components: { NavLogoVue, SEPARATOR, ButtonCustom }
	}
</script>
