<template>
	<div class="widthFull min-height-calc--footer customBg" id="accountH">
		<div class="container-fluid mt-3">
			<div class="row text-center">
				<h3 class="col-12 mt-5 pt-4 pb-3 center Bold pb-2 color-1 uppercase"> I tuoi dati </h3>
			</div>
		</div>

		<div class="container">
			<loading v-if="loadingState" />

			<form class="" id="edit-user-form" @submit.prevent="updateUser">
				<div class="row justify-content-center">
					<div class="col-md-6 col-lg-4 mb-3">
						<div class="group">
							<input
								type="text"
								class="form-control"
								v-model="first_name_player"
								:disabled="!editEnable"
								id="first_name_player"
								name="first_name_player"
								data-vv-as="Nome"
								v-validate="{ required: true, max: 128, min: 2 }"
								:class="{
									'is-invalid': errors.has('first_name_player'),
									valid: !!first_name_player
								}"
							/>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label class="niceInputLabel" for="first_name_player">Nome</label>
							<div v-if="errors.has('first_name_player')" class="invalid-feedback">
								{{ errors.first('first_name_player') }}
							</div>
						</div>
					</div>

					<div class="col-md-6 col-lg-4 mb-3">
						<div class="group">
							<input
								type="text"
								class="form-control ds"
								v-model="last_name_player"
								:disabled="!editEnable"
								id="last_name_player"
								name="last_name_player"
								data-vv-as="Cognome"
								v-validate="{ required: true, max: 128, min: 2 }"
								:class="{
									'is-invalid': errors.has('last_name_player'),
									valid: !!last_name_player
								}"
							/>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label class="niceInputLabel" for="last_name_player">Cognome</label>

							<div v-if="errors.has('last_name_player')" class="invalid-feedback">
								{{ errors.first('last_name_player') }}
							</div>
						</div>
					</div>
				</div>

				<div class="row justify-content-center">
					<div class="col-md-6 col-lg-4 mb-3">
						<div class="group">
							<input
								type="text"
								v-model="email_player"
								class="form-control ds"
								id="email_player"
								name="email_player"
								disabled
								data-vv-as="Email"
								v-validate="{
									required: true,
									email: true,
									temporaryEmail: true,
									plusIssueEmail: true,
									max: 56
								}"
								:class="{
									'is-invalid': errors.has('email_player') || existEmail,
									valid: !!email_player
								}"
							/>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label class="niceInputLabel white" for="email_player">Email</label>

							<div v-if="existEmail" class="invalid-feedback" style="display: block">
								<span v-if="existEmailSocial"
									>Email digitato già presente. Sei registrato con:
									<strong class="text-capitalize">{{ existEmailSocial }}</strong></span
								>
								<span v-else>Email digitato già presente</span>
							</div>
							<div v-if="errors.has('email_player')" class="invalid-feedback">
								{{ errors.first('email_player') }}
							</div>
						</div>
					</div>

					<div v-if="phone" class="col-md-6 col-lg-4 mb-3">
						<div class="group">
							<input
								type="text"
								class="form-control ds"
								v-model="phone"
								:disabled="!editEnable"
								id="phone"
								name="phone"
								data-vv-as="Telefono"
								v-validate="{ required: true, max: 14 }"
								:class="{ 'is-invalid': errors.has('phone'), valid: !!phone }"
							/>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label class="niceInputLabel" for="phone">Telefono</label>
							<div v-if="errors.has('phone')" class="invalid-feedback">
								{{ errors.first('phone') }}
							</div>
						</div>
					</div>
				</div>

				<div v-if="addressData" class="row justify-content-center">
					<div class="col-md-4 mb-3">
						<div class="group">
							<input
								type="text"
								class="form-control"
								v-model="address"
								id="address"
								name="address"
								data-vv-as="Indirizzo"
								v-validate="{ max: 128 }"
								:class="{
									'is-invalid': errors.has('address'),
									valid: !!address
								}"
							/>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label class="niceInputLabel" for="address">Indirizzo</label>
							<div v-if="errors.has('address')" class="invalid-feedback">
								{{ errors.first('address') }}
							</div>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="group">
							<input
								type="text"
								class="form-control"
								v-model="civic"
								id="civic"
								name="civic"
								data-vv-as="Civico"
								v-validate="{ max: 7 }"
								:class="{ 'is-invalid': errors.has('civic'), valid: !!civic }"
							/>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label class="niceInputLabel" for="civic">Civico</label>
							<div v-if="errors.has('civic')" class="invalid-feedback">
								{{ errors.first('civic') }}
							</div>
						</div>
					</div>
				</div>

				<div v-if="addressData" class="form-row">
					<div class="col-md-4 mb-3">
						<div class="group">
							<select
								v-model="province"
								id="province"
								name="province"
								class="form-control"
								:class="{
									'is-invalid': errors.has('province'),
									valid: !!province
								}"
							>
								<option disabled value="" selected="selected"></option>
								<option
									v-for="province in provinces"
									:key="province.sigla"
									v-bind:value="province.sigla"
								>
									{{ province.sigla }} - {{ province.provincia }}
								</option>
							</select>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label class="niceInputLabel" for="province">Provincia</label>
							<div v-if="provincesState">
								<font-awesome-icon
									class="loadSpinner"
									id="loadProvince"
									icon="spinner"
									size="1x"
									pulse
								/>
							</div>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="group">
							<select
								class="form-control"
								v-model="city"
								v-on:click="checkProvince"
								id="city"
								name="city"
								:disabled="citiesState"
								:class="{ 'is-invalid': errors.has('city'), valid: !!city }"
							>
								<option disabled value="" selected="selected"></option>
								<option
									v-for="city in cities"
									:key="city.comune"
									v-bind:value="{ cityistat: city.istat, city: city.comune }"
								>
									{{ city.comune }}
								</option>
							</select>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label class="niceInputLabel" for="city"
								>Località
								<span v-if="cityError" class="formError">Prima scegli Provincia</span></label
							>
							<div v-if="citiesState">
								<font-awesome-icon
									class="loadSpinner"
									id="loadCity"
									icon="spinner"
									size="1x"
									pulse
								/>
							</div>
						</div>
					</div>
					<div class="col-md-4 mb-3">
						<div class="group">
							<select
								class="form-control"
								v-model="zip"
								v-on:click="checkZip"
								id="zip"
								name="zip"
								:disabled="zipsState"
								:class="{ 'is-invalid': errors.has('zip'), valid: !!zip }"
							>
								<option value="" disabled :selected="true"></option>
								<option v-for="zip in zips" :key="zip.cap" v-bind:value="zip.cap">
									{{ zip.cap }}
								</option>
							</select>
							<span class="highlight"></span>
							<span class="bar"></span>
							<label class="niceInputLabel" for="zip"
								>Cap
								<span v-if="zipError" class="formError">Prima scegli Comune</span>
							</label>
							<div v-if="zipsState">
								<font-awesome-icon
									class="loadSpinner"
									id="loadZips"
									icon="spinner"
									size="1x"
									pulse
								/>
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-12 center badge badge-danger" v-if="editStatus == 'error'">
					Errore connessione
				</div>

				<div class="col-md-12 pb-5 pt-4 center" v-if="editEnable">
					<button class="btn btn-primary uppercase">Modifica dati</button>
				</div>

				<div v-if="moment(finish) > timeStore" class="col-md-12 pb-5 pt-4 center">
					<router-link tag="button" class="btn btn-primary uppercase" to="/play"
						>PARTECIPA
					</router-link>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'
	import { USER_REQUEST } from 'actions/user'
	import { locationMixin } from '../../mixins/locationMixin'
	import { sharedEnvMixin } from '../../mixins/sharedEnvMixin'
	import { playFunctionMixin } from '../../mixins/playFunctionMixin'
	import { axiosMixin } from '../../mixins/axiosMixin'
	const moment = require('moment')

	export default {
		name: 'account',
		components: {},
		mixins: [locationMixin, sharedEnvMixin, axiosMixin, playFunctionMixin],
		data() {
			return {
				email_player: '',
				first_name_player: '',
				last_name_player: '',
				address: '',
				civic: '',
				zip: '',
				txt1: '',
				phone: '',
				agreement1_player: '',
				agreement1_player: '',
				agreement1_player: '',
				agreement1_player: '',
				cities: [],
				citiesState: false,
				zips: [],
				zipsState: false,
				tempMail: '',
				existEmail: false,
				existEmailSocial: false,
				loadingState: false,
				editStatus: false,
				userToken: '',
				addressData: false,
				editEnable: false,
				moment: require('moment')
			}
		},
		methods: {
			async updateUser() {
				this.$validator.validate().then((valid) => {
					if (valid && !this.existEmail) {
						this.editUser()
					}
				})
			},
			showAlertError(value) {
				// Use sweetalret2
				this.$swal({
					type: 'error',
					title: 'Oops...',
					text: value + ' !'
				})
			},
			showAlertSuccess(value) {
				// Use sweetalret2
				this.$swal({
					type: 'success',
					title: value,
					timer: 4500
				})
			},

			setProfileData: function () {
				this.email_player = this.profile.email_player
				this.first_name_player = this.profile.first_name_player
				this.last_name_player = this.profile.last_name_player
				this.userToken = this.profile.player_token_player
				this.txt1 = this.profile.txt1_player
				this.phone = this.profile.phone_player.substring(2)

				this.address = this.profile.address_player
				this.civic = this.profile.civic_number_player
				this.city = this.profile.city_player
				this.province = this.profile.province_player
				this.zip = this.profile.zip_player

				this.agreement1_player = this.profile.agreement1_player
				if (this.profile.agreement2_player == 1) {
					this.agreement2_player = 1
				} else {
					this.agreement2_player = 0
				}
				if (this.profile.agreement3_player == 1) {
					this.agreement3_player = 1
				} else {
					this.agreement3_player = 0
				}
				if (this.profile.agreement4_player == 1) {
					this.agreement4_player = 1
				} else {
					this.agreement4_player = 0
				}
			}
		},

		mounted: function () {
			this.populateProvinces()

			var _vue = this
			_vue.loadingState = true
			setTimeout(function () {
				_vue.setProfileData((_vue.loadingState = false))
			}, 1500)
		},

		computed: {
			...mapState({ profile: (state) => state.user.profile }),
			timeStore() {
				return this.$timestore.now
			}
		}
	}
</script>
