 <template>
  <div class="widthFull relative customBg--repeat min-height-calc--footer">
    <!-- <div class="row justify-content-center">

			<div class="col-12 ">
				<div class="row  center notActiveTopBg">

				</div>
			</div>

		</div> -->

    <div class="row justify-content-center">
      <h3 class="Bold py-2 color-2 pt-5">Torna a trovarci tra</h3>

      <div class="col-md-12 pb-2 pt-2 center">
        <countdown :time="countdown">
          <template slot-scope="props">
            <div class="row center justify-content-center pb-5 pt-1">
              <div class="inlineTimeElements mx-1 mx-md-4 text-center relative">
                <div class="timeElement darkBlue BoldFont white">
                  {{ props.days }}
                </div>
                <p v-if="props.days == 1" class="Regular color-2 mt-2 mb-0">
                  Giorno
                </p>
                <p v-else class="Regular color-2 mt-2 mb-0">Giorni</p>
              </div>

              <div class="inlineTimeElements mx-1 mx-md-4 text-center relative">
                <div class="timeElement darkBlue BoldFont white">
                  {{ props.hours }}
                </div>
                <p v-if="props.hours == 1" class="Regular color-2 mt-2 mb-0">
                  Ora
                </p>
                <p v-else class="Regular color-2 mt-2 mb-0">Ore</p>
              </div>

              <div class="inlineTimeElements mx-1 mx-md-4 text-center relative">
                <div class="timeElement darkBlue BoldFont white">
                  {{ props.minutes }}
                </div>
                <p v-if="props.minutes == 1" class="Regular color-2 mt-2 mb-0">
                  Minuto
                </p>
                <p v-else class="Regular color-2 mt-2 mb-0">Minuti</p>
              </div>

              <div class="inlineTimeElements mx-1 mx-md-4 text-center relative">
                <div class="timeElement darkBlue BoldFont white">
                  {{ props.seconds }}
                </div>
                <p v-if="props.seconds == 1" class="Regular color-2 mt-2 mb-0">
                  Secondo
                </p>
                <p v-else class="Regular color-2 mt-2 mb-0">Secondi</p>
              </div>
            </div>
          </template>
        </countdown>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require("moment");

import { sharedEnvMixin } from "../../mixins/sharedEnvMixin";

export default {
  mixins: [sharedEnvMixin],
  name: "NotActive",
  data() {
    return {
      time: false,
      countdown: 0,
      datenow: "",
    };
  },
  methods: {
    timeReactive() {
      let self = this;
      if (this.timeStore >= moment(process.env.VUE_APP_START)) {
        window.location.replace("/");
      }
    },
  },
  created: function () {
    setInterval(this.timeReactive, 1000);
    let now = this.timeStore;
    let then = moment(process.env.VUE_APP_START);
    let dif = then.diff(now);
    this.countdown = dif;
  },
  mounted() {},
  computed: {
    timeStore() {
      return this.$timestore.now;
    },
  },
};
</script>


