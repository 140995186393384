<template>
	<div id="app">
		<div class="main">
			<navigation />
			<HEAD v-if="$route.path != '/' && $route.path != '/check-age'" />
			<div class="main-container">
				<router-view />
			</div>
		</div>
		<custom-footer />
	</div>
</template>

<style lang="css" scoped>
	/* @import '~bootstrap/dist/css/bootstrap.min.css'; */
	/* @import './assets/css/main.css'; */
</style>

<script>
	import Navigation from 'components/00-navigation'
	import HEAD from './components/home/header-image'
	import { USER_REQUEST } from 'actions/user'
	import { POPUP_REQUEST } from 'actions/user'
	import CustomFooter from './components/01-footer/index.vue'
	import './assets/scss/main.scss'

	export default {
		components: {
			CustomFooter,
			Navigation,
			HEAD
			//loading,
		},
		data() {
			return {
				metaTitle: 'Aperitivo con Aperol',
				metaDesc: 'In palio fantastici premi!',
				metaImg: '',
				popUpCheckAge: process.env.VUE_APP_HAS_CHECKAGE_POPUP == 'ON' ? true : false
			}
		},
		name: 'app',
		created: function () {
			if (this.$store.getters.getPopup.status == false) {
				this.$track('getPopup', 'popup Loading')
				console.log(this.popUpCheckAge)
				this.$store.dispatch(POPUP_REQUEST)
			} else {
				this.$track('getPopup', 'popup already Load')
			}

			if (this.$store.getters.isAuthenticated) {
				this.$store.dispatch(USER_REQUEST)
			}
		},
		watch: {
			$route: function () {}
		}
	}
</script>
