const moment = require('moment')

export const toDateMixin = {
	data() {
		return {}
	},

	methods: {
		calcToDateAward() {
			var start = moment(process.env.VUE_APP_START_DATE)
			var date = new Date(new Date().setDate(new Date().getDate() + 14))

			var startDate = new Date(start.format('YYYY'), start.format('M') - 1, start.format('D'))
			if (startDate > date) {
				var finalTo = startDate
			} else {
				var finalTo = date
			}
			return finalTo
		},
		calcFromDateAward() {
			var stopObj = moment('2022-12-01 23:59:59')
			var stopDate = new Date(stopObj.format('YYYY'), stopObj.format('M') - 1, stopObj.format('D'))

			return stopDate
		},

		// DATA INIZIO VALIDITà SCONTRINO
		calcToDate() {
			let start = moment(process.env.VUE_APP_START_DATE)
			let date = new Date(
				new Date().setDate(new Date().getDate() - process.env.VUE_APP_MAX_NUM_DAYS)
			)
			let startDate = new Date(start.format('YYYY'), start.format('M') - 1, start.format('D'))
			let finalTo
			if (startDate > date) {
				finalTo = startDate
			} else {
				if (this.maxDays) finalTo = date
				else finalTo = startDate
			}
			return finalTo
		},

		// DATA FINE VALIDITà SCONTRINO
		calcFromDate() {
			let nowDate = new Date()
			let stopDate = new Date(process.env.VUE_APP_STOP_DATE)
			let finalFrom
			if (nowDate <= stopDate) {
				finalFrom = nowDate
			} else {
				finalFrom = stopDate
			}
			return finalFrom
		},

		// CHECK 18 ANNI
		calcToDate18() {
			return new Date(moment().subtract(18, 'years'))
		}
	}
}
