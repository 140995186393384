export const USER_REQUEST = 'USER_REQUEST'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_ERROR = 'USER_ERROR'
export const USER_UPDATE_DATA = 'USER_UPDATE_DATA'
export const USER_SET_DEFAULT_SHIP_DATA = 'USER_SET_DEFAULT_SHIP_DATA'
export const USER_CHECK_AGE = 'USER_CHECK_AGE'
export const ACCEPT_COOKIES = 'ACCEPT_COOKIES'


export const POPUP_REQUEST = 'POPUP_REQUEST'
export const POPUP_SUCCESS = 'POPUP_SUCCESS'
