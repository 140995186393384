import axios from 'axios';
import {
	axiosMixin
} from "./axiosMixin.js";

export const checkUniqueMixin = {

	data() {
		return {
			existEmail: false,
			tempMail: '',
			existEmailSocial: false,
			existPhone: false,
			tempPhone: '',
			existIban: false,
			tempIban: '',
			checkAllIban: ''
		};
	},


	methods: {
		async checkAll(table, field, value) {
			let data = {
				param: value,
				table: table,
				fieldName: field,
				all: 1,
			};

			let r = await axiosMixin.methods.axiosCall('api/checkIfIsUnique', data, 'POST');
			let message = r.data.message;

			if (r.status < process.env.VUE_APP_CATCH) { // then 
				this.checkAllIban = message
			} else { // catch
				this.checkAllIban = null
			}
		},

		async checkIfExistEmail(value) {
			const newValue = value.target.value;

			const field = this.$validator.fields.find({
				name: 'email'
			});

			if (!this.errors.has('email') && this.tempMail != newValue) {
				this.tempMail = newValue;
				let data = {
					param: newValue,
					table: 'player',
					fieldName: 'username_player',
				};

				let r = await axiosMixin.methods.axiosCall('api/checkIfIsUnique', data, 'POST');

				try {
					if (r.status < parseInt(process.env.VUE_APP_CATCH)) { // then 
						this.existEmail = false;
					} else { // catch
						this.existEmail = true;
					}
				} catch (error) { }
			}
		},


		async checkIfExistPhone(value) {
			const newValue = '39' + value.target.value;
			const field = this.$validator.fields.find({
				name: 'phone'
			});

			if (!this.errors.has('phone') && this.tempPhone != newValue) {
				this.tempPhone = newValue;
				var data = {
					param: newValue,
					table: 'player',
					fieldName: 'phone_player',
				};
				let r = await axiosMixin.methods.axiosCall('api/checkIfIsUnique', data, 'POST');

				try {
					if (r.status < parseInt(process.env.VUE_APP_CATCH)) { // then 
						this.existPhone = false;
					} else { // catch
						this.existPhone = true;
					}
				} catch (error) { }
			}
		},


		async checkIfIban(value) {
			const newValue = value.target.value;
			const field = this.$validator.fields.find({
				name: 'cf'
			});
			if (!this.errors.has('iban') && this.tempIban != newValue) {
				this.tempIban = newValue;
				let data = {
					param: newValue,
					table: 'giocata',
					fieldName: 'iban',
				}

				let r = await axiosMixin.methods.axiosCall('api/checkIfIsUnique', data, 'POST');
				try {
					if (r.status < parseInt(process.env.VUE_APP_CATCH)) { // then 
						this.existIban = false;
					} else { // catch
						this.existIban = true;
					}
				} catch (error) { }

			}
		},
	},


}