<template>
	<footer class="container-fluid back-5 pt-4 back-image">
		<div class="row justify-content-center pb-5">
			<div class="col-md-10 center">
				<h5 class="uppercase Bold white pt-3"
					><br /><a
						href="https://www.alcolparliamone.it"
						target="_blank"
						class="Regular small nouppercase"
						><img
							style="max-width: 200px"
							src="../../assets/images/obevi.png"
							class="img img-fluid" /></a></h5
				><br />
				<h4 class="Bold white uppercase">concorso valido dal 1 luglio 2022 al 2 OTTOBRE 2022</h4
				><br />
			</div>
			<div class="col-12"></div>
			<div class="col-lg-2 col-md-2 center"
				><a :href="regolamentoPolicy" target="_blank" class="Regular small color-3"
					>Regolamento</a
				></div
			>
			<div class="col-lg-2 col-md-2 center"
				><a :href="cookiePolicy" target="_blank" class="Regular small color-3">Cookies</a></div
			>
			<div class="col-lg-2 col-md-2 center"
				><a :href="privacyPolicy" target="_blank" class="Regular small color-3">Privacy</a></div
			>
		</div>
	</footer>
</template>

<script>
	import { sharedEnvMixin } from '../../mixins/sharedEnvMixin'

	export default {
		name: 'Footer',
		mixins: [sharedEnvMixin],
		mounted() {}
	}
</script>
