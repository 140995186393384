export const sharedEnvMixin = {

	data() {
		return {
			startDate: process.env.VUE_APP_START_DATE,
			stopDate: process.env.VUE_APP_STOP_DATE,
			daysBeforeNow: process.env.VUE_APP_MAX_BEFORE_NOW_DAYS,
			maxDays: process.env.VUE_APP_MAXDAYS == 'ON' ? true : false,
			start: process.env.VUE_APP_START,
			finish: process.env.VUE_APP_FINISH,
			wizard: process.env.VUE_APP_STEPFORM == 'ON' ? true : false,
			hasOcr: process.env.VUE_APP_HAS_OCR == 'ON' ? true : false,
			hasImg: process.env.VUE_APP_HAS_IMAGE == 'ON' ? true : false,
			hasLogin: process.env.VUE_APP_HAS_LOGIN == 'ON' ? true : false,
			hasShipData: process.env.VUE_APP_HAS_SHIPDATA == 'ON' ? true : false,
			regolamentoPolicy: process.env.VUE_APP_REGOLAMENTO,
			privacyPolicy: process.env.VUE_APP_PRIVACY,
			cookiePolicy: process.env.VUE_APP_COOKIE,
			altImg: process.env.VUE_APP_ALT_IMG,
			cookieBot: process.env.VUE_APP_HAS_COOKIEBOT,
			cookieBotKey: process.env.VUE_APP_COOKIEBOTKEY,
			datiSpedizione: process.env.VUE_APP_HAS_DATI_SPEDIZIONE == 'ON' ? true : false,
			navType: process.env.VUE_APP_TYPE_OF_NAV,
			hasProduct: process.env.VUE_APP_PRODUCT_INSERT == 'ON' ? true : false,
			navHeight: 106,
			navHeightMedium: 80,
			navHeightSmall: 60,
		};
	},

}