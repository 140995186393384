<template>
  <div
    class="widthFull relative padding-top-calc customBg min-height-calc"
    id="loginH"
  >
    <div class="container-fluid"></div>
    <div class="row justify-content-center py-4">
      <div class="col-8 text-center">
        <h1 class="col-12 center Regular pb-2 color-1 uppercase">
          Dove acquistare
        </h1>

        <h3 class="col-12 center Regular pb-2 color-1 uppercase">
          PRESSO I NEGOZI FISICI <br />
          (INCLUSI CASH&CARRY PER PARTITE IVA) <br />
          OPPURE ONLINE
        </h3>

        <h2 class="col-12 center Regular pb-2 color-1 uppercase">
          qui trovi alcuni siti:
        </h2>

        <div class="mb-2">
          <a
            class="btn btn-primary btn-primary--fixwidth"
            href="https://pamacasa.pampanorama.it/prodotto/crodino-crodino-10-x-10-cl-346891"
            target="blank"
          >
            <span class="multiplelines">PAM</span>
          </a>
        </div>
        <div class="mb-2">
          <a
            class="btn btn-primary btn-primary--fixwidth"
            href="https://spesaonline.coopcentroitalia.it/spesa-consegna-domicilio/05100/prodotto/crodino-crodino-10-x-10-cl-337555"
            target="blank"
          >
            <span class="multiplelines">COOP</span>
          </a>
        </div>
        <div class="mb-2">
          <a
            class="btn btn-primary btn-primary--fixwidth"
            href="https://www.tigros.it/shop/product/crodino-cl10x10"
            target="blank"
          >
            <span class="multiplelines">TIGROS</span>
          </a>
        </div>
        <div class="mb-2">
          <a
            class="btn btn-primary btn-primary--fixwidth"
            href="https://www.craispesaonline.it/prodotto/10101-crodino-10-x-10-cl"
            target="blank"
          >
            <span class="multiplelines">CRAI</span>
          </a>
        </div>

        <div class="mb-2">
          <a
            class="btn btn-primary btn-primary--fixwidth"
            href="https://it.everli.com/it"
            target="blank"
          >
            <span class="multiplelines">EVERLI</span>
          </a>
        </div>
        <div class="mb-2">
          <a
            class="btn btn-primary btn-primary--fixwidth"
            href="https://shop.despar.com/spesa-consegna-domicilio/85025/prodotto/crodino-crodino-10-x-10-cl-240392"
            target="blank"
          >
            <span class="multiplelines">DESPAR</span>
          </a>
        </div>
        <div class="mb-2">
          <a
            class="btn btn-primary btn-primary--fixwidth"
            href="https://shop.nonnaisa.com/spesa-ritiro-negozio/via-vandalino-casu/ricerca?search=crodino"
            target="blank"
          >
            <span class="multiplelines">NONNA ISA</span>
          </a>
        </div>
        <div class="mb-2">
          <a
            class="btn btn-primary btn-primary--fixwidth"
            href="https://shop.flordocafe.it/spesa-consegna-domicilio/80125/ricerca?search=crodino#remodal_product_366441"
            target="blank"
          >
            <span class="multiplelines">FLOR DO CAFE'</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AUTH_REQUEST } from "actions/auth";
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import { axiosMixin } from "../../mixins/axiosMixin";

export default {
  name: "Wheretobuy",
  mixins: [axiosMixin],
  data() {
    return {
      password: "",
      confirm_password: "",
      state: false,
      error: "",
      loadingState: false,
    };
  },
  methods: {
    async recoveryCall(token) {
      const { password, confirm_password } = this;

      let data = {
        password_player: password,
        temp_token_player: token,
      };

      let r = await axiosMixin.methods.axiosCall(
        "api/changePassword",
        data,
        "POST"
      );
      let message = r.data.message;
      let statusCode = r.data.statusCode;
      let description = r.data.message.description;
      this.loadingState = false;
      try {
        if (r.status < parseInt(process.env.VUE_APP_CATCH)) {
          // then
          if (message) {
            this.state = true;
          }
        } else {
          this.state = false;
          this.error = process.env.VUE_APP_TOKEN_NOT_EXIST;
        }
      } catch (error) {
        this.state = false;
        this.error = process.env.VUE_APP_TOKEN_NOT_EXIST;
      }
    },

    recoveryValidation: function () {
      const token = this.$route.query.token;
      this.$validator.validate().then((valid) => {
        if (valid && token) {
          this.loadingState = true;
          this.recoveryCall(token);
        }
      });
    },
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "loginerror",
      "isAuthenticated",
      "isProfileLoaded",
    ]),
    ...mapState({
      //login_error: state => `${state.login_error}`,
    }),
  },
};
</script>
