<template>
	<div id="separator">
		<img
			src="../../assets/images/divisorio.png"
			style="max-width: 100px !important"
			class="img mb-5"
		/><br />
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	const moment = require('moment')

	export default {
		name: 'header',

		data() {
			return {
				vw: window.innerWidth,
				oldPresent: false,
				moment: moment,
				finish: process.env.FINISH
			}
		},
		methods: {
			showPdv() {
				var pdv = this.pdv
				pdv.sort((a, b) => a[1].localeCompare(b[1]))
				var text = ''
				for (var x in pdv) {
					text +=
						'<p class="text-left"> <b class="medium">' +
						pdv[x][1] +
						'</b> - ' +
						pdv[x][0] +
						' </p> '
				}

				this.$swal({
					type: '',
					title: '<strong class="modalT danger">Punti vendita.</strong>',
					html: '<div class="pdvContainer">' + text + '</div>',
					confirmButtonText: 'CHIUDI',
					confirmButton: 'closeProductsModal',

					background: 'rgb(255, 255, 255)'
				})
			},

			playNow: function () {
				var _vue = this
				if (this.vw > 767) {
					setTimeout(function () {
						_vue.$scrollTo('#inizio-concorso', 700, { offset: -30 })
					}, 100)
				} else {
					setTimeout(function () {
						_vue.$scrollTo('#inizio-concorso', 700, { offset: -40 })
					}, 100)
				}
			}
		},
		watch: {},
		beforeUpdate: function () {},
		updated() {},
		mounted: function () {},
		computed: {
			...mapGetters(['isAuthenticated', 'authStatus', 'getPromotions', 'getOldPromotions'])
		}
	}
</script>
