import Vue from 'vue'
import axios from 'axios'
import {
	axiosMixin
} from "../../mixins/axiosMixin.js";


const moment = require('moment')
const timestore = Vue.observable({
	now: moment(),
	status: 'init',
	diff: 0
})
const timeactions = {
	updateValue(val) {
		timestore.now = val
	},
	updateStatus(val) {
		timestore.status = val
	},
	updateDiff(val) {
		timestore.diff = val
	}
}
Vue.prototype.$timestore = timestore
Vue.prototype.$timeactions = timeactions

const startTimeFunction = async function () {
	if (Vue.prototype.$timestore.status != 'init') {
		var front = moment()
		if (Vue.prototype.$timestore.diff >= 0) {
			front = front.add(Vue.prototype.$timestore.diff)
		} else {
			front = front.add(Vue.prototype.$timestore.diff)
		}
		timeactions.updateValue(front)
	} else {

		let data = {}
		let r = await axiosMixin.methods.axiosCall('api/atomictime', data, 'GET');
		let message = r.data.message;

		try {
			if (r.status < parseInt(process.env.VUE_APP_CATCH)) { // then
				if (message) {
					var front = moment()
					var server = moment(message)
					var difference = server.diff(front)
					if (difference >= 0) {
						front = front.add(difference)
					} else {
						front = front.add(difference)
					}
					var _vue = this
					timeactions.updateValue(front)
					timeactions.updateStatus('initialized')
					timeactions.updateDiff(difference)
				}
			} else {
				var front = moment()
				timeactions.updateValue(front)
			}
		} catch (error) {
			var front = moment()
			timeactions.updateValue(front)
		}


		// axios({
		// 		url: process.env.VUE_APP_ROOT_API + 'api/atomictime',
		// 		method: 'GET',
		// 		headers: {
		// 			Authorization: 'Bearer ' + process.env.VUE_APP_KITTY,
		// 			'Content-type': 'application/json',
		// 		},
		// 	})
		// 	.then(resp => {
		// 		let front = moment()
		// 		let server = moment(resp.data.now)
		// 		let difference = server.diff(front)
		// 		if (difference >= 0) {
		// 			front = front.add(difference)
		// 		} else {
		// 			front = front.add(difference)
		// 		}

		// 		timeactions.updateValue(front)
		// 		timeactions.updateStatus('initialized')
		// 		timeactions.updateDiff(difference)
		// 	})
		// 	.catch(resp => {

		// 		let front = moment()
		// 		timeactions.updateValue(front)
		// 	})
	}
}
startTimeFunction();
setInterval(function () {
	startTimeFunction()
}, 3000)