var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"widthFull relative back-3 padding-top-calc min-height-calc"},[(_vm.loadingState)?_c('loading'):_vm._e(),_vm._m(0),_c('div',{staticClass:"container-fluid"},[_c('form',{staticClass:"recovery-password",attrs:{"id":"recovery-form"},on:{"submit":function($event){$event.preventDefault();return _vm.recoveryValidation.apply(null, arguments)}}},[(!_vm.state)?_c('div',[_c('div',{staticClass:"form-row justify-content-center"},[_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"widthFull text-center",attrs:{"for":"password"}}),_c('div',{staticClass:"group mt-5"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"},{name:"validate",rawName:"v-validate",value:({
                  required: true,
                  passwordCheck: true,
                  min: 8,
                  max: 56,
                }),expression:"{\n                  required: true,\n                  passwordCheck: true,\n                  min: 8,\n                  max: 56,\n                }"}],staticClass:"form-control mt-1",class:{
                  'is-invalid': _vm.errors.has('password'),
                  valid: !!_vm.password,
                },attrs:{"autocomplete":"on","type":"password","id":"password","name":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',{staticClass:"highlight"}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"password"}},[_vm._v("Nuova password")]),(_vm.errors.has('password'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("password"))+" ")]):_vm._e()]),_c('div',{staticClass:"group mt-5"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirm_password),expression:"confirm_password"},{name:"validate",rawName:"v-validate",value:({
                  required: true,
                  passwordCheck: true,
                  min: 8,
                  max: 56,
                  confirmed: _vm.password,
                }),expression:"{\n                  required: true,\n                  passwordCheck: true,\n                  min: 8,\n                  max: 56,\n                  confirmed: password,\n                }"}],staticClass:"form-control mt-3",class:{
                  'is-invalid': _vm.errors.has('confirm_password'),
                  valid: !!_vm.confirm_password,
                },attrs:{"autocomplete":"on","type":"password","id":"confirm_password","name":"confirm_password","data-vv-as":"conferma password"},domProps:{"value":(_vm.confirm_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirm_password=$event.target.value}}}),_c('span',{staticClass:"highlight"}),_c('label',{staticClass:"niceInputLabel",attrs:{"for":"password"}},[_vm._v("Ripeti password")]),(_vm.errors.has('confirm_password'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("confirm_password"))+" ")]):_vm._e(),(_vm.error)?_c('div',{staticClass:"mt-3"},[_c('span',{staticClass:"formError ml-1"},[_vm._v(_vm._s(_vm.error))])]):_vm._e()])])]),_vm._m(1)]):_c('div',[_vm._m(2),_c('div',{staticClass:"form-row justify-content-center"},[_c('div',{staticClass:"col-md-6 mb-3 text-center"},[_c('router-link',{staticClass:"btn btn-primary mb-4 px-5",attrs:{"to":"/login"}},[_vm._v("Accedi")])],1)])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row center mt-4"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"col-12 center Regular pb-2 color-1 uppercase"},[_vm._v(" Crea nuova password ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-row justify-content-center"},[_c('div',{staticClass:"col-md-6 mb-3 mt-5 text-center"},[_c('button',{staticClass:"btn btn-primary mb-4 px-5",attrs:{"type":"submit"}},[_vm._v(" RIPRISTINA ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-row justify-content-center"},[_c('div',{staticClass:"col-md-4 mb-5 mt-5"},[_c('h4',{staticClass:"center widthFull glade",attrs:{"for":"email","role":"alert"}},[_vm._v(" La password è stata aggiornata correttamente, "),_c('br'),_vm._v(" adesso puoi accedere utilizzando la tua nuova password. ")])])])}]

export { render, staticRenderFns }