import axios from 'axios';

export const locationMixin = {

	data() {
		return {
			city: '',
			province: '',
			provinces: [],
			pdv_provinces: [],
			provincesState: '',
			pdv_provincesState: true,
			cityError: false,
			zipError: false,
			pdv_cityError: false,
			pdvError: false
		};
	},

	methods: {
		populateProvinces: function () {
			axios({
					url: process.env.VUE_APP_ROOT_API + 'api/getProvinces',
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + process.env.VUE_APP_KITTY,
						'Content-type': 'application/json',
					},
				})
				.then((resp) => {
					this.provinces = resp.data;
					this.provincesState = false;
				})
				.catch((resp) => {
					this.provinces = [];
					this.provincesState = false;
				});
		},

		checkProvince: function () {
			const {
				province
			} = this;
			if (province) {
				this.cityError = false;
			} else {
				this.cityError = true;
			}
		},

		checkZip: function () {
			const {
				city
			} = this;
			if (city) {
				this.zipError = false;
			} else {
				this.zipError = true;
			}
		},
		check_pdv_prov: function () {
			const {
				pdv_prov
			} = this;
			if (pdv_prov) {
				this.pdv_cityError = false;
			} else {
				this.pdv_cityError = true;
			}
		},

		checkpdv: function () {
			const {
				pdv
			} = this;
			if (pdv) {
				this.pdvError = false;
			} else {
				this.pdvError = true;
			}
		},
	},

	watch: {

		province: function (newProvince) {
			this.zips = [];
			this.cities = [];
			this.zip = '';
			this.city = '';
			this.cityError = false;
			this.zipError = false;

			var data = this.$encryptByAES({
				province: newProvince,

			})



			if (newProvince) {
				this.citiesState = true;
				axios({
						data: {
							data
						},
						url: process.env.VUE_APP_ROOT_API + 'api/getMunicipalities',
						method: 'POST',
						headers: {
							Authorization: 'Bearer ' + process.env.VUE_APP_KITTY,
							'Content-type': 'application/json',
						},
					})
					.then((resp) => {

						this.cities = resp.data;
						this.citiesState = false;
					})
					.catch((resp) => {
						this.cities = [];
						this.citiesState = false;
					});
			}
		},

		city: function (newCity) {
			this.zipError = false;
			this.zips = [];
			this.zip = '';

			var data = this.$encryptByAES({
				istat: newCity.cityistat,

			})



			if (newCity.cityistat) {
				this.zipsState = true;
				axios({
						url: process.env.VUE_APP_ROOT_API + 'api/getZip',
						method: 'POST',
						data: {
							data
						},
						headers: {
							Authorization: 'Bearer ' + process.env.VUE_APP_KITTY,
							'Content-type': 'application/json',
						},
					})
					.then((resp) => {
						this.zips = resp.data;
						this.zipsState = false;
					})
					.catch((resp) => {
						this.zips = [];
						this.zipsState = false;
					});
			}
		},
	},

}