<template>
  <div class="widthFull relative padding-top-calc min-height-calc customBg">
    <loading v-if="loadingState" />

    <div class="container-fluid">
      <div class="row mt-4 center">
        <h1 class="col-12 center Regular pb-2 color-1 uppercase">
          FAQ <br />
          Alcune domande
        </h1>
      </div>
    </div>
    <div class="container mt-5">
      <div
        class="
          accordion-buttons
          d-flex
          flex-column flex-md-row
          justify-content-center
          mb-5
        "
      >
        <div
          class="btn-accordion text-center Bold"
          @click="activate(1)"
          :class="{ active: position == 1 }"
        >
          <h3>PARTECIPAZIONE</h3>
        </div>
        <div
          class="btn-accordion text-center Bold"
          @click="activate(2)"
          :class="{ active: position == 2 }"
        >
          <h3>IL PREMIO</h3>
        </div>

        <div
          class="btn-accordion text-center Bold"
          @click="activate(3)"
          :class="{ active: position == 3 }"
        >
          <h3>ACCESSO E REGISTRAZIONE</h3>
        </div>

        <div
          class="btn-accordion text-center Bold"
          @click="activate(4)"
          :class="{ active: position == 4 }"
        >
          <h3>PROVA D'ACQUISTO</h3>
        </div>
      </div>

      <div class="mb-5">
        <div id="accordion" v-if="position == 1">
          <div class="card">
            <div class="card-header">
              <h5>
                <button
                  class="btn btn-link white"
                  data-toggle="collapse"
                  data-target="#coll1"
                >
                  Come si partecipa al concorso?
                </button>
              </h5>
            </div>
            <div
              id="coll1"
              class="collapse back-1 color-3 Regular"
              data-parent="#accordion"
            >
              <div class="card-body">
                <hr />
                <h4 class="uppercase Medium">
                  Per partecipare devi:
                  <ul class="mt-3">
                    <li>
                      acquistare, nel periodo di durata della promozione, con
                      scontrino unico parlante/fattura di acquisto, almeno uno
                      dei prodotti promozionati sopra indicati;
                    </li>
                    <li>
                      collegarsi dalle ore 00:00:01 del 21/03/2022 alle ore
                      23:59:59 del 15/01/2023 al sito
                      https://restaumanoconcrodino.it/;
                    </li>
                    <li>accedere alla sezione dedicata alla promozione;</li>
                    <li>
                      registrarsi compilando tutti i dati obbligatori
                      dell’apposito form (compreso l’indirizzo e-mail) e
                      accettare il regolamento; in caso di già avvenuta
                      registrazione all’iniziativa, il consumatore dovrà
                      effettuare il login con le credenziali di accesso già in
                      suo possesso;
                    </li>
                    <li>
                      fare l’upload della foto dello scontrino che documenta
                      l’acquisto (o un documento equiparabile allo scontrino);
                    </li>
                    <li>indicare nell’apposito form:</li>
                    <li>
                      il/i prodotto/prodotti acquistati (formato e quantità tra
                      quelli in promozione);
                    </li>
                    <li>un numero di telefono.</li>
                  </ul>

                  <span>
                    Nel caso di vincita di uno dei premi messi in palio verrà
                    richiesto di iniziare a fornire l’indirizzo per la
                    spedizione del premio. La prova d’acquisto verrà moderata
                    entro 5 (cinque) giorni lavorativi (sabato, domenica e
                    festivi esclusi).
                  </span>
                  <br />
                  <span>
                    <b>
                      N.B. Ogni prova d’acquisto può essere giocata una sola
                      volta e, pertanto, consente una sola partecipazione al
                      concorso; deve essere parlante ovvero riportare la
                      descrizione dei prodotti acquistati ed essere interamente
                      leggibile.
                    </b>
                  </span>
                </h4>
                <hr />
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h5>
                <button
                  class="btn btn-link white"
                  data-toggle="collapse"
                  data-target="#coll2"
                >
                  Chi può partecipare al concorso?
                </button>
              </h5>
            </div>
            <div
              id="coll2"
              class="collapse back-1 color-3 Regular"
              data-parent="#accordion"
            >
              <div class="card-body">
                <hr />
                <div class="uppercase text-center Medium">
                  <h4>
                    Tutti coloro che, dopo aver acquistato nel periodo dal 21
                    MARZO 2022 AL 15 GENNAIO 2022 almeno n.1 prodotto tra quelli
                    in promozione; si collegano al sito
                    www.restaumanoconcrodino.it dalle ore 00:00 del 21 Marzo
                    2022 alle ore 23:59 del 15 Gennaio 2022 al sito ed entrano
                    nella sezione dedicata alla promozione.
                  </h4>
                </div>

                <hr />
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <h5>
                <button
                  class="btn btn-link white"
                  data-toggle="collapse"
                  data-target="#coll3"
                >
                  Quanto dura il concorso?
                </button>
              </h5>
            </div>
            <div
              id="coll3"
              class="collapse back-1 color-3 Regular"
              data-parent="#accordion"
            >
              <div class="card-body">
                <hr />
                <div class="uppercase text-center Medium">
                  <h4>
                    La durata per l’acquisto è dal 21 Marzo 2022 al 15 Gennaio
                    2023. <br />
                    La durata per la partecipazione è dalle ore 00:00 del 21
                    Marzo 2022 alle ore 23:59 del 15 Gennaio 2023.
                  </h4>
                </div>

                <hr />
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <h5>
                <button
                  class="btn btn-link white"
                  data-toggle="collapse"
                  data-target="#coll4"
                >
                  C’è un limite di volte in cui posso partecipare?
                </button>
              </h5>
            </div>
            <div
              id="coll4"
              class="collapse back-1 color-3 Regular"
              data-parent="#accordion"
            >
              <div class="card-body">
                <hr />
                <div class="uppercase text-center Medium">
                  <h4>
                    No, l’importante è che ogni partecipazione sia con una prova
                    d’acquisto diversa.
                  </h4>
                </div>

                <hr />
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <h5>
                <button
                  class="btn btn-link white"
                  data-toggle="collapse"
                  data-target="#coll5"
                >
                  Come faccio a modificare i miei dati personali?
                </button>
              </h5>
            </div>
            <div
              id="coll5"
              class="collapse back-1 color-3 Regular"
              data-parent="#accordion"
            >
              <div class="card-body">
                <hr />
                <div class="uppercase text-center Medium">
                  <h4>
                    I dati personali che hai inserito al momento della
                    registrazione puoi modificarli accedendo alla sezione “Il
                    mio profilo” e aggiornando le informazioni precedentemente
                    inserite. Invece i dati personali che hai inserito al
                    momento della vincita per ricevere il premio dopo aver
                    cliccato il tasto “Conferma” sono immodificabili da parte
                    tua e devi comunicare le modifiche contattando il call
                    center.
                  </h4>
                </div>

                <hr />
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <h5>
                <button
                  class="btn btn-link white"
                  data-toggle="collapse"
                  data-target="#coll6"
                >
                  Quali sono i prodotti che partecipano alla manifestazione a
                  premio?
                </button>
              </h5>
            </div>
            <div
              id="coll6"
              class="collapse back-1 color-3 Regular"
              data-parent="#accordion"
            >
              <div class="card-body">
                <hr />
                <div class="uppercase text-center Medium">
                  <h4>
                    Crodino Biondo x10, <br />
                    Crodino Biondo x8, <br />
                    Crodino Biondo x6,<br />
                    Crodino Rosso x10,<br />
                    Crodino Rosso x8<br />
                  </h4>
                </div>

                <hr />
              </div>
            </div>
          </div>
        </div>

        <div id="accordion" v-if="position == 2">
          <div class="card">
            <div class="card-header">
              <h5>
                <button
                  class="btn btn-link white"
                  data-toggle="collapse"
                  data-target="#coll1"
                >
                  Quali sono i premi in palio?
                </button>
              </h5>
            </div>
            <div
              id="coll1"
              class="collapse back-1 color-3 Regular"
              data-parent="#accordion"
            >
              <div class="card-body">
                <hr />
                <div class="uppercase text-center Medium">
                  <h4>
                    I premi in palio sono dei Kit aperitivo composto da 5
                    bicchieri Crodino e 5 sottobicchieri durevoli personalizzati
                  </h4>
                </div>

                <hr />
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h5>
                <button
                  class="btn btn-link white"
                  data-toggle="collapse"
                  data-target="#coll2"
                >
                  Quante volte posso vincere un premio
                </button>
              </h5>
            </div>
            <div
              id="coll2"
              class="collapse back-1 color-3 Regular"
              data-parent="#accordion"
            >
              <div class="card-body">
                <hr />
                <div class="uppercase text-center Medium">
                  <h4>
                    Si può vincere un solo premio nel corso dell’intera
                    iniziativa.
                  </h4>
                </div>

                <hr />
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <h5>
                <button
                  class="btn btn-link white"
                  data-toggle="collapse"
                  data-target="#coll3"
                >
                  Posso cambiare il premio che ho scelto?
                </button>
              </h5>
            </div>
            <div
              id="coll3"
              class="collapse back-1 color-3 Regular"
              data-parent="#accordion"
            >
              <div class="card-body">
                <hr />
                <div class="uppercase text-center Medium">
                  <h4>
                    I premi non sono sostituibili, né convertibili in denaro, né
                    è data la possibilità ai vincitori di richiedere, con o
                    senza l’aggiunta di somme in denaro, un premio diverso.
                  </h4>
                </div>

                <hr />
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <h5>
                <button
                  class="btn btn-link white"
                  data-toggle="collapse"
                  data-target="#coll4"
                >
                  E se non vinco nulla?
                </button>
              </h5>
            </div>
            <div
              id="coll4"
              class="collapse back-1 color-3 Regular"
              data-parent="#accordion"
            >
              <div class="card-body">
                <hr />
                <div class="uppercase text-center Medium">
                  <h4>
                    Tieni accesa la speranza! Gli scontrini validi non vincenti
                    parteciperanno all’eventuale estrazione finale dei premi non
                    assegnati o non convalidati che si terrà entro il entro il
                    15 Febbraio 2023.
                  </h4>
                </div>

                <hr />
              </div>
            </div>
          </div>
        </div>

        <div id="accordion" v-if="position == 3">
          <div class="card">
            <div class="card-header">
              <h5>
                <button
                  class="btn btn-link white"
                  data-toggle="collapse"
                  data-target="#coll1"
                >
                  Non riesco ad accedere al mio profilo. Come posso fare?
                </button>
              </h5>
            </div>
            <div
              id="coll1"
              class="collapse back-1 color-3 Regular"
              data-parent="#accordion"
            >
              <div class="card-body">
                <hr />
                <div class="uppercase text-center Medium">
                  <h4>
                    Puoi recuperare la password utilizzata per la registrazione
                    usando il pulsante “Hai dimenticato la password?” in fase di
                    login. Lo username è l’indirizzo email con cui hai
                    effettuato la registrazione.
                  </h4>
                </div>

                <hr />
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h5>
                <button
                  class="btn btn-link white"
                  data-toggle="collapse"
                  data-target="#coll2"
                >
                  In fase di registrazione compare il messaggio che l’email
                  utilizzata è già esistente. Come mai?
                </button>
              </h5>
            </div>
            <div
              id="coll2"
              class="collapse back-1 color-3 Regular"
              data-parent="#accordion"
            >
              <div class="card-body">
                <hr />
                <div class="uppercase text-center Medium">
                  <h4>
                    Se compare il messaggio che l’email inserita è già esistente
                    vuol dire che ti sei già registrato. Se hai dimenticato la
                    password clicca sul link “Hai dimenticato la password?”
                  </h4>
                </div>

                <hr />
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <h5>
                <button
                  class="btn btn-link white"
                  data-toggle="collapse"
                  data-target="#coll3"
                >
                  Non ricordo la password per accedere. Cosa posso fare?
                </button>
              </h5>
            </div>
            <div
              id="coll3"
              class="collapse back-1 color-3 Regular"
              data-parent="#accordion"
            >
              <div class="card-body">
                <hr />
                <div class="uppercase text-center Medium">
                  <h4>
                    Se non ricordi la password, clicca sul link “Hai dimenticato
                    la password?”, ti verrà inviata una e-mail con le istruzioni
                    per impostare nuovamente la password.
                  </h4>
                </div>

                <hr />
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <h5>
                <button
                  class="btn btn-link white"
                  data-toggle="collapse"
                  data-target="#coll4"
                >
                  Non mi è arrivata la e-mail di conferma registrazione /
                  riepilogo dati. Come mai?
                </button>
              </h5>
            </div>
            <div
              id="coll4"
              class="collapse back-1 color-3 Regular"
              data-parent="#accordion"
            >
              <div class="card-body">
                <hr />
                <div class="uppercase text-center Medium">
                  <h4>
                    Controlla nella cartella di posta indesiderata. Assicurati
                    che la mailbox non risulti piena, l’indirizzo di posta
                    elettronica fornito in fase di registrazione non sia errato
                    o incompleto, la mailbox non risulti disabilitata.
                  </h4>
                </div>

                <hr />
              </div>
            </div>
          </div>
        </div>

        <div id="accordion" v-if="position == 4">
          <div class="card">
            <div class="card-header">
              <h5>
                <button
                  class="btn btn-link white"
                  data-toggle="collapse"
                  data-target="#coll1"
                >
                  Non riesco a caricare lo scontrino/documento di consegna?
                </button>
              </h5>
            </div>
            <div
              id="coll1"
              class="collapse back-1 color-3 Regular"
              data-parent="#accordion"
            >
              <div class="card-body">
                <hr />
                <div class="uppercase text-center Medium">
                  <h4>
                    Per caricare la foto dello scontrino/documento di consegna
                    devi prima cliccare su “Carica”. Ricordati di caricare
                    un’immagine ben visibile dell’intera prova d’acquisto che
                    non deve essere piegata, tagliata, riportare sottolineature
                    e deve essere ben leggibile e visibile in tutte le sue
                    parti, nei formati “jpeg”, “png”, “pdf”, nella dimensione
                    massima di “10Mb”.
                  </h4>
                </div>

                <hr />
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h5>
                <button
                  class="btn btn-link white"
                  data-toggle="collapse"
                  data-target="#coll2"
                >
                  Ho ricevuto l’esito “Immagine non leggibile”, “Non sembra
                  essere una prova d’acquisto”, “Data non coerente”, come devo
                  fare?
                </button>
              </h5>
            </div>
            <div
              id="coll2"
              class="collapse back-1 color-3 Regular"
              data-parent="#accordion"
            >
              <div class="card-body">
                <hr />
                <div class="uppercase text-center Medium">
                  <h4>
                    In questo caso, devi fotografare nuovamente la prova
                    d’acquisto e ricaricare la foto facendo attenzione a rendere
                    che sia ben visibile e fotografata per intero.
                  </h4>
                </div>

                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

 <style lang="css" scoped>
/* FAQ */
.card {
  position: relative !important;
  display: -ms-flexbox;
  display: flex !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  min-width: 0 !important;
  word-wrap: break-word !important;
  background-color: #fff !important;
  background-clip: border-box !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 0.25rem !important;
}
#accordion .btn {
  text-transform: inherit;
  color: inherit;
  padding: 0 !important;
  white-space: inherit;
  text-align: left;
  font-size: 1rem !important;
  line-height: 1.5rem;
  width: 100% !important;
}

#accordion .card {
  margin-bottom: 1rem;
}
.btn:focus {
  box-shadow: none !important;
}
.card-header {
  background-color: #fff;
  border-bottom: none;
}

.card-body {
  padding: 0 1.25rem 1.25rem;
}

.addthis_inline_share_toolbox {
  display: inline-block;
}

.insta-download {
  display: inline-block;
  vertical-align: top;
  margin-left: -1px;
  transition: all 0.2s ease-in-out;
}

.insta-download:hover {
  transform: translateY(-4px);
}
</style>
<script>
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import { USER_REQUEST } from "actions/user";
import { BadgerAccordion, BadgerAccordionItem } from "vue-badger-accordion";

export default {
  name: "faq",
  components: {
    BadgerAccordion,
    BadgerAccordionItem,
  },
  data() {
    return {
      position: 1,
      loadingState: "",
      userToken: "",
      concorso: "1",
      pdvBuy: [
        ["CISALFA SPORT SPA", "Cisalfa"],
        ["MAISONS DU MONDE ITALIE SPA", "Maison du Monde"],
        ["PAM PANORAMA SPA", "PAM"],
        ["PROFUMERIE DOUGLAS SPA", "Douglas"],
        ["MCS SRL", "Aldo Shoes"],
        ["E' QUI SPA", "E' qui"],
        ["THUN SPA", "Thun"],
        ["CENTRI DI BELLEZZA YVES ROCHER ITALIA Y.R.A.M. SRL", "Yves Rocher"],
        ["PRIMADONNA SPA", "Primadonna"],
        ["COMPAR SPA", "AW Lab"],
        ["MCS SRL", "Napapijri"],
        ["H&M HENNES & MAURITZ SRL", "H&M"],
        ["BONNIE SRL", "Tezenis"],
        ["LEONARDO SRL", "Amore Store"],
        ["ZARA ITALIA SRL", "ZARA"],
        ["HANGOVER GROUP SRL", "Zuiki"],
        ["TIGER ITALY 2 SRL", "Flying Tiger"],
        ["PAGANINI ROMA SRL", "Nuvolari"],
        ["NUMBER 1 SRL", "I Am"],
        ["MAYORAL ITALIA SRL", "Mayoral"],
        ["KASANOVA SPA", "Kasanova"],
        ["DP 50 SRL", "Dental Pro"],
        ["101 CAFFE' SRL", "101 Caffè"],
        ["GOLD ART STUDIO SRL", "Gold Art"],
        ["SHEKEL SRL", "Rafi Ruben"],
        ["C4 SRL", "Re Sole"],
        ["LOGOS SRL", "Lama Optical"],
        ["COMPANY SRL", "Sandro Ferrone"],
        ["STROILI ORO SPA", "Stroili Oro"],
        ["S. & G. TRADE SPA", "Skechers"],
        ["S. & G. TRADE SPA", "Adidas"],
        ["DAZA SRL", "Imperial"],
        ["AT RETAIL SRL", "Intimissimi Uomo"],
        ["MARI’S 2001 SRL", "Tiberland"],
        ["MARI’S 2001 SRL", "Wycon"],
        ["MARI’S 2001 SRL", "Tommy/Calvin Klein"],
        ["CAMAIEU ITALIA SRL", "Camaieu"],
        ["D.I.P. SPA DIFFUSIONE ITALIANA PREZIOSI", "Blue Spirit"],
        ["SALLY J SRL", "Calzedonia"],
        ["GRANDVISIONITALY SRL UNIPERSONALE", "Grand Vision"],
        ["SIX16TEEN GROUP SRL", "To Lu"],
        ["BERTON LINE SRL", "Talco"],
        ["GRUPPO SASSON SRL", "Omai"],
        ["NAU SPA", "Nau!"],
        ["GRACAFFE’ 2019 SRL", "CHIOSCO Vergnano"],
        ["B PLAN COLORS SRL", "CHIOSCO Benetton"],
        ["A & CD FUTURE COMMUNICATION SRL", "CHIOSCO WIND-TRE"],
        ["NESPRESSO ITALIANA SPA", "CHIOSCO NESPRESSO"],
        ["KAOS CAMPANIA SRL", "Don Peppe"],
        ["ROADHOUSE GRILL ROMA SRL", "Roadhouse"],
        ["GRUPPO NEGOZI SRL", "La Piadineria"],
        ["ROADHOUSE SPA", "Calavera"],
        ["RETAIL SHOP SRL", "Caporiccio"],
        ["DG GROUP SRL", "Castellese"],
        ["PALOMAR SRL", "Scarpamondo"],
        ["MEDIAMARKET SPA", "MediaWorld"],
        ["DENIM FACTORY SRL", "Live 978"],
        ["GRAN FEMME SRL", "Tagliati per il successo"],
        ["GALILEO SPA", "Satur Passione Casa"],
        ["BERSHKA ITALIA SRL", "Bershka"],
        ["PULL&BEAR ITALIA SRL", "Pull & Bear"],
        ["SORBINO RETAIL SPA", "Sorbino"],
        ["STRADIVARIUS ITALIA SRL", "Stradivarius"],
        ["BESTSELLER STORES ITALY SPA", "Jack & Jones"],
        ["PARFUMANIA SRL", "Estasi Profumerie"],
        ["PAGANINI ROMA SRL", "Project"],
        ["4G RETAIL SRL A SOCIO UNICO", "TIM"],
        ["GRABISTROT 2019 SRL", "Mondo Arancina "],
        ["BURGER KING RESTAURANTS ITALIA SRL", "Burger King"],
        ["FOLLYE SRL", "Marlù"],
        ["LIDDO SAMANTA", "Bottega Verde"],
        ["A & CD FUTURE COMMUNICATION SRL", "Wind Tre"],
        ["GIOIELLI DI VALENZA SPA", "Gioielli di Valenza"],
        ["ANDER SRL", "DVerso"],
        ["IMAP EXPORT SPA", "Original Marines"],
        ["VIRTUAL DREAM SRL", "Dixie"],
        ["GUESS ITALIA SRL", "Guess"],
        ["RINAH SPA", "Dan John"],
        ["PRIMITIVE SRL", "Brend Atelier"],
        ["CIDIVERTE SPA", "Gamelife"],
        ["TAILOR MAN SRL", "Enrico Coveri"],
        ["TEDDY SPA", "Rinascimento"],
        ["Q. HELENA SHOES SRL", "Queen Helena"],
        ["GRACAFFE’ 2019 SRL", "Caffè Vergnano"],
        ["MINI PIZZA GASPERINA SRL", "MINIPIZZA 22CM"],
        ["DAN EUROPE SRL", "Monsanto"],
        ["CONBIPEL SPA", "Conbipel uomo"],
        ["SI-COMERCE SRL", "CHIOSCO Equivalenza "],
        ["VACANT", "CHIOSCO (non commercializzato) "],
        [
          "MISTER GAMES SNC DI CEROCCHI FABIANO E CIUCCOLI ALESSANDRO",
          "CHIOSCO MR GAMES ",
        ],
        ["ANTARTICA SRL", "I Baccanali"],
        ["UNDIQUE NAPOLI SRL", "CHIOSCO CAPATOAST"],
        ["VODAFONE GESTIONI SPA", "CHIOSCO VODAFONE"],
        [
          "A & CD FUTURE COMMUNICATION SRL CON UNICO SOCIO",
          "CHIOSCO WIND TRE ",
        ],
      ],
      pdvOff: [
        ["CISALFA SPORT SPA", "Cisalfa"],
        ["PROFUMERIE DOUGLAS SPA", "Douglas"],
        ["MCS SRL", "Aldo Shoes"],
        ["E' QUI SPA", "E' qui"],
        ["THUN SPA", "Thun"],
        ["CENTRI DI BELLEZZA YVES ROCHER ITALIA Y.R.A.M. SRL", "Yves Rocher"],
        ["PRIMADONNA SPA", "Primadonna"],
        ["COMPAR SPA", "AW Lab"],
        ["MCS SRL", "Napapijri"],
        ["H&M HENNES & MAURITZ SRL", "H&M"],
        ["BONNIE SRL", "Tezenis"],
        ["LEONARDO SRL", "Amore Store"],
        ["ZARA ITALIA SRL", "ZARA"],
        ["HANGOVER GROUP SRL", "Zuiki"],
        ["TIGER ITALY 2 SRL", "Flying Tiger"],
        ["MAYORAL ITALIA SRL", "Mayoral"],
        ["KASANOVA SPA", "Kasanova"],
        ["101 CAFFE' SRL", "101 Caffè"],
        ["GOLD ART STUDIO SRL", "Gold Art"],
        ["C4 SRL", "Re Sole"],
        ["S. & G. TRADE SPA", "Skechers"],
        ["S. & G. TRADE SPA", "Adidas"],
        ["DAZA SRL", "Imperial"],
        ["MARI’S 2001 SRL", "Tiberland"],
        ["MARI’S 2001 SRL", "Wycon"],
        ["MARI’S 2001 SRL", "Tommy/Calvin Klein"],
        ["CAMAIEU ITALIA SRL", "Camaieu"],
        ["D.I.P. SPA DIFFUSIONE ITALIANA PREZIOSI", "Blue Spirit"],
        ["SALLY J SRL", "Calzedonia"],
        ["GRANDVISIONITALY SRL UNIPERSONALE", "Grand Vision"],
        ["SIX16TEEN GROUP SRL", "To Lu"],
        ["BERTON LINE SRL", "Talco"],
        ["GRUPPO SASSON SRL", "Omai"],
        ["NAU SPA", "Nau!"],
        ["GRACAFFE’ 2019 SRL", "CHIOSCO Vergnano"],
        ["B PLAN COLORS SRL", "CHIOSCO Benetton"],
        ["ROADHOUSE GRILL ROMA SRL", "Roadhouse"],
        ["GRUPPO NEGOZI SRL", "La Piadineria"],
        ["ROADHOUSE SPA", "Calavera"],
        ["RETAIL SHOP SRL", "Caporiccio"],
        ["DG GROUP SRL", "Castellese"],
        ["PALOMAR SRL", "Scarpamondo"],
        ["MEDIAMARKET SPA", "MediaWorld"],
        ["DENIM FACTORY SRL", "Live 978"],
        ["GRAN FEMME SRL", "Tagliati per il successo"],
        ["BERSHKA ITALIA SRL", "Bershka"],
        ["PULL&BEAR ITALIA SRL", "Pull & Bear"],
        ["SORBINO RETAIL SPA", "Sorbino"],
        ["STRADIVARIUS ITALIA SRL", "Stradivarius"],
        ["BESTSELLER STORES ITALY SPA", "Jack & Jones"],
        ["PARFUMANIA SRL", "Estasi Profumerie"],
        ["4G RETAIL SRL A SOCIO UNICO", "TIM"],
        ["GRABISTROT 2019 SRL", "Mondo Arancina "],
        ["BURGER KING RESTAURANTS ITALIA SRL", "Burger King"],
        ["FOLLYE SRL", "Marlù"],
        ["LIDDO SAMANTA", "Bottega Verde"],
        ["A & CD FUTURE COMMUNICATION SRL", "Wind Tre"],
        ["GIOIELLI DI VALENZA SPA", "Gioielli di Valenza"],
        ["ANDER SRL", "DVerso"],
        ["IMAP EXPORT SPA", "Original Marines"],
        ["VIRTUAL DREAM SRL", "Dixie"],
        ["GUESS ITALIA SRL", "Guess"],
        ["PRIMITIVE SRL", "Brend Atelier"],
        ["CIDIVERTE SPA", "Gamelife"],
        ["TAILOR MAN SRL", "Enrico Coveri"],
        ["TEDDY SPA", "Rinascimento"],
        ["Q. HELENA SHOES SRL", "Queen Helena"],
        ["GRACAFFE’ 2019 SRL", "Caffè Vergnano"],
        ["MINI PIZZA GASPERINA SRL", "MINIPIZZA 22CM"],
        ["DAN EUROPE SRL", "Monsanto"],
        ["CONBIPEL SPA", "Conbipel uomo"],
        ["SI-COMERCE SRL", "CHIOSCO Equivalenza "],
        ["ANTARTICA SRL", "I Baccanali"],
        ["UNDIQUE NAPOLI SRL", "CHIOSCO CAPATOAST"],
      ],
    };
  },
  methods: {
    activate(val) {
      this.position = val;
    },
  },
  watch: {},
  mounted: function () {
    var pdvBuy = this.pdvBuy;
    pdvBuy.sort((a, b) => a[1].localeCompare(b[1]));
    this.pdvBuy = pdvBuy;

    var pdvOff = this.pdvBuy;
    pdvOff.sort((a, b) => a[1].localeCompare(b[1]));
    this.pdvOff = pdvOff;
    if (this.$route.query) {
      if (this.$route.query.concorso) {
        this.concorso = this.$route.query.concorso;
      }
    }
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "acceptCookies",
      "promoOpen",
      "getPromotions",
    ]),
    ...mapState({
      authLoading: (state) => state.auth.status === "loading",
      nome: (state) => `${state.user.profile.nome}`,
    }),
  },
};
</script>

<style lang="scss" scoped>
hr {
  background: white !important;
}

.card .card-header,
.card .card-footer {
  background-color: #d6001c;
}

.card:hover .card-header,
.card:hover .card-footer {
  background-color: #db223b;
}

#accordion .btn {
  font-size: 26px !important;
  font-family: "Medium" !important;
  font-weight: 400 !important;

  &::after {
    content: "";
    position: absolute;
    right: 14px;
    top: 26px !important;

    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid white;
    transition: all 0.3s ease;
  }

  &:hover::after {
    opacity: 0;
  }
}

.prodotto {
  border: 1px solid #d6001c;
  padding: 10px;
  min-width: 280px !important;
  margin: 0 20px;
  @media screen and (max-width: 768px) {
    & h4 {
      font-size: 16px;
    }
    min-width: 180px !important;
    padding: 6px;
    margin: 0 5px;
  }

  @media screen and (max-width: 400px) {
    & h4 {
      font-size: 10px;
    }
    min-width: 80px !important;
    padding: 6px;
    margin: 0 5px;
  }
}

.btn-accordion {
  cursor: pointer;
  border: 1px solid #d6001c;
  padding: 10px;
  width: 100%;
  color: #d6001c;
  &.active {
    background: #d6001c;

    color: white;
  }

  @media screen and (max-width: 768px) {
    font-size: 10px !important;
    padding: 6px;
  }

  @media screen and (max-width: 400px) {
    font-size: 16px !important;
    padding: 6px;
  }
}
</style>

