<template>
	<div class="container-fluid headerBg padding-top-calc">
		<div class="row" style="width: 100% !important; position: absolute; top: 0">
			<div class="col-12 pl-0 pr-0">
				<img
					v-if="vw > 990"
					src="../../assets/images/top-home-desk.png"
					class="img-fluid absolute-home"
					alt=""
				/>
				<img
					v-if="vw < 990"
					src="../../assets/images/top-home-desk.png"
					class="img-fluid mt-5"
					style="width: 100% !important"
					alt=""
				/>
			</div>

			<div class="col-12 col-lg-6 offset-lg-6">
				<!--	<div class="ml-auto" :class="{ 'title-img': vw >= 998 }">
				<div class="pt-2 pt-md-5 text-center">
							<button-custom
								v-if="timeStore < moment(finish)"
								:to="!isAuthenticated ? '/' : '/play'"
								:scrollTo="'#section01'"
							>
							</button-custom>

							<div v-else>Il concorso è terminato</div>
						</div>
				</div>-->

				<div class="pt-4" style="position: absolute; bottom: -98vh" @click="playNow(2)">
					<img src="../../assets/images/download-visual.png" class="img-fluid" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'

	import { innerWidthMixin } from '../../mixins/innerWidthMixin'
	import { sharedEnvMixin } from '../../mixins/sharedEnvMixin'

	const moment = require('moment')

	export default {
		name: 'header_',
		mixins: [innerWidthMixin, sharedEnvMixin],
		data() {
			return {
				moment: moment
			}
		},

		methods: {
			playNow: function (val) {
				var _vue = this
				if (this.vw > 767) {
					/**Pc */
					if (val == 2) {
						setTimeout(function () {
							_vue.$scrollTo('#section01', 400, { offset: -10 })
						}, 100)
					}
				} else if (this.vw < 330) {
					/*Iphone 5*/
					if (val == 2) {
						setTimeout(function () {
							_vue.$scrollTo('#how-partecipate', 700, { offset: -50 })
						}, 100)
					}
				} else {
					/**Mobile */
					if (val == 2) {
						setTimeout(function () {
							_vue.$scrollTo('#how-partecipate', 700, { offset: -50 })
						}, 100)
					}
				}
			},

			scrollToTop: function () {
				var _vue = this
				setTimeout(function () {
					_vue.$scrollTo('.main', 700, { offset: -125 })
				}, 100)
			}
		},
		mounted() {},
		computed: {
			...mapGetters(['isAuthenticated', 'authStatus', 'getPromotions']),
			timeStore() {
				return this.$timestore.now
			}
		}
	}
</script>
