<template>
	<div
		class="widthFull relative mt-3 min-height-calc"
		id="loginH"
		:class="{ 'back-3': $route.path == '/', customBg: $route.path != '/' }"
	>
		<div class="container-fluid">
			<loading v-if="loadingState" />

			<form class="login" id="login-form" @submit.prevent="login">
				<div v-if="!resend_success" class="bg-theme">
					<div class="row justify-content-center">
						<div class="col-8 text-center">
							<h3 class="col-12 center Bold pb-2 color-1 uppercase"> Accedi </h3>
						</div>
					</div>

					<div class="form-row justify-content-center">
						<div v-if="moment(finish) > timeStore" class="col-md-10 center mb-3 mt-4">
							<h4 class="Regular">
								Non sei registrato?
								<router-link class="underline Regular mt-3" to="/registration">
									<b> Registrati </b>
								</router-link>
							</h4>
						</div>
					</div>

					<div class="form-row justify-content-center">
						<div class="col-10 col-md-5 col-lg-3 mb-5 mb-md-3">
							<div class="group login-input">
								<input
									type="text"
									v-model="username_player"
									@blur="checkIfExistEmail"
									v-validate="{
										required: true,
										email: true,
										temporaryEmail: true,
										plusIssueEmail: true,
										max: 56
									}"
									class="form-control"
									id="username_player"
									name="username_player"
									data-vv-as="Email"
									:class="{
										'is-invalid': errors.has('username_player'),
										valid: !!username_player
									}"
								/>
								<span class="highlight"></span>
								<span class="bar"></span>
								<label class="niceInputLabel" for="username_player">Email</label>

								<div v-if="errors.has('username_player')" class="invalid-feedback">
									{{ errors.first('username_player') }}
								</div>
								<div v-if="socialEmail && socialEmail != 'Manual'" class="yellow mt-3">
									Sei registrato con:
									<strong class="text-capitalize">{{ socialEmail }}</strong
									>. Per effetuare accesso clicca sulla apposita icona
								</div>
								<div v-if="socialEmail == 'Manual'" class="yellow mt-3">
									<b
										>Siamo spiacenti ma non è possibile collegare il tuo account social, hai già
										effettuato una registrazione utilizzando questo indirizzo e-mail. Puoi accedere
										utilizzando password scelto in fase di iscrizione.
									</b>
								</div>
							</div>
						</div>
					</div>

					<div class="form-row justify-content-center">
						<div class="col-10 col-md-5 col-lg-3">
							<div class="group user-input">
								<input
									autocomplete=""
									type="password"
									v-model="password_player"
									v-validate="{
										required: true,
										passwordCheck: true,
										min: 8,
										max: 56
									}"
									class="form-control"
									id="password_player"
									name="password_player"
									data-vv-as="Password"
									:class="{
										'is-invalid': errors.has('password_player'),
										valid: !!password_player
									}"
								/>
								<span class="highlight"></span>
								<span class="bar"></span>
								<label class="niceInputLabel" for="password_player">Password</label>

								<div v-if="errors.has('password_player')" class="invalid-feedback">
									{{ errors.first('password_player') }}
								</div>

								<div v-if="loginerror" class="mb-3">
									<span class="formError ml-1">Email o password errati.</span>
								</div>

								<div v-if="!confirmed" class="mb-3">
									<h5 class="text-center">
										<a
											class="underline Regular ml-1"
											@click="
												popupButton(
													'info',
													'Info',
													'Segui le indicazioni ricevute nella mail di conferma registrazione per confermare il tuo account... <br> Non hai ricevuto l\'e-mail?'
												)
											"
										>
											<b>Account non confermato!</b>
											<font-awesome-icon :icon="['fas', 'info-circle']" class="icon" />
										</a>
									</h5>
								</div>
							</div>
						</div>
					</div>

					<div class="form-row justify-content-center">
						<div class="col-md-5 col-lg-3 mt-2">
							<div class="group">
								<h4 class="black text-center">
									<router-link class="underline Regular" to="/forgot-password">
										<b> Password dimenticata? </b>
									</router-link>
								</h4>
							</div>
						</div>
					</div>

					<div class="form-row justify-content-center mt-3">
						<div class="col-md-6 mb-5 text-center">
							<button class="btn btn-primary uppercase" type="submit"> Entra </button>
						</div>

						<div v-if="social && social != 'Manual'" class="yellow mb-5">
							Sei registrato con altra social network:
							<strong class="text-capitalize">{{ social }}</strong> Per effetuare accesso clicca
							sulla apposita icona
						</div>
						<div v-else-if="social == 'Manual'" class="yellow mt-3">
							<b
								>Siamo spiacenti ma non è possibile collegare il tuo account social, hai già
								effettuato una registrazione utilizzando questo indirizzo e-mail. Puoi accedere
								utilizzando password scelto in fase di iscrizione.
							</b>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import { AUTH_REQUEST, AUTH_SOCIAL } from 'actions/auth'
	import { mapGetters, mapState } from 'vuex'

	const moment = require('moment')

	import { checkUniqueMixin } from '../../mixins/checkUniqueMixin'
	import { sharedEnvMixin } from '../../mixins/sharedEnvMixin'
	import { popupConfigurationMixin } from '../../mixins/popupConfigurationMixin'
	import { gtagMixin } from '../../mixins/gtagMixin'
	import { axiosMixin } from '../../mixins/axiosMixin'

	export default {
		name: 'login',
		components: {},
		mixins: [checkUniqueMixin, sharedEnvMixin, popupConfigurationMixin, gtagMixin, axiosMixin],
		data() {
			return {
				username_player: '',
				password_player: '',
				token: '',
				loading: false,

				socialEmail: false,
				social: '',
				confirmed: true,
				resend_success: false,
				loadingState: false,
				passwordFieldType: 'password',
				moment: moment
			}
		},
		methods: {
			scrollToTop: function () {
				var _vue = this
				setTimeout(function () {
					_vue.$scrollTo('.main', 700, { offset: -2000 })
				}, 100)
			},
			switchVisibility() {
				this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
			},
			showAlert() {
				// Use sweetalret2
				this.$swal({
					type: 'error',
					title: 'Oops...',
					text: 'Something went wrong!'
				})
			},
			login: function () {
				const { username_player, password_player } = this

				this.$validator.validate().then((valid) => {
					if (valid) {
						var data = this.$encryptByAES({
							username_player,
							password_player
						})

						this.$store
							.dispatch(AUTH_REQUEST, { data })
							.then((resp) => {
								const path = this.$route.path

								path == '/' ? this.scrollToTop() : this.$router.push('/')

								this.gtagCheck('login', username_player, 'success', 'success')
							})
							.catch((err) => {
								// this.popupErrorCode(err.response.data.statusCode);
							})
					}
				})
			},

			resetState: function () {
				this.resend_success = false
				this.confirmed = true
			},

			async resendConfirmEmail() {
				const { username_player } = this

				if (username_player) {
					let data = {
						username_player: username_player,
						HBGRF: process.env.VUE_APP_HBGRF,
						vector_HBGRF: process.env.VUE_APP_ICODE,
						from: 'new_registration_email'
					}

					this.loadingState = true
					let r = await axiosMixin.methods.axiosCall('api/resendEmailForUser', data, 'POST')

					this.loadingState = false

					let message = r.data.message
					let statusCode = r.data.statusCode
					let description = r.data.message.description

					try {
						if (r.status < parseInt(process.env.VUE_APP_CATCH)) {
							// then
							if (message) {
								this.resend_success = true
							}
						} else {
							this.$swal({
								type: 'error',
								title: 'Oops...',
								text: message
							})
						}
					} catch (error) {
						this.$swal({
							type: 'error',
							title: 'Oops...',
							text: message
						})
					}
				} else {
					this.confirmed = true
				}
			}
		},

		watch: {
			authStatus(value) {
				if (value == 'player_not_confirmed') {
					this.confirmed = false
				}
			}
		},

		computed: {
			...mapGetters([
				'getProfile',
				'loginerror',
				'confirmerror',
				'isAuthenticated',
				'isProfileLoaded',
				'authStatus'
			]),
			timeStore() {
				return this.$timestore.now
			},
			...mapState({
				//response: state => `${state.status.response.data}`,
			})
		},
		created() {},
		mounted: function () {
			this.$route.path != '/' ? this.scrollToTop() : ''
		}
	}
</script>
